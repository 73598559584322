import PropTypes from "prop-types"

import dsv from "../services/discover_service_view/discover_service_view.scss"
import wwc from "../services/discover_service_view/works_well_card.scss"
import tqad from "./tqa_details.scss"

const Headers = {
  triggers: "Triggers (If this)",
  queries: "Queries (With)",
  actions: "Actions (Then that)",
}

const TqaDetails = props => {
  return (
    <>
      <section styleName="tqad.tqa-details dsv.column">
        {["triggers", "queries", "actions"]
          .filter(tqa => props[tqa].length)
          .map(tqas => (
            <div key={tqas} styleName="tqad.column">
              <h3 styleName="tqad.title">{Headers[tqas]}</h3>
              <ul styleName="dsv.tqa-list" className="web-applet-cards tqa">
                {props[tqas]
                  .map(({ url, name, description, brand_color, image_url }, i) => (
                    <li
                      styleName="tqad.card"
                      className="my-web-applet-card web-applet-card tqa"
                      key={i}
                    >
                      <a
                        className="web-applet-card applet-card-body tqa"
                        styleName="wwc.connect-card tqad.connect-card"
                        style={{ backgroundColor: `#${brand_color}` }}
                        href={url}
                      >
                        <img
                          styleName="tqad.logo"
                          src={image_url}
                          title={name}
                          alt={name}
                          width="54"
                          height="54"
                          loading="lazy"
                        />
                        <div styleName="tqad.content">
                          <div styleName="tqad.title">{name}</div>
                          <div styleName="tqad.description">{description}</div>
                        </div>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </section>
    </>
  )
}

const tqaShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  module_name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  brand_color: PropTypes.string.isRequired,
  tier: PropTypes.string,
})

TqaDetails.propTypes = {
  triggers: PropTypes.arrayOf(tqaShape).isRequired,
  queries: PropTypes.arrayOf(tqaShape).isRequired,
  actions: PropTypes.arrayOf(tqaShape).isRequired,
}

export default TqaDetails
