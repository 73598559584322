export const formatCreateErrors = errors => {
  let formattedErrors = { applet: {} }
  const nameError = errors.find(e => e.attribute === "/applet/name")
  const otherErrors = errors.filter(e => e.attribute !== "/applet/name")
  if (nameError) {
    formattedErrors.applet.description = nameError.message.replace(
      /^Name/,
      "Title"
    )
  }

  if (otherErrors.length) {
    formattedErrors.applet.base = otherErrors
  }

  return formattedErrors
}
