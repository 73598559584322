export const reducer = (state, action) => {
  switch (action.type) {
    case "OWNER_CONNECTED":
      return {
        ...state,
        owner: { ...state.owner, connected: true },
      }
    case "DEPENDENT_CONNECTED":
      return {
        ...state,
        dependent: { ...state.dependent, connected: true },
      }
    case "TOGGLE_LEARN_MORE":
      return {
        ...state,
        showLearnMore: !state.showLearnMore,
      }
    case "EMAIL_UPDATED":
      return {
        ...state,
        user: { ...state.user, email: action.value },
      }
    case "EMAIL_VERIFIED":
      return {
        ...state,
        user: { ...state.user, verified: true },
      }
    case "UPDATE_VIEW":
      return {
        ...state,
        view: action.value,
      }
    case "UPDATE_REDIRECT_URI":
      return {
        ...state,
        redirectUri: action.value,
      }
    case "UPDATE_NEXT_TO_CONNECT":
      return {
        ...state,
        nextToConnect: action.value,
      }
    case "UPDATE_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: action.value,
      }
    default:
      throw new Error(`Unhandled action dispatched: ${action}`)
  }
}
