import { ChangeEvent, GenericEventHandler, TargetedEvent, useEffect, useMemo, useState } from "react"
import { graphQueryBang } from "shared/lib/graphql"

import Fuse from "fuse.js"

import SearchInput from "./search_input"
import Spinner from "shared/components/spinner"
import { partition, pluralizeStepType } from "shared/lib/utils"
import classnames from "classnames"
import debounce from "lodash/debounce"

import "./service_selector.scss"
import SearchIndex from "shared/lib/search_index"
import EmptyStateCta from "shared/components/empty_state"
import { Service } from "./utils/interfaces"

interface ServiceSelectorProps {
  allServiceCategories: Array<string>
  loading: boolean
  onServiceSelect: (a: Service) => void
  onServiceSelectQuery: string
  services: Array<Service>
  recommendedServiceModuleNames: Array<string>
  stepType: "trigger" | "query" | "action"
  updateLoading: (v: boolean) => void
}

interface ServiceCategoryProps {
  allServiceCategories: Array<string>
  handleCategoryChange: (event: GenericEventHandler<HTMLSelectElement>) => void
  serviceCategoryFilter: string
}

export default function ServiceSelector({
  loading,
  onServiceSelect,
  onServiceSelectQuery,
  services,
  recommendedServiceModuleNames,
  allServiceCategories,
  stepType,
  updateLoading,
}: ServiceSelectorProps) {
  const [matchingServices, updateMatchingServices] = useState<Array<Service>>([])
  const [unavailableServices, updateUnavailableServices] = useState<Array<Service>>([])
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState<string>("All")
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [selectedCategoryServices, setSelectedCategoryServices] = useState<Array<Service>>([])
  const [searchIndex, setSearchIndex] = useState<SearchIndex>(new SearchIndex())
  const [selectedCategory, setSelectedCategory] = useState<string>("All")

  const recommendedServices = useMemo(() => {
    if (!recommendedServiceModuleNames) return []
    const moduleNameSet = new Set(recommendedServiceModuleNames)
    return services.filter(s => s.module_name && moduleNameSet.has(s.module_name))
  }, [services, recommendedServiceModuleNames])

  const fuse = useMemo(
    () =>
      new Fuse(services, {
        keys: [
          "name",
          "module_name",
          "category.name",
          "text_only_description",
          "public_triggers.name",
          "public_actions.name",
          "public_queries.name",
        ],
        distance: 150, // default is 100
        threshold: 0.2, // Higher is fuzzier
      }),
    [services]
  )

  const allServiceIds = services.map(s => s.id) as Array<string>
  useEffect(() => {
    const newSearchIndex = new SearchIndex()
    services.forEach(s => {
      newSearchIndex.add(s.id, s.name)
      setSearchIndex(newSearchIndex)
    })
  }, [services])

  const handleCategoryChange = (event: TargetedEvent<HTMLSelectElement>) => {
    const category = (event.target as HTMLSelectElement).value
    setSelectedCategory(category)

    if (searchTerm) setSearchTerm("") // reset search input when user first uses search bar and then selects from the category dropdown

    let visibleServices
    switch (category) {
      case "All":
        visibleServices = services
        break
      case "New":
        visibleServices = services.filter(s => (s.tags as Array<string>).includes("new"))
        break
      case "Popular":
        visibleServices = services.filter(s => (s.tags as Array<string>).includes("popular"))
        break
      default:
        visibleServices = services.filter(s => s.category?.name === category)
    }

    setServiceCategoryFilter(category)
    setSelectedCategoryServices(visibleServices)
    updateRenderedServices(visibleServices)
  }

  function updateRenderedServices(services: Array<Service>) {
    const [matching, unavailable] = partition(
      services,
      (service: Service) => service[`public_${pluralizeStepType(stepType)}`].length > 0
    )

    updateMatchingServices(matching)
    updateUnavailableServices(unavailable)
  }

  useEffect(() => {
    updateRenderedServices(services)
  }, [services])

  const updateSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    const term = (event.target as HTMLInputElement).value

    if (!term) return clearSearch()

    setSearchTerm(term)

    const normalizedSearchTerm = term.trim().toLowerCase()
    const sortedResults = fuse.search(normalizedSearchTerm).sort((a, b) => {
      const aStartsWithTerm = a.item.name.toLowerCase().startsWith(normalizedSearchTerm)
      const bStartsWithTerm = b.item.name.toLowerCase().startsWith(normalizedSearchTerm)

      if (aStartsWithTerm && !bStartsWithTerm) {
        return -1 // Prioritize 'a' if its name starts with the search term and 'b's does not
      } else if (!aStartsWithTerm && bStartsWithTerm) {
        return 1 // Prioritize 'b' if its name starts with the search term and 'a's does not
      }

      // If both or neither item starts with the term, fallback to sorting by the Fuse.js score
      return a.score - b.score
    })
    const results = sortedResults.map(result => result.item)

    if (results.length === 0) {
      const user = window.App.user
      window.App.Utils?.logCustomDatadogAction?.("empty_diy_search", { term, user })
    }

    debouncedOnSearchResult(results.map(s => s.id))
  }

  const clearSearch = () => {
    debouncedOnSearchResult.cancel()
    setSearchTerm("")
    onSearchResult(allServiceIds)
  }

  const onSearchResult = (serviceIds: Array<string>) => {
    let visibleServices

    if (serviceCategoryFilter !== "All") {
      visibleServices = selectedCategoryServices.filter((service: Service) => serviceIds.includes(service.id))
    } else {
      visibleServices = services
        .filter(s => serviceIds.includes(s.id))
        .sort((first, second) => serviceIds.indexOf(first.id) - serviceIds.indexOf(second.id))
    }

    updateRenderedServices(visibleServices)
  }

  const debouncedOnSearchResult = useMemo(
    () => debounce(onSearchResult, 250),
    [serviceCategoryFilter, selectedCategoryServices, services]
  )

  const onClick = (service: Service) => {
    if (loading) return null

    updateLoading(true)

    graphQueryBang(onServiceSelectQuery, {
      serviceModuleName: service.module_name,
    })
      .then((data: { channel: object }) => {
        onServiceSelect({ ...service, ...data.channel })
      })
      .catch(() => updateLoading(false))
  }

  const tiles = (services: Array<Service>, linkable = true) =>
    services.map(service => {
      let detail = (
        <div>
          <img src={service.image} alt={service.name} title={service.name} loading="lazy" />
          <span>{service.name}</span>
        </div>
      )
      if (linkable) {
        detail = (
          <a title={`Choose service ${service.name}`} onClick={() => onClick(service)}>
            {detail}
          </a>
        )
      } else {
        detail = (
          <span title={`${service.name} doesn't have any available ${pluralizeStepType(stepType)}`}>{detail}</span>
        )
      }
      return (
        <li
          key={service.module_name}
          styleName={classnames("service-tile", {
            "disabled-service-tile": !linkable,
          })}
          style={{ backgroundColor: service.brand_color }}
          role="listitem"
        >
          {detail}
        </li>
      )
    })

  return (
    <div styleName="service-selector">
      <>
        {!services.length || (!searchTerm.length && !matchingServices.length && !unavailableServices.length) ? (
          <Spinner />
        ) : (
          <>
            <ServiceCategory
              allServiceCategories={allServiceCategories}
              handleCategoryChange={handleCategoryChange}
              serviceCategoryFilter={serviceCategoryFilter}
            />
            <SearchInput
              searchTerm={searchTerm}
              onChange={updateSearchTerm}
              clearSearch={clearSearch}
              placeholder={serviceCategoryFilter === "All" ? "Search services" : "Filter"}
            />
            {matchingServices.length > 0 || unavailableServices.length > 0 ? (
              <>
                {!searchTerm.length && selectedCategory === "All" && recommendedServices.length > 0 && (
                  <>
                    <h4 styleName="service-grid-title">Recommended</h4>
                    <ul styleName="service-grid" role="list">
                      {tiles(recommendedServices)}
                    </ul>
                  </>
                )}
                <h4 styleName="service-grid-title">Available services</h4>
                {matchingServices.length > 0 && (
                  <ul styleName="service-grid" role="list">
                    {tiles(matchingServices)}
                  </ul>
                )}
                {unavailableServices.length > 0 && (
                  <>
                    <h4 styleName="service-grid-title">Services with no available {pluralizeStepType(stepType)}</h4>
                    <ul styleName="service-grid" role="list">
                      {tiles(unavailableServices, false)}
                    </ul>
                  </>
                )}
              </>
            ) : (
              <EmptyStateCta
                heading="No results found"
                copy="Looks like we couldn't find any Service matches. Please try again."
                extraClass="diy-filter"
              />
            )}
          </>
        )}
      </>
    </div>
  )
}

export const ServiceCategory = ({
  allServiceCategories,
  handleCategoryChange,
  serviceCategoryFilter,
}: ServiceCategoryProps) => {
  return (
    <div className="service-category-container">
      <select className="service-category-dropdown" onChange={handleCategoryChange} value={serviceCategoryFilter}>
        <optgroup label="Filters">
          <option value="All">All services</option>
          <option value="New">New services</option>
          <option value="Popular">Popular services</option>
        </optgroup>
        <optgroup label="Categories">
          {allServiceCategories.map((category, idx) => {
            return (
              <option key={idx} value={category}>
                {category}
              </option>
            )
          })}
        </optgroup>
      </select>
    </div>
  )
}
