import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import checkmark from "images/components/checkmark.svg"

import "./connect_url.scss"

export default class CustomerCTA extends Component {
  state = {
    showManage: false,
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.redirectUri) {
        setTimeout(() => {
          // fallback to showing manage button if redirectUri fails
          this.setState({ showManage: true })
        }, 100)
        location.assign(this.props.redirectUri)
      } else {
        this.setState({ showManage: true })
      }
    }, 2500)
  }

  handleManageClick = event => {
    event.preventDefault()
    window.history.pushState({}, "Explore - IFTTT", "/explore")
    location.assign(this.props.manageUrl)
  }

  render() {
    const { manageUrl, overlayColor, localization } = this.props

    return (
      <div styleName="connect-cta">
        {this.state.showManage ? (
          <a
            href={manageUrl}
            onClick={this.handleManageClick}
          >
            <span
              styleName="action-button"
              style={{ backgroundColor: overlayColor }}
            >
              <span>{localization.manage}</span>
            </span>
          </a>
        ) : (
          <div styleName="vertical-center">
            <div styleName="complete">
              <span styleName="check">
                {parse(checkmark)}
              </span>
            </div>
            <p>{localization.connected}</p>
          </div>
        )}
      </div>
    )
  }
}

CustomerCTA.propTypes = {
  overlayColor: PropTypes.string.isRequired,
  redirectUri: PropTypes.string,
  manageUrl: PropTypes.string,
  localization: PropTypes.shape({
    connected: PropTypes.string.isRequired,
    manage: PropTypes.string.isRequired,
  }),
}
