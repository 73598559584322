import { post } from "shared/lib/api"
import { assignWithBustedCache } from "shared/lib/utils"
import { captureException } from "@sentry/react"

interface Props {
  credit: number,
  exploreUrl: string,
  freeUser: boolean,
  proSubscriptionUrl: string,
  rewardRedeemPath: string,
  rewards: object,
  subscriptionPaymentType: string,
}

const Rewards = (props: Props) => {
  const mobileSubscriber = props.subscriptionPaymentType != "stripe"
  const rewardNotAllowed = props.freeUser || mobileSubscriber

  const onRewardSelect = rewardId => {
    post(props.rewardRedeemPath, { reward_id: rewardId})
      .then(result => {
        if (result.error) {
          window.appendFlash(result.error, "danger")
        } else {
          window.appendFlash("Reward applied to your subscription.", "success", () => {
            assignWithBustedCache(props.exploreUrl)
          })
        }
      })
      .catch(error => {
        captureException(error)
        window.appendFlash("Failure to redeem you reward. Try again later.", "danger")
      })
  }

  const buildRewardCard = reward => {
    const bgColor = reward.id === "month" ? "#289CD6" : "#3b579d"
    const missingCredits = props.credit < reward.required_credit
    return (
      <div key={reward.id} className="reward-card" style={{backgroundColor: bgColor}}>
        <span>{reward.description}</span>
        <button disabled={rewardNotAllowed || missingCredits} className="button-tertiary button-on-color" onClick={() => onRewardSelect(reward.id)} >Select</button>
      </div>
    )
  }

  const description = `Congratulations, you have ${props.credit} credits!`

  const descriptionText = () => {
    if (props.freeUser) {
      return <>
        {description} Please create a subscription to redeem your reward. <a className="link-inline" href={props.proSubscriptionUrl}>Start your trial here.</a>
      </>
    } else if (mobileSubscriber) {
      return "Rewards can only be applied to web subscriptions, to redeem, please cancel your iOS or Android subscription and resume via IFTTT.com."
    } else {
      return `${description} Choose your reward.`
    }

  }

  return (
    <section className="referral-rewards">
      <h1>Referral rewards</h1>
      <p className="txt-body-3 mb2 mt1--phone">{descriptionText()}</p>

      <div className="actions">
        {props.rewards.map(reward => buildRewardCard(reward.data))}
      </div>
    </section>
  )
}

export default Rewards
