import OnboardingTile from "./onboarding_tile"
import Header from "./header"
import Spinner from "shared/components/spinner"

import "./index.scss"
import { useEffect, useState } from "react"
import { fetchRecommendedServicesV2 } from "./api"
import ConnectingToService from "./connecting_to_service"
import Modal from "shared/components/modal"
import { Service } from "types"

interface Props {
  connectingToAssets: {
    desktop: string
    responsive: string
  }
  userIsOnboarding: boolean
  onClickBack: () => void
  onServiceSelected: (service: Service) => void
  onSkip: (location: string) => void
  progressBarSteps: Array<string>
  urls: {
    exploreUrl: string
    bulkChannelBaseUrl: string
    returnToUrl: string
  }
}

const ServiceSelection = ({
  connectingToAssets,
  userIsOnboarding,
  onClickBack,
  onServiceSelected,
  onSkip,
  progressBarSteps,
  urls,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [services, setServices] = useState<Service[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedService, setSelectedService] = useState<Service>()

  useEffect(() => {
    setShowModal(false)
    async function fetchServices() {
      try {
        const response = await fetchRecommendedServicesV2()
        setServices(response)
      } catch (error) {
        window.appendFlash(`Error fetching services. Please try again.`, "danger")
      }
      setLoading(false)
    }

    fetchServices()
  }, [])

  const handleSelectedService = (service: Service) => {
    if (!service.connected) setShowModal(true)
    setSelectedService(service)
    onServiceSelected(service)
  }

  const hideModal = () => {
    setShowModal(false)
  }

  const renderServices = () => {
    const serviceCopy =
      services.length > 0
        ? `Connect a service to ${
            userIsOnboarding ? "create your first Applet" : "unlock new integrations"
          }. You can always choose more later.`
        : "You've already connected all of our recommended services."

    return (
      <div>
        <p className="txt-body-3">{serviceCopy}</p>
        <ul styleName="service-list">
          {services.map(c => (
            <li key={c.module_name}>
              <OnboardingTile onClick={() => handleSelectedService(c)} onboardingItem={c} isServiceSelection />
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <>
      <Modal show={showModal}>
        <ConnectingToService
          connectingToAssets={connectingToAssets}
          progressBarSteps={progressBarSteps}
          service={selectedService as Service}
          urls={urls}
          hideModal={hideModal}
        ></ConnectingToService>
      </Modal>
      <Header
        onClickBack={onClickBack}
        onSkip={onSkip}
        page="services"
        progressBarSteps={progressBarSteps}
        title="Choose one of your favorite services"
      />
      {loading ? <Spinner /> : <div styleName="container">{renderServices()}</div>}
    </>
  )
}

export default ServiceSelection
