import React, { useState } from "react"
import { capitalize } from "humanize-plus"
import classnames from "classnames"
import displayFlash from "partners/scripts/display_flash"
import { activateWithToken } from "./api"
import ErrorIcon from "images/icons/icon-error-triangle.svg"
import parse from "html-react-parser"

import "./activation.scss"

interface Props {
  service: {
    name: string
    module_name: string
  }
  liveChannels: {
    [key: string]: string
  }[]
  serviceUrl: string
}

const ActivateServiceWithToken = ({ service, liveChannels, serviceUrl }: Props) => {
  const fieldName = "API token"
  const existingTokenPresent = liveChannels[0]

  const fieldValue = existingTokenPresent ? liveChannels[0]["masked_api_token"] : ""

  const [token, setToken] = useState<string>(fieldValue)
  const [error, setError] = useState<string>("")

  const handleTokenChange = (e: Event) => {
    const token = (e.target as HTMLInputElement).value
    setToken(token)

    if (token.length >= 6) {
      setError("")
    }
  }

  const handleTokenSubmit = async (e: Event) => {
    e.preventDefault()

    if (token.length < 6) {
      setError(`${fieldName} must be six characters or longer`)
      return
    }

    try {
      const response = await activateWithToken(service.module_name, token, !!existingTokenPresent)
      const dynamicChannelFields = response?.liveChannelCreate?.live_channel?.dynamic_channel_fields
      const maskedApiToken = JSON.parse(dynamicChannelFields || null)?.masked_api_token
      if (maskedApiToken) {
        displayFlash("Service connected successfully!")
        window.location.assign(serviceUrl)
      } else {
        setError(`Error validating your ${fieldName}`)
      }
    } catch (e) {
      console.error(e)
      setError(e.details || `Error validating your ${fieldName}`)
    }
  }

  return (
    <div styleName="activation">
      <div styleName="service-fields">
        <h1>Add your {fieldName}</h1>
        <div styleName="service-activation-form">
          <form className="new_live_channel" id="new_live_channel" onSubmit={handleTokenSubmit}>
            <div styleName="live-channel-fields">
              <p>
                Copy and paste your {fieldName} into the field below to connect to {service.name}.
              </p>
                <>
                  <div styleName={classnames("pin-entry", { error: !!error })}>
                    <label htmlFor={fieldName}>{capitalize(fieldName)}</label>
                    <input
                      type="text"
                      autofocus="true"
                      name={fieldName}
                      id={fieldName}
                      value={token}
                      onChange={handleTokenChange}
                      autocomplete="off"
                    />
                  {error && (
                    <div styleName="after-message submit">
                      <span>
                        {parse(ErrorIcon)}
                      </span>
                      {error}
                    </div>
                  )}
                  </div>

                  <div styleName="send-pin">
                    <button className="button-secondary" onClick={handleTokenSubmit} disabled={!token.length || !!error}>
                      {existingTokenPresent ? "Update" : "Connect"}
                    </button>
                  </div>
                </>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ActivateServiceWithToken
