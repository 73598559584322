import { useState } from "react"

import "./customer_support.scss"

interface Props {
  asset: string
  handleCancellation: func
}

const CustomerSupport = ({ asset, handleCancellation }: Props) => {
  const [loading, updateLoading] = useState(false)

  const handleContactSupport = () => {
    window.App.loadZendeskWidget()
  }

  return (
    <div>
      <h1 className="mt4 mb4 mb2--phone mb2--small-phone">Can we help?</h1>

      <p className="mt0" styleName="techinical-difficulties-copy">
        Our team is available to assist you on any technical difficulties you may be experiencing.
      </p>

      <img src={asset} width="320" height="320" />

      <div className="cancellation-actions mt4 mt8--phone mt8--small-phone">
        <button className="button-primary" onClick={handleContactSupport} disabled={loading}>
          Contact support
        </button>
        <a className={`link-standalone mt4 ${loading && "disabled"}`} onClick={() => handleCancellation(updateLoading)}>
          No thanks, just cancel my subscription
        </a>
      </div>
    </div>
  )
}

export default CustomerSupport
