import typography from "foundation/_typography_export.scss?variables"

if (typeof window !== 'undefined' && typeof window.globalHighchartsOptions === 'undefined') {
  window.globalHighchartsOptions = {
    lang: {
      thousandsSep: ",",
    },
    chart: {
      style: {
        fontFamily: typography.avenir,
      },
    },
  }
}
