import PropTypes from "prop-types"
import parse from "html-react-parser"

import { tqaShape } from "app/lib/shared_prop_types"
import { camelize, humanize, titleize } from "shared/lib/sluggify"
import { getFieldTypeDefinition } from "./helpers"
import IngredientValueTypes from "partners/components/shared/ingredient_value_types"

import proPlusBadge from "images/plan-badges/pro_plus.svg"
import proBadge from "images/plan-badges/pro.svg"

import "./info.scss"

export default function TQAInfo({ service, tqa, type }) {
  const skipActionMethod = () => `${tqa.full_normalized_module_name}.skip(string?: reason)`
  const fieldSetter = field => {
    const setter = "set" + camelize(field.slug)
    const varName = camelize(field.slug, "lower")
    return `${tqa.full_normalized_module_name}.${setter}(string: ${varName})`
  }

  const runtimeId = [service.module_name, tqa.module_name].join(".")
  const performQueryMethod = () => `performQuery("${runtimeId}")`
  const runActionMethod = () => `runAction("${runtimeId}", {})`

  return (
    <div styleName="service-action-info">
      <section>
        <h2>
          {tqa.tier !== "free" && (
            <a href="/plans" title="IFTTT Pro Plans" styleName="badge-container">
              <span className="card-badge">{parse(tqa.tier === "pro" ? proPlusBadge : proBadge)}</span>
            </a>
          )}
          {tqa.description}
        </h2>

        <h4 styleName="dl-header">Developer info</h4>
        <dl>
          <dt>API endpoint slug</dt>
          <dd>{tqa.full_module_name}</dd>
          {type === "query" && (
            <>
              <dt>Runtime method</dt>
              <dd>{performQueryMethod()}</dd>
            </>
          )}
          {type === "action" && (
            <>
              <dt>Filter code method</dt>
              <dd>{skipActionMethod()}</dd>
              <dt>Runtime method</dt>
              <dd>{runActionMethod()}</dd>
            </>
          )}
        </dl>
      </section>

      <div styleName="spacer" />

      <section>
        <h3>{titleize(type)} fields</h3>
        {tqa.fields.length === 0 && <div styleName="empty-state">No fields for this {type}</div>}
        {tqa.fields.map(field => (
          <div key={field.slug}>
            <h4 styleName="dl-header">
              {humanize(field.name)} <span styleName="type-definition">{getFieldTypeDefinition(field)}</span>
            </h4>
            <dl>
              {field.label && (
                <>
                  <dt>Label</dt>
                  <dd>{field.label}</dd>
                </>
              )}
              {field.helper_text && (
                <>
                  <dt>Helper text</dt>
                  <dd>{parse(field.helper_text)}</dd>
                </>
              )}
              <dt>Slug</dt>
              <dd>{field.slug}</dd>
              <dt>Required</dt>
              <dd>{field.required ? "true" : "false"}</dd>
              <dt>Can have default value</dt>
              <dd>{field.can_have_default ? "true" : "false"}</dd>
              {type === "action" && field.hideable && (
                <>
                  <dt>Filter code method</dt>
                  <dd>{fieldSetter(field)}</dd>
                </>
              )}
            </dl>
          </div>
        ))}
      </section>

      {(type === "trigger" || type === "query") && (
        <>
          <div styleName="spacer" />

          <section>
            <h3>Ingredients</h3>
            {tqa.ingredients.length === 0 && <div styleName="empty-state">No ingredients for this {type}</div>}
            {tqa.ingredients.map(ingredient => (
              <div key={ingredient.slug}>
                <h4 styleName="dl-header">
                  {humanize(ingredient.name)}
                  <span styleName="type-definition">{parse(ingredient.note || "")}</span>
                </h4>
                <dl>
                  <dt>Slug</dt>
                  <dd>{ingredient.slug}</dd>
                  <dt>Filter code</dt>
                  <dd>{ingredient.normalized_name}</dd>
                  <dt>Type</dt>
                  <dd>{IngredientValueTypes[ingredient.value_type].label}</dd>
                  {ingredient.example && (
                    <>
                      <dt>Example</dt>
                      <dd>{ingredient.example}</dd>
                    </>
                  )}
                </dl>
              </div>
            ))}
          </section>
        </>
      )}
    </div>
  )
}

TQAInfo.propTypes = {
  service: PropTypes.shape({
    module_name: PropTypes.string.isRequired,
  }).isRequired,
  tqa: tqaShape.isRequired,
  type: PropTypes.oneOf(["trigger", "query", "action"]).isRequired,
}
