import classnames from "classnames"

import "./diy_tanda_selected.scss"

interface Props {
  disabled?: boolean
  label: "if" | "then"
  onClick: () => void
}

export default function DIYTandaSelect({ label, disabled = false, onClick }: Props) {
  const placeholder = label == "if" ? "this" : "that"
  const classes = classnames("tanda-select-block", {
    disabled,
    "then-that": placeholder === "that",
  })

  return (
    <section styleName={classes} onClick={disabled ? () => {} : onClick}>
      <span styleName="label">
        {label} {placeholder}
      </span>
      <button className="button-tertiary button-on-color">Add</button>
    </section>
  )
}
