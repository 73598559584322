import getMoreResults, { initialState as getMoreInitialState } from "./get_more_results"
import searchResults, { initialState as searchResultsInitialState } from "./search_results"
import serviceResults, { initialState as serviceResultsInitialState } from "./service_results"
import viewToggle, { initialState as viewToggleInitialState } from "./view_toggle"

const reducers = {
  getMoreResults: {
    reducer: getMoreResults,
    initialState: getMoreInitialState,
  },
  searchResults: {
    reducer: searchResults,
    initialState: searchResultsInitialState,
  },
  serviceResults: {
    reducer: serviceResults,
    initialState: serviceResultsInitialState,
  },
  viewToggle: {
    reducer: viewToggle,
    initialState: viewToggleInitialState,
  },
}

export default reducers
