import { useEffect } from "react"
import parse from "html-react-parser"

import { verifyIntendedPromiseRejection } from "./utils/modal_promise"

import "./diy_add_tanda_button.scss"
import { attachNewBadgeForButtons } from "shared/scripts/new_badge_button"
import PlusIcon from "shared/components/icons/plus_icon"
import proPlusBadge from "images/plan-badges/pro_plus.svg"

interface Props {
  delayAdded: boolean
  filterCodeAdded: boolean
  onDelayButtonClick: () => void
  onFilterButtonClick: () => void
  onQueryButtonClick: () => void
  userTier: string
}

export default function DIYAddFilterQueryButtons({
  delayAdded,
  filterCodeAdded,
  onDelayButtonClick,
  onFilterButtonClick,
  onQueryButtonClick,
  userTier,
}: Props) {
  useEffect(() => {
    attachNewBadgeForButtons()
  }, [])

  return (
    <section styleName="add-new-query-filter-delay">
      <div styleName="add-query" onClick={verifyIntendedPromiseRejection(onQueryButtonClick)}>
        <div className="description">
          <div styleName="title">
            <h4 className="h4">Query</h4>
            {userTier != "pro" && (
              <span styleName="badge pro_plus">{parse(proPlusBadge)}</span>
            )}
            <span styleName="badge" data-allow-new-badge="queries" />
          </div>
          <p className="txt-body-3">Get additional data from triggers</p>
        </div>
        <button className="plus-icon-button" styleName="add">
          <PlusIcon />
        </button>
      </div>
      {filterCodeAdded ? (
        <></>
      ) : (
        <div styleName="add-filter" onClick={verifyIntendedPromiseRejection(onFilterButtonClick)}>
          <div className="description">
            <div styleName="title">
              <h4 className="h4">Filter code</h4>
              {userTier != "pro" && (
                <span styleName="badge pro_plus">{parse(proPlusBadge)}</span>
              )}
              <span styleName="badge" data-allow-new-badge="filter_code" />
            </div>
            <p className="txt-body-3">Add the power of JavaScript logic</p>
          </div>
          <button className="plus-icon-button" styleName="add">
            <PlusIcon />
          </button>
        </div>
      )}
      {delayAdded ? (
        <></>
      ) : (
        <>
          <div styleName="add-delay" onClick={verifyIntendedPromiseRejection(onDelayButtonClick)}>
            <div className="description">
              <div styleName="title">
                <h4 className="h4">Delay</h4>
                <span styleName="badge" />
              </div>
              <p className="txt-body-3">Add a delay to the next action</p>
            </div>
            <button className="plus-icon-button" styleName="add">
              <PlusIcon />
            </button>
          </div>
        </>
      )}
    </section>
  )
}
