import { useState } from "react"
import PropTypes from "prop-types"
import HelloCard from "./hello_card"

const SLAPrompt = ({ upgradeUrl, eligibleForTrial, imgSet }) => {
  const [hidden, setHidden] = useState(false)

  if (hidden) return null

  let ctaTitleCopy = eligibleForTrial ? "Try it free" : "Upgrade"
  let mainBodyCopy = "Your new Applet will run faster with Pro!"

  return (
    <HelloCard
      ctaTitle={ctaTitleCopy}
      clickAcceptUrl={upgradeUrl}
      onClickDecline={e => {
        e.preventDefault()
        setHidden(true)
      }}
      declineCopy="Maybe later"
      logoHtml={imgSet}
      mainTitle="Better with IFTTT Pro"
      mainBody={mainBodyCopy}
    />
  )
}

SLAPrompt.propTypes = {
  eligibleForTrial: PropTypes.bool,
  upgradeUrl: PropTypes.string.isRequired,
  imgSet: PropTypes.string.isRequired,
}

export default SLAPrompt
