import PropTypes from "prop-types"

import FadeIn from "shared/components/fade_in"
import InfiniteFooter from "./infinite_footer"

import colors from "foundation/_colors_export.scss?variables"
import styleVars from "./footer_button.scss?variables"

import "./footer_button.scss"

export default function FooterButton({
  backgroundColor,
  disabled,
  label,
  onClick,
  fadeIn,
  fadeInDelay,
}) {
  if (backgroundColor === "darken") {
    backgroundColor = "rgba(0, 0, 0, 0.2)"
  } else if (backgroundColor === "lighten") {
    backgroundColor = "rgba(255, 255, 255, 0.15)"
  } else if (!backgroundColor) {
    backgroundColor = colors.quaternaryBackgroundColor
  }

  return (
    <FadeIn fade={fadeIn ? 500 : 0} delay={fadeInDelay || 0}>
      {/* extra div protects the button's own opacity */}
      <div>
        <button
          styleName="button"
          type="submit"
          onClick={onClick}
          disabled={disabled}
          style={{ backgroundColor }}
        >
          {label}
        </button>
      </div>
      <InfiniteFooter
        backgroundColor={backgroundColor}
        opacity={disabled ? parseFloat(styleVars.disabledOpacity) : 1}
      />
    </FadeIn>
  )
}

FooterButton.propTypes = {
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fadeIn: PropTypes.bool,
  fadeInDelay: PropTypes.number,
}

FooterButton.defaultProps = {
  fadeIn: false,
  fadeInDelay: 500,
}
