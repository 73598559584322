import { useEffect } from "react"
import PropTypes from "prop-types"

// This component creates and manages a global <style> tag for as long as it's
// mounted. Please use sparingly.
const GlobalStyle = ({ content }) => {
  const styleId = "global-style"

  useEffect(() => {
    if (!document.getElementById(styleId)) {
      const styleNode = document.createElement("style")
      styleNode.id = styleId
      document.head.appendChild(styleNode)
    }

    return () => {
      document.getElementById(styleId).remove()
    }
  }, [])

  useEffect(() => {
    document.getElementById(styleId).textContent = content
  }, [content])

  return <noscript />
}

GlobalStyle.propTypes = {
  content: PropTypes.string.isRequired,
}

export default GlobalStyle
