import { Tier } from "./utils/interfaces"

// The order here is a little weird. `legacy_pro` is at the end (and therefore
// the "greatest" tier) so that legacy users have effectively pro permissions.
const TIERS: Array<Tier> = ["free", "intermediate_pro", "pro", "legacy_pro"]

export const maxTier = (tier1: Tier, tier2: Tier): Tier => {
  if (TIERS.indexOf(tier1) < TIERS.indexOf(tier2)) {
    return tier2
  } else {
    return tier1
  }
}

export const compareTier = (tier1: Tier, tier2: Tier): number => {
  return Math.sign(TIERS.indexOf(tier1) - TIERS.indexOf(tier2))
}
