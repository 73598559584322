import { useState } from "react"
import PropTypes from "prop-types"

import Prompt from "./prompt"

const EditAction = ({ service, liveChannel, setHref }) => {
  const [isPromptVisible, setPromptVisisble] = useState(false)

  // events
  function didClickReconnect() {
    setPromptVisisble(true)
  }

  function didCloseReconnectPrompt() {
    setPromptVisisble(false)
  }

  function didConfirmReconnectPrompt() {
    setHref(liveChannel.reconnect_path)
  }

  // prompt if required, otherwise just call the action
  const onReconnectClick =
    liveChannel.prompt == null
      ? { href: liveChannel.reconnect_path }
      : { href: "#", onClick: didClickReconnect }

  const defaultEditLabel = "Reconnect"
  const customLabels = {
    date_and_time: "Update timezone",
    maker_webhooks: "Regenerate key",
  }
  const editLabel = (() => {
    return customLabels[service.module_name]
      ? customLabels[service.module_name]
      : defaultEditLabel
  })()
  const allowToReconnect =
    service.requires_user_authentication ||
    service.allow_multiple_live_channels ||
    customLabels[service.module_name]

  const accountOrService = service.requires_user_authentication
    ? "account"
    : "service"

  // render
  return (
    <span className="credential-actions">
      <a
        title={`Remove ${accountOrService}`}
        data-confirm={`Applets you've created that are associated with this ${accountOrService} will be archived, all others will be deleted.`}
        data-method="delete"
        className="remove"
        href={`${liveChannel.disconnect_path}`}
      >
        {service.allow_multiple_live_channels ? "Remove" : "Remove service"}
      </a>
      {allowToReconnect && (
        <a {...onReconnectClick} className="edit">
          {editLabel}
        </a>
      )}

      {liveChannel.prompt && (
        <Prompt
          visible={isPromptVisible}
          prompt={liveChannel.prompt}
          onClose={didCloseReconnectPrompt}
          onConfirm={didConfirmReconnectPrompt}
        />
      )}
    </span>
  )
}

EditAction.propTypes = {
  service: PropTypes.shape({
    allow_multiple_live_channels: PropTypes.bool,
    brand_color: PropTypes.string,
    module_name: PropTypes.string,
    name: PropTypes.string,
    requires_user_authentication: PropTypes.bool,
  }),
  liveChannel: PropTypes.shape({
    id: PropTypes.string,
    reconnect_path: PropTypes.string,
    disconnect_path: PropTypes.string,
    prompt: PropTypes.object,
  }),
  setHref: PropTypes.func,
}

EditAction.defaultProps = {
  setHref: href => (window.location.href = href),
}

export default EditAction
