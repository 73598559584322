import { useState } from "react"
import { assignWithBustedCache } from "shared/lib/utils"
import "./switch_your_plan.scss"

import { updateSubscriptionPlan } from "app/components/upgrade_plan_modal/pro_upgrade/api"

interface Assets {
  downgrade: string
  upgrade: string
}

interface Props {
  assets: Assets
  exploreUrl: string
  handleCancellation: func
  productId: string
  tierName: string
  trackMutationError: func
}

const SwitchYourPlan = ({ assets, exploreUrl, handleCancellation, productId, tierName, trackMutationError }: Props) => {
  const targetPlanName = tierName === "Pro+" ? "Pro" : "Pro+"
  const imageUrl = tierName === "Pro+" ? assets.downgrade : assets.upgrade
  const [loading, updateLoading] = useState(false)

  const handleSwithPlan = async () => {
    updateLoading(true)
    const result = await updateSubscriptionPlan({
      productId,
    })
    if (!result.errors?.length) {
      let message
      if (targetPlanName === "Pro+") {
        message = "Successfully switched from Pro to Pro+!"
      } else {
        message = "Successfully switched from Pro+ to Pro!"
      }

      window.appendFlash(message, "success", () => {
        assignWithBustedCache(exploreUrl)
      })
    } else {
      trackMutationError(
        "updateSubscriptionPlan",
        result.errors,
        "There was a problem switching your account. Please contact support for more information.",
        updateLoading
      )
    }
  }

  return (
    <div styleName="switch_your_plan">
      <h1 className="mt4 mb4 mb2--phone mb2--small-phone">Switch your plan</h1>

      <p className="txt-body-2 mt4 mb4">
        Change to the right plan for you and access tools that help every thing work better together.
      </p>

      <img src={imageUrl} className="mt4" width="329" height="199" />

      <div className="cancellation-actions mt4 mt8--phone">
        <button className="button-primary" onClick={handleSwithPlan} disabled={loading}>
          Switch to {targetPlanName}
        </button>
        <a className={`link-standalone mt4 ${loading && "disabled"}`} onClick={() => handleCancellation(updateLoading)}>
          No thanks, just cancel my subscription
        </a>
      </div>
    </div>
  )
}

export default SwitchYourPlan
