import { useState } from "react"
import PropTypes from "prop-types"

import classNames from "classnames"
import groupBy from "lodash/groupBy"
import includes from "lodash/includes"
import map from "lodash/map"
import uniq from "lodash/uniq"

import StoredField from "app/components/stored_field/index"

export default function StoredFieldsCollection({
  configurationSlug,
  permissions,
  fields,
  ingredientsMetadata,
  onInvalidResolution,
  onFieldChange,
  validationErrors,
  onStoredFieldReady,
  useInlineErrors,
  includeDescriptions,
  filterCodeCta,
  includeHeader,
  useIngredientsForAppletOwners,
  hideIngredientNamespaces,
  applet,
  multiServiceAccounts,
  updateFormEnabled,
  proFeatureGate,
  userAllowMultipleLiveChannels,
  multipleLiveChannelsNewBadgeVisibility,
  hideMultipleLiveChannelsNewBadge,
}) {
  const [retryOptionsForService, forceRetryForService] = useState(
    permissions.reduce((acc, permission) => {
      return {
        ...acc,
        [permission.service_id]: false,
      }
    }, {})
  )

  const permissionElement = (permissionFields, owner) => {
    const permission = permissions.find(p => p.id === owner)
    const collectionErrors =
      (configurationSlug &&
        validationErrors &&
        validationErrors.stored_fields &&
        validationErrors.stored_fields[configurationSlug] &&
        validationErrors.stored_fields[configurationSlug][
          owner.replace(/\//g, "~1")
        ]) ||
      {}
    const errorsForPermission =
      (validationErrors &&
        validationErrors.stored_fields &&
        validationErrors.stored_fields[owner]) ||
      {}

    const fieldElements = permissionFields.map(field => {
      const errorMessages = uniq(errorsForPermission[field.name] || [])
      let fieldOwnerServiceName = "This service"
      let fieldOwnerServiceId = "id"
      let fieldOwnerAllowMultipleLiveChannels = false

      if (permission && permission.service_name) {
        fieldOwnerServiceName = permission.service_name
        fieldOwnerServiceId = permission.service_id
      }

      const permissionChannel = applet.channels.find(
        c => c.module_name === permission?.service_id
      )

      fieldOwnerAllowMultipleLiveChannels =
        permissionChannel?.allow_multiple_live_channels

      if (field.field_subtype === "service_account_select") {
        field.options = permissionChannel.live_channels
        field.updateOptionsList = liveChannels =>
          multiServiceAccounts.updateServiceList(
            fieldOwnerServiceId,
            liveChannels
          )
        field.connectionFinishedUrl = multiServiceAccounts.connectionFinishedUrl
        field.isOnColor = false
        field.showNewBadge = multipleLiveChannelsNewBadgeVisibility
        field.hideNewBadge = hideMultipleLiveChannelsNewBadge
      }

      return fieldElement({
        field,
        owner,
        errorMessages,
        useInlineErrors,
        ingredientsMetadata,
        onStoredFieldReady,
        onInvalidResolution,
        onFieldChange,
        fieldOwnerServiceName,
        fieldOwnerServiceId,
        fieldOwnerAllowMultipleLiveChannels,
        useIngredientsForAppletOwners,
        hideIngredientNamespaces,
        liveChannelId: permission?.live_channel_id,
        updateFormEnabled,
        userAllowMultipleLiveChannels,
        shouldForceRetryOptions: retryOptionsForService[fieldOwnerServiceId],
        proFeatureGate,
        updateServiceToForceRetry: val => {
          forceRetryForService({
            ...retryOptionsForService,
            [fieldOwnerServiceId]: val,
          })
        },
      })
    })

    return (
      <div className="permission" key={owner}>
        {permission && includeHeader && (
          <div className="title">
            <img
              className="permission-logo"
              src={permission.icon_url}
              title={permission.name}
              alt={permission.name}
            />
            <span>{permission.name}</span>
          </div>
        )}
        {includeHeader && (
          <p className="description">
            {/* If there's a header, we always include the .description element
                because we need it for its margin */}
            {includeDescriptions && permission && permission.description}
          </p>
        )}
        <ul className="form">{fieldElements}</ul>
        {!!collectionErrors.length && (
          <div className="label">
            <div className="error-message">{collectionErrors.join(", ")}</div>
          </div>
        )}
      </div>
    )
  }
  const triggerAndQueryPermissions = map(
    groupBy(
      fields.filter(f => f.type !== "action"),
      "owner"
    ),
    permissionElement
  )
  const actionPermissions = map(
    groupBy(
      fields.filter(f => f.type === "action"),
      "owner"
    ),
    permissionElement
  )

  return (
    <div className="services">
      {[...triggerAndQueryPermissions, filterCodeCta, ...actionPermissions]}
    </div>
  )
}

function fieldElement({
  field,
  owner,
  ingredientsMetadata,
  onStoredFieldReady,
  onInvalidResolution,
  onFieldChange,
  errorMessages,
  fieldOwnerServiceName,
  fieldOwnerServiceId,
  fieldOwnerAllowMultipleLiveChannels,
  useInlineErrors,
  useIngredientsForAppletOwners,
  hideIngredientNamespaces,
  liveChannelId,
  updateFormEnabled,
  shouldForceRetryOptions,
  userAllowMultipleLiveChannels,
  proFeatureGate,
  updateServiceToForceRetry,
}) {
  const name = fieldName(field)
  const subHeader = !field.required ? " (optional)" : ""
  const className = classNames("field", {
    error: errorMessages.length,
  })

  let ingredients = {}
  if (includes(owner, "action")) {
    ingredients = ingredientsMetadata
  } else if (includes(owner, "queries")) {
    ingredients = { trigger: ingredientsMetadata.trigger }
  }

  return (
    <li className={className} key={field.name}>
      <div className="label">
        {field.label} {subHeader}
        {!useInlineErrors && !!errorMessages.length && (
          <span className="error-message">{errorMessages.join(", ")}</span>
        )}
      </div>

      <div className="input">
        <StoredField
          field={field}
          fieldName={name}
          ingredientsMetadata={ingredients}
          onReady={onStoredFieldReady}
          onInvalidResolution={(err, name) =>
            onInvalidResolution(err, name, field)
          }
          onChange={(val, automated) => onFieldChange(field, val, automated)}
          fieldOwnerServiceName={fieldOwnerServiceName}
          fieldOwnerServiceId={fieldOwnerServiceId}
          fieldOwnerAllowMultipleLiveChannels={
            fieldOwnerAllowMultipleLiveChannels
          }
          userAllowMultipleLiveChannels={userAllowMultipleLiveChannels}
          proFeatureGate={proFeatureGate}
          errors={useInlineErrors ? errorMessages : []}
          useIngredientsForAppletOwners={useIngredientsForAppletOwners}
          hideIngredientNamespaces={hideIngredientNamespaces}
          liveChannelId={liveChannelId}
          shouldForceRetryOptions={shouldForceRetryOptions}
          updateServiceToForceRetry={updateServiceToForceRetry}
          updateFormEnabled={updateFormEnabled}
        />
      </div>
    </li>
  )
}

fieldElement.propTypes = {
  field: PropTypes.object.isRequired,
  owner: PropTypes.string,
  ingredientsMetadata: PropTypes.object,
  onStoredFieldReady: PropTypes.func,
  onInvalidResolution: PropTypes.func,
  onFieldChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  fieldOwnerServiceName: PropTypes.string.isRequired,
  useInlineErrors: PropTypes.bool,
  useIngredientsForAppletOwners: PropTypes.bool,
  hideIngredientNamespaces: PropTypes.bool,
}

function fieldName(field) {
  const name = field.name
  return `stored_fields[${field.owner}][${name}]`
}

StoredFieldsCollection.propTypes = {
  configurationSlug: PropTypes.string,
  applet: PropTypes.object,
  permissions: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  ingredientsMetadata: PropTypes.object,
  onInvalidResolution: PropTypes.func,
  onFieldChange: PropTypes.func,
  onStoredFieldReady: PropTypes.func,
  validationErrors: PropTypes.object,
  useInlineErrors: PropTypes.bool,
  includeDescriptions: PropTypes.bool,
  includeHeader: PropTypes.bool,
  useIngredientsForAppletOwners: PropTypes.bool,
  hideIngredientNamespaces: PropTypes.bool,
  filterCodeCta: PropTypes.object,
  updateFormEnabled: PropTypes.func,
  userAllowMultipleLiveChannels: PropTypes.bool,
  proFeatureGate: PropTypes.func,
  multiServiceAccounts: PropTypes.shape({
    connectionFinishedUrl: PropTypes.string,
    updateServiceList: PropTypes.func,
  }),
  multipleLiveChannelsNewBadgeVisibility: PropTypes.bool,
  hideMultipleLiveChannelsNewBadge: PropTypes.func,
}

StoredFieldsCollection.defaultProps = {
  onInvalidResolution: () => {},
  onFieldChange: () => {},
  onStoredFieldReady: () => {},
  includeDescriptions: true,
  includeHeader: true,
  useIngredientsForAppletOwners: false,
}
