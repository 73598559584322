import { useEffect, useState } from "react"
import debounceMatchMedia from "shared/lib/debounce_match_media"
import { ProgressBar } from "./progress_bar"
import classnames from "classnames"
import parse from "html-react-parser"

import iftttLogo from "images/logo-ifttt.svg"
import proBadge from "images/plan-badges/pro.svg"
import proPlusBadge from "images/plan-badges/pro_plus.svg"
import chevronLeftBlack from "images/layout/chevron-left-black.svg"
import "./header.scss"

const minModalWidth = 850
interface Props {
  onClickBack: () => void
  onSkip?: (page: string) => void
  title: string | object
  page?: string
  progressBarSteps?: Array<string>
  hideBackButton?: boolean
  badgeTier: string
}

const Header = ({ badgeTier, onClickBack, title, onSkip, page, progressBarSteps, hideBackButton }: Props) => {
  const renderSkip = (page === "services" || page === "applets") && onSkip
  const [isDesktop, setDesktop] = useState(window.matchMedia(`(min-width: ${minModalWidth}px)`)?.matches)

  useEffect(debounceMatchMedia.bind(null, minModalWidth, setDesktop), [])

  return (
    <header styleName={classnames("onboarding-header", { trial: page === "trial" })}>
      {hideBackButton ? (
        <a title="Home" styleName="logo" aria-label="The IFTTT home page" href="/my_applets">
          <span className="svg-icon">{parse(iftttLogo)}</span>
        </a>
      ) : (
        <button
          type="submit"
          styleName="back"
          className="button-tertiary button-outlined button-with-icon only-icon-on-mobile"
          aria-label="Back button"
          data-dd-action-name={`NUI ${page} back button`}
          onClick={() => {
            onClickBack()
          }}
        >
          <span className="button-icon">{parse(chevronLeftBlack)}</span>
          <span className="hide-on-xs">Back</span>
        </button>
      )}
      {isDesktop && progressBarSteps && <ProgressBar progressBarSteps={progressBarSteps} page={page as string} />}
      {!!badgeTier && (
        <div styleName="badge-container">
          <span>{parse(iftttLogo)}</span>
          <span>{parse(badgeTier === "pro" ? proBadge : proPlusBadge)}</span>
        </div>
      )}
      {renderSkip && page === "services" && (
        <a
          styleName="skip"
          aria-label="Skip button"
          data-dd-action-name="NUI services skip button"
          onClick={() => {
            onSkip(page)
          }}
        >
          Skip
        </a>
      )}
      {renderSkip && page === "applets" && (
        <button
          aria-label="Continue button"
          data-dd-action-name="NUI applets skip button"
          styleName="continue"
          className="button-tertiary"
          onClick={() => {
            onSkip(page)
          }}
        >
          Continue
        </button>
      )}
      {!renderSkip && <div className="spacer" />}
      <h1>{title}</h1>
    </header>
  )
}

export default Header
