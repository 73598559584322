import { useState, useEffect } from "react"
import { useBgColorContext } from "./context"
import parse from "html-react-parser"

import ServiceBrand from "./shared/service_brand"
import { fullColoredPage } from "./utils/container_background_color"
import openRemoteLinksInNewWindow from "app/scripts/open_remote_links_in_new_window"
import { checkServiceActivation } from "./api"
import { LoadLiveChannels } from "shared/components/stored_fields/service_account_select_field/api"

import { buildConnectionPath, windowFeatures } from "shared/reconnect_window"

import "./service_connection.scss"
import { Service } from "./utils/interfaces"

interface Props {
  connectionFinishedUrl: string
  onServiceConnected: (v: Service) => void
  service: Service
}

export default function ServiceConnection({ service, connectionFinishedUrl, onServiceConnected }: Props) {
  const bgColor = useBgColorContext()

  const [connectionFailed, updateConnectionFailed] = useState(false)
  let popup: { closed: boolean; focus: () => void } | null = null

  useEffect(() => {
    fullColoredPage(bgColor)
    openRemoteLinksInNewWindow(".service-diy-description")

    return () => {
      fullColoredPage("initial")
    }
  }, [bgColor])

  const connectionPath = buildConnectionPath(service.module_name, null, connectionFinishedUrl)

  let serviceConnectionAttempted = false

  const onStartConnection = () => {
    if (service.can_be_autoactivated) {
      checkServiceActivation({ serviceModuleName: service.module_name as string })
        .then(data => {
          if (data.liveChannelCreate.live_channel?.offline === false) {
            onServiceConnected({
              ...service,
              live_channels: [data.liveChannelCreate.live_channel],
            })
          } else {
            updateConnectionFailed(true)
          }
        })
        .catch(() => updateConnectionFailed(true))
    } else if (popup == null || popup.closed) {
      popup = window.open(connectionPath, `${service.module_name}_connection`, windowFeatures)
      serviceConnectionAttempted = true

      // Cross-Origin-Opener-Policy headers may interfere with the popup being able to relay
      // back to the DIY flow that the authentication was/wasn't successful. When focus returns
      // to the DIY flow, check for a LiveChannel otherwise call the authentication a failure.
      window.addEventListener("focus", () => {
        if (serviceConnectionAttempted) {
          try {
            window.connectionFinished()
          } catch (error) {
            updateConnectionFailed(true)
          } finally {
            serviceConnectionAttempted = false
          }
        }
      }, { once: true })
    } else {
      serviceConnectionAttempted = true
      popup.focus()
    }

    window.connectionFinished = () => {
      LoadLiveChannels(service.module_name).then(response => {
        if (!response.data) updateConnectionFailed(true)

        const liveChannels = response.data.channel.live_channels

        if (!liveChannels.length) {
          // Authentication failed if there isn't a LiveChannel to work with
          updateConnectionFailed(true)
        } else {
          onServiceConnected({
            ...service,
            live_channels: liveChannels,
          })
        }
      })
    }

    window.connectionFailed = () => updateConnectionFailed(true)
  }

  const serviceFailureMessage = `There was an error connecting ${service.name}. Try again.`
  return (
    <div styleName="service-connection">
      {connectionFailed && <p styleName="inline-errors">{serviceFailureMessage}</p>}

      <ServiceBrand title={service.name} image={service.image} />

      <p
        className="service-diy-description"
        styleName="service-description"
      >
        {parse(service.description_html as string)}
      </p>

      <button className="button-primary button-on-color" onClick={onStartConnection}>
        Connect
      </button>
    </div>
  )
}
