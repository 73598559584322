import { Component } from "react"
import PropTypes from "prop-types"

import classnames from "classnames"

export default class Header extends Component {
  titleLimit = 140

  state = {
    editing: false,
    change: false,
    title: this.props.connection.name,
    titleValid: true,
  }

  render() {
    const bgColor = { backgroundColor: `${this.props.connection.brand_color}` }

    return (
      <div style={bgColor} className="connection-header">
        <div className="cta">
          {!this.props.programmable && (
            <div className="dependency-icons">
              {Object.keys(this.props.icons).map((key, index) => (
                <img
                  key={index}
                  src={this.props.icons[key]}
                  width="30"
                  height="30"
                  alt={key}
                  title={key}
                />
              ))}
            </div>
          )}
          <div
            className={classnames({
              "connection-title": true,
              dynamic: this.props.programmable,
            })}
          >
            {this.props.connection.name}
          </div>

          <div className="owner no-link">
            {this.props.programmable && (
              <img
                src={this.props.mainService.lrg_variant_image_url}
                width="20"
                height="20"
                alt={this.props.mainService.name}
                title={this.props.mainService.name}
              />
            )}
            <span>
              {!this.props.programmable && <span>By</span>}{" "}
              {this.props.mainService.name}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  mainService: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lrg_variant_image_url: PropTypes.string,
  }),
  icons: PropTypes.object,
  connection: PropTypes.shape({
    brand_color: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  programmable: PropTypes.bool,
  updateURL: PropTypes.string,
};
