import { Component } from "react"
import PropTypes from "prop-types"

// This component creates and manages a global <meta> tag for as long as it's
// mounted.
export default class GlobalMeta extends Component {
  componentDidMount() {
    this.metaNode = document.createElement("meta")
    this.setProperties()
    document.head.appendChild(this.metaNode)
  }

  componentDidUpdate() {
    this.setProperties()
  }

  setProperties() {
    this.metaNode.content = this.props.content
    this.metaNode.name = this.props.name
  }

  componentWillUnmount() {
    document.head.contains(this.metaNode) &&
      document.head.removeChild(this.metaNode)
  }

  render() {
    return <noscript />
  }
}

GlobalMeta.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
