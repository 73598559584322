import { useState } from "react"

import "../index.scss"
import Header from "../header.tsx"
import { post } from "shared/lib/api"
import { captureMessage } from "@sentry/browser"

interface Props {
  assets: object
  urls: object
  userTier: string
}

const PromotionContainer = (props: Props) => {
  const [loading, updateLoading] = useState(false)
  const errorMessage = "There was a problem claiming your offer. Please contact support for more information."
  const tierName = props.userTier === "pro" ? "Pro+" : "Pro"

  const handleCancellation = () => {
    updateLoading(true)
    window.location.assign(props.urls.explore)
  }

  const trackMutationError = (mutationName, error, userFeedback, updateLoadingState) => {
    updateLoadingState(false)
    if (window.App.allowDebugErrorMessages) console.warn(error)
    captureMessage(`${mutationName} failed`, { extra: error })
    window.appendFlash(userFeedback, "danger")
  }

  const handleOffer = () => {
    updateLoading(true)
    post(props.urls.claimPromotionOffer)
      .then(data => {
        if (data.ok) {
          window.appendFlash("A 20% discount has been applied to your next payment.", "success", () => {
            window.location.assign(props.urls.explore)
          })
        } else {
          trackMutationError("userSubscriptionPromoCodeUpdate", data.error, errorMessage, updateLoading)
        }
      })
      .catch(error => {
        trackMutationError("userSubscriptionPromoCodeUpdate", error, errorMessage, updateLoading)
      })
  }

  return (
    <section styleName="cancel_plan_container">
      <Header logo={props.assets.iftttSvgUrl} />
      <div>
        <h1 className="mt4 mb4 mb2--phone mb2--small-phone">Exclusive offer</h1>

        <p className="mt0 mb4">Continue as {tierName} and get 20% off your next payment.</p>

        <img src={props.assets.exclusiveOffer} width="288" height="256" />

        <div className="cancellation-actions mt4 mt8--phone mt8--small-phone">
          <button className="button-primary" onClick={handleOffer} disabled={loading}>
            Claim offer
          </button>
          <a className={`link-standalone mt4 ${loading && "disabled"}`} onClick={handleCancellation}>
            No thanks, I don&apos;t like deals
          </a>
        </div>
      </div>
    </section>
  )
}

export default PromotionContainer
