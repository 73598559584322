import { post } from "shared/lib/api"
import SetupDeleteAccountSurvey from "./setup_delete_account_survey"
import paswordUnmasking from "shared/scripts/password_un_masking"

export default function HandleVerifyDeleteAccountByEmail(verifyByEmailUrl) {
  const completableFormEl = document.querySelector(".completable-form.hidden")
  const startDeleteEl = document.querySelector(".delete-start")
  const usePasswordEl = document.getElementById("verify-by-password")
  if (usePasswordEl && completableFormEl && startDeleteEl) {
    usePasswordEl.onclick = function (e) {
      e.preventDefault()
      e.stopPropagation()
      completableFormEl.classList.remove("hidden")
      startDeleteEl.classList.add("hidden")
      SetupDeleteAccountSurvey()
      paswordUnmasking?.()
    }
  }
  const verifyLink = document.getElementById("verify-delete-account")
  if (verifyLink) {
    verifyLink.onclick = function (e) {
      e.preventDefault()
      post(verifyByEmailUrl)
        .then(() => {
          window.appendFlash("Just a minute, we're sending you an email...")
        })
        .catch(() => {
          console.warn("verify delete account mutation failed")
        })
    }
  }
}
