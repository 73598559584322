export const ingredientsMetadataFromState = (
  { selectedTrigger, selectedQueries },
  includeQueries = true
) => {
  const service = step => ({
    name: step.service.name,
    background_color: step.service.brand_color,
    icon_url: step.service.image,
  })

  const trigger = selectedTrigger && {
    id: selectedTrigger.id,
    name: selectedTrigger.name,
    service: service(selectedTrigger),
    ingredients: selectedTrigger.ingredients.map(ingredient => ({
      ...ingredient,
      trigger_id: selectedTrigger.id,
    })),
  }

  const queries = (includeQueries ? selectedQueries : []).map(query => ({
    name: query.name,
    service: service(query),
    ingredients: query.ingredients.map(ingredient => ({
      ...ingredient,
      trigger_id: query.id,
    })),
  }))

  return {
    trigger,
    queries,
  }
}
