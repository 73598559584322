import PropTypes from "prop-types"
import icons from "../connect_sdk_applet/utils/art"
import XIcon from "shared/components/icons/x_icon"
import {
  replaceTextWithComponent,
  formatTextStyle,
} from "./utils/replace_text_with_component"
import parse from "html-react-parser"

import "../connect_sdk_applet/returning_user_flow/returning_user_flow.scss"

const agreement_with_link = (text, terms_text, termsUrl) => {
  const textToBeReplaced = `<a href=${termsUrl} rel="noopener noreferrer" target="_blank">${terms_text}</a>`

  return parse(text.replace(terms_text, textToBeReplaced))
}

const formatHeaderText = (text, services) => {
  const svg = <span styleName="ifttt-logo">{parse(icons.iftttLogoWhite)}</span>

  return replaceTextWithComponent(text, "IFTTT", svg)
    .map(
      formatTextStyle(
        services[0].name,
        <span styleName="bold">{services[0].name}</span>
      )
    )
    .map(
      formatTextStyle(
        services[1].name,
        <span styleName="bold">{services[1].name}</span>
      )
    )
}

const LearnMore = ({
  services,
  connectionUrl,
  toggle,
  termsUrl,
  appStoreUrl,
  googlePlayStoreUrl,
  localization,
}) => (
  <>
    <div styleName="header learn">
      <div styleName="close" onClick={toggle}>
        <XIcon width="25" height="25" />
      </div>
      <div styleName="header-content learn">
        <div styleName="service-to-service">
          <div styleName="service">
            <a
              href={`/${services[0].module_name}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={
                  services[0].lrg_monochrome_image_url ||
                  services[0].lrg_variant_image_url
                }
                title={services[0].name}
                alt={services[0].name}
              />
            </a>
          </div>
          <div
            styleName="arrow"
            title={localization.to}
            alt={localization.to}
          >
            {parse(icons.arrow)}
          </div>
          <div styleName="service">
            <a
              href={`/${services[1].module_name}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={
                  services[1].lrg_monochrome_image_url ||
                  services[1].lrg_variant_image_url
                }
                title={services[1].name}
                alt={services[1].name}
              />
            </a>
          </div>
        </div>
        <p styleName="ifttt-connects">
          <a href={connectionUrl} rel="noopener noreferrer" target="_blank">
            {formatHeaderText(
              localization.about_ifttt_connects_x_to_y,
              services
            )}
          </a>
        </p>
      </div>
    </div>
    <div styleName="learn-more-content">
      <ul>
        {[
          {
            icon: icons.twoSquares,
            cta: localization.about_ifttt_unlock_new_features,
          },
          {
            icon: icons.toggleLeft,
            cta: localization.about_ifttt_control_which_services_access_data,
          },
          {
            icon: icons.lock,
            cta: localization.about_ifttt_protect_and_monitor_data,
          },
          {
            icon: icons.plug,
            cta: localization.about_ifttt_unplug_any_time,
          },
        ].map((learnLI, i) => (
          <li key={i}>
            <span>{parse(learnLI.icon)}</span>
            {learnLI.cta}
          </li>
        ))}
      </ul>
      <div styleName="app-store-logos">
        <a href={appStoreUrl} rel="noopener noreferrer" target="_blank">
          <span>{parse(icons.appleAppStore)}</span>
        </a>
        <a href={googlePlayStoreUrl} rel="noopener noreferrer" target="_blank">
          <span>{parse(icons.googlePlayStore)}</span>
        </a>
      </div>
        <p>
          {agreement_with_link(
            localization.about_ifttt_privacy_and_terms, localization.term_privacy_and_terms, termsUrl
          )}
        </p>
    </div>
  </>
)

LearnMore.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      module_name: PropTypes.string.isRequired,
      lrg_monochrome_image_url: PropTypes.string,
      lrg_variant_image_url: PropTypes.string,
    })
  ),
  connectionUrl: PropTypes.string.isRequired,
  termsUrl: PropTypes.string.isRequired,
  appStoreUrl: PropTypes.string.isRequired,
  googlePlayStoreUrl: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  localization: PropTypes.shape({
    to: PropTypes.string.isRequired,
    about_ifttt_connects_x_to_y: PropTypes.string.isRequired,
    about_ifttt_unlock_new_features: PropTypes.string.isRequired,
    about_ifttt_control_which_services_access_data: PropTypes.string.isRequired,
    about_ifttt_protect_and_monitor_data: PropTypes.string.isRequired,
    about_ifttt_unplug_any_time: PropTypes.string.isRequired,
    about_ifttt_privacy_and_terms: PropTypes.string.isRequired,
    term_privacy_and_terms: PropTypes.string.isRequired,
  }).isRequired,
}

export default LearnMore
