export default function AdminLayover() {
  let hideTimer = null

  // Use named function to avoid redeclare for every page
  window.addEventListener("mousemove", adminLayoverMouseMove)
  window.addEventListener("keydown", adminLayoverKeydown)

  const adminLayover = document.querySelector(".applet-admin-layover")
  if (adminLayover) {
    adminLayover.addEventListener("mouseenter", _ => {
      if (hideTimer !== null) clearTimeout(hideTimer)
    })
    adminLayover.addEventListener("mouseleave", _ => {
      hideTimer = setTimeout(function () {
        document.querySelector(".applet-admin-layover").style.display = "none"
      }, 1000)
    })
  }
}

function adminLayoverMouseMove(e) {
  const adminLayover = document.querySelector(".applet-admin-layover")
  if (e.pageX < 10 && adminLayover) {
    adminLayover.style.display = "block"
  }
}

function adminLayoverKeydown(e) {
  const adminLayover = document.querySelector(".applet-admin-layover")
  if (e.keyCode === 27 && adminLayover) {
    adminLayover.style.display = "none"
  }
}
