import { capitalize, titleCase } from "humanize-plus"

/**
 * When passed a string, this function converts it to a slug.
 * By default, it uses snake_case, but you can convert to kebab, Camel, and lowerCamel as well.
 * The optional case type can be one of:
 *   snake (default) - snake_case_your_string
 *   kebab - kebabify-your-string
 *   Camel - CamelCaseYourString
 *   lowerCamel - lowerCamelCaseYourString
 *   title - Title Case Your String
 *   sentence - Humanize your string
 *
 * @param {string} string
 * @param {"snake"|"kebab"|"Camel"|"lowerCamel"|"title"|"sentence"?} caseType
 *
 * @return {string} sluggified string
 */
export default function sluggify(string, caseType = "snake") {
  let str = string
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/[^a-zA-Z0-9-_\s]/g, "")
    .replace(/[A-Z]{2,}/g, match => ` ${match.toLowerCase()}`)
    .replace(/[A-Z]/g, " $&")
    .replace(/[-_]/g, " ")
    .replace(/\s+/g, " ")
    .toLowerCase()
    .trim()

  if (["title", "sentence"].includes(caseType)) {
    str = str.replace(/\b(url|html|id)\b/g, match => match.toUpperCase())
  }

  switch (caseType) {
    case "snake":
      return str.replace(/\s/g, "_")
    case "kebab":
      return str.replace(/\s/g, "-")
    case "Camel":
      return str.replace(/(^|\s)[a-z0-9]/g, match => match.trim().toUpperCase())
    case "lowerCamel":
      return str.replace(/\s[a-z0-9]/g, match => match.trim().toUpperCase())
    case "title":
      return titleCase(str)
    case "sentence":
      return capitalize(str)
    default:
      throw new Error(`Don't know type: ${caseType}`)
  }
}

export function underscore(string) {
  return sluggify(string, "snake")
}

export function dasherize(string) {
  return sluggify(string, "kebab")
}

/**
 * @param {string} string
 * @param {"upper"|"lower"?} firstLetter
 */
export function camelize(string, firstLetter = "upper") {
  if (!(firstLetter === "upper" || firstLetter === "lower")) {
    throw new Error(
      `first_letter must be one of 'upper', 'lower' but got: ${firstLetter}`
    )
  }
  return sluggify(string, firstLetter === "upper" ? "Camel" : "lowerCamel")
}

export function titleize(string) {
  return sluggify(string, "title")
}

export function humanize(string) {
  return sluggify(string, "sentence")
}
