import PropTypes from "prop-types"

import "./connect_url.scss"

// Only service authenticate error will display button to retry.
// In the future, multiAccount will have a logic to be handled.
const isServiceAuthenticateError = errorMessage => {
  return errorMessage.includes("authenticate")
}

// Errors to be rendered:
//  * Could not authenticate service.
//  * The email parameter is missing!
//  * A connection already exists
const ErrorElement = ({
  onSubmit,
  errorMessage,
  overlayColor,
  localization,
}) => (
  <div styleName="connect-cta">
    <div>
      <p styleName="reason">{errorMessage}</p>
      {isServiceAuthenticateError(errorMessage) && (
        <a
          onClick={e => {
            e.preventDefault()
            onSubmit()
          }}
          href="#"
        >
          <span
            styleName="action-button"
            style={{ backgroundColor: overlayColor }}
          >
            <span>{localization.try_again}</span>
          </span>
        </a>
      )}
    </div>
  </div>
)

ErrorElement.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  overlayColor: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localization: PropTypes.shape({
    try_again: PropTypes.string.isRequired,
  }).isRequired,
}

export default ErrorElement
