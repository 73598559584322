import { gql, graphQueryBang } from "shared/lib/graphql"

const SessionCheckEmailAvailabilityMutation = gql`
  mutation SessionCheckEmailAvailabilityMutation($email: String!) {
    sessionCheckEmailAvailability(input: { email: $email }) {
      available
    }
  }
`
const MailerSendConnectionMagicSignInMutation = gql`
  mutation MailerSendConnectionMagicSignInMutation(
    $applet_slug: String!
    $email: String
    $redirect_uri: String!
    $locale: String
  ) {
    mailerSendConnectionMagicSignIn(
      input: {
        applet_slug: $applet_slug
        email: $email
        redirect_uri: $redirect_uri
        locale: $locale
      }
    ) {
      errors {
        attribute
        message
      }
    }
  }
`

const LiveChannelCreateMutation = gql`
  mutation LiveChannelCreateMutation(
    $channel_module_name: String!
    $oauth_code: String
    $provisional_access_code: String
  ) {
    liveChannelCreate(
      input: {
        channel_module_name: $channel_module_name
        oauth_code: $oauth_code
        provisional_access_code: $provisional_access_code
      }
    ) {
      errors {
        attribute
        message
      }
      live_channel {
        id
        user_name_field
      }
    }
  }
`

const NormalizedAppletEnableMutation = gql`
  mutation NormalizedAppletEnableMutation(
    $applet_id: String
    $push_enabled: Boolean
    $stored_fields: JSON
    $allow_empty: Boolean
    $metadata: JSON
  ) {
    normalizedAppletEnable(
      input: {
        applet_id: $applet_id
        push_enabled: $push_enabled
        stored_fields: $stored_fields
        allow_empty: $allow_empty
        metadata: $metadata
      }
    ) {
      errors {
        attribute
        message
      }
      user_token
      normalized_applet {
        status
        underlying_applet {
          connect_url_redirect_opts {
            extra_redirect_url_params
          }
        }
      }
    }
  }
`

export const CheckEmailAvailability = email =>
  graphQueryBang(SessionCheckEmailAvailabilityMutation, { email }).then(
    data => data.sessionCheckEmailAvailability.available
  )

export const SendMagicSignIn = ({ appletSlug, email, redirectUri, locale }) =>
  graphQueryBang(MailerSendConnectionMagicSignInMutation, {
    applet_slug: appletSlug,
    email,
    locale,
    redirect_uri: redirectUri,
  }).then(data => data.mailerSendConnectionMagicSignIn)

export const LiveChannelCreate = ({
  service,
  oauthCode,
  provisionalAccessCode,
}) =>
  graphQueryBang(LiveChannelCreateMutation, {
    channel_module_name: service,
    oauth_code: oauthCode,
    provisional_access_code: provisionalAccessCode,
  }).then(data => data.liveChannelCreate)

export const EnableApplet = ({
  appletSlug,
  storedFields,
  pushEnabled = true,
  allowEmpty = false,
  metadata = {},
}) => {
  if (allowEmpty) {
    // Ignore stored fields when allowEmpty is set to emphasis creating an empty applet
    return graphQueryBang(NormalizedAppletEnableMutation, {
      applet_id: appletSlug,
      stored_fields: {},
      push_enabled: pushEnabled,
      allow_empty: allowEmpty,
      metadata: {},
    }).then(data => data.normalizedAppletEnable)
  } else {
    return graphQueryBang(NormalizedAppletEnableMutation, {
      applet_id: appletSlug,
      stored_fields: storedFields,
      push_enabled: pushEnabled,
      metadata,
    }).then(data => data.normalizedAppletEnable)
  }
}
