import { useEffect, useReducer, useRef } from "react"
import PropTypes from "prop-types"
import {
  formatHexColorValue,
  getOverlayColor,
} from "shared/lib/alternate_brand_color"
import debounce from "lodash/debounce"
import qs from "qs"
import isEmpty from "lodash/isEmpty"

import EmailStatusDisplayer from "../connect_sdk_applet/returning_user_flow/email_status_displayer"
import Connect from "../connect_sdk_applet/returning_user_flow/connect"
import Header from "../connect_sdk_applet/returning_user_flow/header"
import VerifyEmailAddress from "./verify_email_address"
import ClickVerify from "./click_verify"
import CustomerCTA from "./customer_cta"
import ErrorElement from "./error"
import RubberBandPinchColor from "app/scripts/rubber_band_pinch_color"
import LearnMore from "./learn_more"
import colors from "foundation/_colors_export.scss?variables"

import { reducer } from "./reducer"
import { LiveChannelCreate, EnableApplet } from "./mutations"

import "../connect_sdk_applet/returning_user_flow/returning_user_flow.scss"

const ENABLED_STATUS = "enabled_for_user"

export default function Container(props) {
  const [state, dispatch] = useReducer(reducer, {
    user: {
      email: props.email || "",
      avatar: props.avatar,
    },
    owner: { ...props.services[0] },
    dependent: { ...props.services[1] },
    showLearnMore: false,
    view: props.view,
    redirectUri: props.urls.redirectUri,
    nextToConnect: props.nextToConnect,
    errorMessage: props.errorMessage,
  })

  useEffect(
    () => {
      // make the rubberband area the same color as the header div
      RubberBandPinchColor({ checkPath: true })
    },
    [] /* re-execute effect when values in this array have changed, once only when empty, always if omitted. */
  )
  // second effect for a seperate concern
  useEffect(() => {
    const fixViewportOnResize = debounce(() => window.fixViewport())
    window.addEventListener("resize", fixViewportOnResize)

    // the returned method will run when the component is unmounted
    return () => {
      window.removeEventListener("resize", fixViewportOnResize)
    }
  }, [])

  // Change bgColor when Learn is opened to avoid service color when we have leak of content from Learn More view.
  useEffect(() => {
    state.showLearnMore
      ? document.body.classList.add("learn-more-opened")
      : document.body.classList.remove("learn-more-opened")
  }, [state.showLearnMore])

  const toggleLearnMore = event => {
    event.preventDefault()
    dispatch({ type: "TOGGLE_LEARN_MORE" })
  }

  const onSentMagicSignIn = email => {
    dispatch({ type: "EMAIL_UPDATED", value: email })
    dispatch({ type: "UPDATE_VIEW", value: "sending_you_email" })
  }

  const onEmailChange = email => {
    dispatch({ type: "EMAIL_UPDATED", value: email })
  }

  const connectService = (state, isOwner) => {
    const service = isOwner ? state.owner : state.dependent
    dispatch({ type: "UPDATE_NEXT_TO_CONNECT", value: service.module_name })

    if (service.can_be_autoactivated) {
      LiveChannelCreate({ service: service.module_name }).then(
        liveChannelCreate => {
          if (isEmpty(liveChannelCreate.live_channel)) {
            dispatch({ type: "UPDATE_VIEW", value: "error" })
          } else {
            if (isOwner) {
              dispatch({ type: "OWNER_CONNECTED" })
              connectService(state, false)
            } else {
              dispatch({ type: "DEPENDENT_CONNECTED" })
              dispatch({
                type: "UPDATE_VIEW",
                value: "connecting_to",
              })
            }
          }
        }
      )
    } else {
      const baseActivationUrl = props.urls.activationPath
      const params = qs.parse(location.search, { ignoreQueryPrefix: true })
      params.next = service.module_name
      delete params.code

      const queryPart = qs.stringify(
        {
          c: [service.module_name],
          return_to: `${location.origin}${location.pathname}?${qs.stringify(
            params
          )}`,
        },
        { arrayFormat: "brackets" }
      )
      const fullActivationUrl = `${baseActivationUrl}?${queryPart}`

      if (
        state.view === "going_to" &&
        state.nextToConnect === service.module_name
      ) {
        location.assign(fullActivationUrl)
      } else {
        afterTransitionScreen.current = () => location.assign(fullActivationUrl)
        dispatch({ type: "UPDATE_VIEW", value: "going_to" })
      }
    }
  }

  const authFlow = boundState => {
    if (window.App.env && window.App.allowDebugErrorMessages) {
      if (isEmpty(props.urls.configUrl) && isEmpty(props.slug)) {
        console.warn("configUrl and slug are necessary")
      }
    }

    if (!boundState.owner.connected) {
      if (props.code) {
        LiveChannelCreate({
          service: boundState.owner.module_name,
          provisionalAccessCode: props.code,
        })
          .then(liveChannelCreate => {
            if (
              isEmpty(liveChannelCreate.live_channel) &&
              !isEmpty(liveChannelCreate.errors)
            ) {
              throw new Error(JSON.stringify(liveChannelCreate.errors))
            } else {
              dispatch({ type: "OWNER_CONNECTED" })
              connectService(boundState, false)
            }
          })
          .catch(() => {
            connectService(boundState, true)
          })
      } else {
        connectService(boundState, true)
      }
      return
    }

    if (!boundState.dependent.connected) {
      connectService(boundState, false)
      return
    }

    if (redirectToConfigs(props)) {
      location.assign(props.urls.configUrl)
    } else if (props.connectionStatus !== ENABLED_STATUS) {
      EnableApplet({
        appletSlug: props.slug,
        storedFields: {},
        allowEmpty: props.skipConfig,
        metadata: {
          configurations: props.configurations.map(c => {
            return { slug: c.slug, disabled: false }
          }),
        },
      }).then(normalizedAppletEnable => {
        if (!isEmpty(normalizedAppletEnable.errors)) {
          dispatch({
            type: "UPDATE_ERROR_MESSAGE",
            value: normalizedAppletEnable.errors[0].message,
          })
          dispatch({ type: "UPDATE_VIEW", value: "error" })
        } else if (
          normalizedAppletEnable.normalized_applet.status === ENABLED_STATUS
        ) {
          if (
            normalizedAppletEnable.normalized_applet.underlying_applet
              ?.connect_url_redirect_opts?.extra_redirect_url_params
          ) {
            const url = new URL(boundState.redirectUri)
            const params = qs.parse(url.search, { ignoreQueryPrefix: true })
            const queryPart = qs.stringify({
              ...params,
              ...normalizedAppletEnable.normalized_applet.underlying_applet
                .connect_url_redirect_opts.extra_redirect_url_params,
            })
            url.search = `?${queryPart}`
            dispatch({
              type: "UPDATE_REDIRECT_URI",
              value: url.toString(),
            })
          }

          dispatch({ type: "UPDATE_VIEW", value: "cta" })
        }
      })
    } else if (props.connectionStatus === ENABLED_STATUS) {
      dispatch({ type: "UPDATE_VIEW", value: "cta" })
    }
  }

  const afterTransitionScreen = useRef(authFlow)

  const { services, code, localization } = props
  const {
    submitUrl,
    manageUrl,
    connectionUrl,
    termsUrl,
    appStoreUrl,
    googlePlayStoreUrl,
  } = props.urls

  const nextServiceToConnect =
    [state.owner, state.dependent].find(
      s => s.module_name === state.nextToConnect
    ) || state.dependent
  const fullScreenBrandColor = ["going_to", "connecting_to", "error"].includes(
    state.view
  )
    ? nextServiceToConnect.brand_color
    : state.dependent.brand_color
  const bgColor = state.showLearnMore
    ? colors.primaryTextColor
    : formatHexColorValue(fullScreenBrandColor)
  const overlayColor = getOverlayColor(fullScreenBrandColor)

  return (
    <div
      styleName="returning_user_flow"
      className="connection-header-container"
      style={{
        backgroundColor: bgColor,
      }}
    >
      {state.showLearnMore ? (
        <LearnMore
          services={services}
          toggle={toggleLearnMore}
          connectionUrl={connectionUrl}
          termsUrl={termsUrl}
          appStoreUrl={appStoreUrl}
          googlePlayStoreUrl={googlePlayStoreUrl}
          localization={localization.about_ifttt}
        />
      ) : (
        <>
          <Header
            services={services}
            idStrategy={getEmailForView({
              initialEmail: props.email,
              currentEmail: state.user.email,
              view: state.view,
            })}
            connected={state.view === "cta"}
            avatar={state.avatar}
            localization={localization.header}
          />
          <div styleName="connect-url-container">
            {(() => {
              switch (state.view) {
                case "verify_email_address":
                  return (
                    <VerifyEmailAddress
                      slug={props.slug}
                      onEmailChange={onEmailChange}
                      onSentMagicSignIn={onSentMagicSignIn}
                      email={state.user.email}
                      submitUrl={submitUrl}
                      redirectUri={state.redirectUri}
                      code={code}
                      skipConfig={props.skipConfig}
                      locale={props.locale}
                      toggleLearnMore={toggleLearnMore}
                      overlayColor={overlayColor}
                      localization={localization.learn_more}
                    />
                  )
                case "click_verify":
                  return (
                    <ClickVerify
                      onSubmit={authFlow.bind(null, state)}
                      overlayColor={overlayColor}
                      localization={localization.click_verify}
                    />
                  )
                case "cta":
                  return (
                    <CustomerCTA
                      redirectUri={state.redirectUri}
                      manageUrl={manageUrl}
                      overlayColor={overlayColor}
                      localization={localization.cta}
                    />
                  )
                case "sending_you_email":
                  return (
                    <EmailStatusDisplayer
                      idStrategy={state.user.email}
                      brandColor={state.dependent.brand_color}
                      userDevice="web"
                      localization={localization.verify_email_address}
                      connectionSlug={props.slug}
                    />
                  )
                case "creating_account_for_you":
                case "going_to":
                case "connecting_to":
                  return (
                    <Connect
                      nextToConnect={nextServiceToConnect}
                      email={state.user.email}
                      onAnimationEnd={afterTransitionScreen.current.bind(
                        null,
                        state
                      )}
                      view={state.view}
                      createMagickAccount={
                        state.view === "creating_account_for_you"
                      }
                      beforeVerification={state.view === "going_to"}
                      localization={localization.connect}
                    />
                  )
                case "error":
                  return (
                    <ErrorElement
                      errorMessage={state.errorMessage}
                      overlayColor={overlayColor}
                      onSubmit={authFlow.bind(null, state)}
                      localization={localization.error}
                    />
                  )
                default:
                  return null
              }
            })()}
          </div>
        </>
      )}
    </div>
  )
}

function redirectToConfigs({ connectionStatus, needsConfig, skipConfig }) {
  return connectionStatus !== ENABLED_STATUS && needsConfig && !skipConfig
}

function getEmailForView({ initialEmail, currentEmail, view }) {
  switch (view) {
    case "verify_email_address":
      return initialEmail
    default:
      return currentEmail
  }
}

Container.propTypes = {
  services: PropTypes.array.isRequired,
  slug: PropTypes.string,
  email: PropTypes.string,
  code: PropTypes.string,
  idStrategy: PropTypes.string,
  avatar: PropTypes.string,
  view: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  nextToConnect: PropTypes.string,
  connectionStatus: PropTypes.string,
  needsConfig: PropTypes.bool,
  skipConfig: PropTypes.bool,
  configurations: PropTypes.array.isRequired,
  locale: PropTypes.string,
  urls: PropTypes.shape({
    activationPath: PropTypes.string,
    redirectUri: PropTypes.string,
    submitUrl: PropTypes.string,
    manageUrl: PropTypes.string,
    configUrl: PropTypes.string,
    termsUrl: PropTypes.string,
    connectionUrl: PropTypes.string,
    appStoreUrl: PropTypes.string,
    googlePlayStoreUrl: PropTypes.string,
  }),
  localization: PropTypes.shape({
    header: PropTypes.object.isRequired,
    connect: PropTypes.object.isRequired,
    cta: PropTypes.object.isRequired,
    learn_more: PropTypes.object.isRequired,
    about_ifttt: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    click_verify: PropTypes.object.isRequired,
    verify_email_address: PropTypes.object.isRequired,
  }).isRequired,
}
