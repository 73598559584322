import UpgradePlanModal from "app/components/upgrade_plan_modal"
import { useState, useCallback } from "react"

interface Props {
  currentTier: string
  isTrialing: boolean
  proPlusProductId: string
  subscriptionPaymentType: string
}

const BillingSwitchPlanLink = ({ currentTier, isTrialing, proPlusProductId, subscriptionPaymentType }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  const onSwitchLinkClick = (evt: MouseEvent) => {
    evt.preventDefault()
    toggleModal()
  }

  return (
    <>
      <a onClick={onSwitchLinkClick} className="link-standalone">
        Switch to Pro+
      </a>
      {showModal && (
        <UpgradePlanModal
          onClose={() => toggleModal()}
          onUpgrade={() => window.location.reload()}
          proPlusProductId={proPlusProductId}
          step="plan_upgrade"
          tierRequirement="pro"
          user={{
            eligibleForTrial: false,
            eligibleTrialPeriod: 0,
            isTrialing,
            subscriptionPaymentType,
            tier: currentTier,
          }}
          visible={showModal}
        />
      )}
    </>
  )
}

export default BillingSwitchPlanLink
