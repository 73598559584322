export default function TransitionEvent() {
  let transition
  const el = document.createElement("fakeelement")

  const transitions = {
    transition: "transitionend",
    OTransition: "oTransitionEnd",
    MozTransition: "transitionend",
    WebkitTransition: "webkitTransitionEnd",
  }

  for (transition in transitions) {
    if (el.style[transition] !== undefined) {
      return transitions[transition]
    }
  }
}
