import "./sizeable_icon.scss"

export default function FacebookOnColorIcon({ height, width, iconStyles }) {
  return (
    <svg
      styleName={iconStyles}
      width={width}
      height={height}
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M241.871 256.001c7.802 0 14.129-6.326 14.129-14.129V14.129C256 6.325 249.673 0 241.871 0H14.129C6.324 0 0 6.325 0 14.129v227.743c0 7.803 6.324 14.129 14.129 14.129h227.742"
        fill="#FFF"
      />
      <path
        d="M176.635 256.001v-99.137h33.277l4.982-38.635h-38.259V93.561c0-11.186 3.107-18.809 19.148-18.809l20.459-.009V40.188c-3.54-.471-15.684-1.523-29.812-1.523-29.498 0-49.692 18.005-49.692 51.071v28.493h-33.362v38.635h33.362v99.137h39.897"
        fill="#3B579D"
      />
    </svg>
  )
}

FacebookOnColorIcon.defaultProps = {
  width: 16,
  height: 16,
  iconStyles: "icon",
}
