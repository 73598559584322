import { Component } from "react"
import PropTypes from "prop-types"
import { formatHexColorValue } from "shared/lib/alternate_brand_color"

import "./returning_user_flow.scss"

import Header from "./header"
import EmailStatusDisplayer from "./email_status_displayer"

import debounce from "lodash/debounce"

import RubberBandPinchColor from "app/scripts/rubber_band_pinch_color"

export default class ReturningUserFlow extends Component {
  fixViewportOnResize = () => {
    debounce(() => window.fixViewport())
  }

  componentDidMount() {
    window.addEventListener("resize", this.fixViewportOnResize)

    // make the rubberband area the same color as the header div
    RubberBandPinchColor({ checkPath: true })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.fixViewportOnResize)
  }

  render() {
    const {
      idStrategy,
      services,
      emailAppScheme,
      userDevice,
      localization,
      connectionSlug,
      sdkAnonymousId,
    } = this.props

    return (
      <div
        styleName="returning_user_flow"
        className="connection-header-container"
        style={{
          backgroundColor: formatHexColorValue(services[1].brand_color),
        }}
      >
        <Header
          services={services}
          idStrategy={idStrategy}
          localization={localization.header}
        />
        <EmailStatusDisplayer
          idStrategy={idStrategy}
          emailAppScheme={emailAppScheme}
          brandColor={services[1].brand_color}
          userDevice={userDevice}
          localization={localization.verify_email_address}
          connectionSlug={connectionSlug}
          sdkAnonymousId={sdkAnonymousId}
        />
      </div>
    )
  }
}

ReturningUserFlow.propTypes = {
  connectionSlug: PropTypes.string.isRequired,
  sdkAnonymousId: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      lrg_monochrome_image_url: PropTypes.string,
      lrg_variant_image_url: PropTypes.string,
    })
  ),
  emailAppScheme: PropTypes.string,
  idStrategy: PropTypes.string.isRequired,
  userDevice: PropTypes.string.isRequired,
  localization: PropTypes.shape({
    header: PropTypes.object.isRequired,
    verify_email_address: PropTypes.object.isRequired,
  }).isRequired,
}

ReturningUserFlow.defaultProps = {
  services: [],
}
