import { Fragment, useEffect, useState } from "react"
import { captureException } from "@sentry/react"

import DIYTandaSelect from "./diy_tanda_select"
import DIYTandaSelected from "./diy_tanda_selected"

import "./initial_step.scss"
import DIYAddTandaButton from "./diy_add_tanda_button"
import DIYAddFilterQueryButtons from "./diy_add_filter_query_buttons"
import Spinner from "shared/components/spinner"
import { knownErrors } from "./utils/error_map"

import colors from "foundation/_colors_export.scss?variables"

import { editApplet, updateCommunityApplet } from "./api"
import { formatToTimestamp } from "./utils/format_time"
import { attachNewBadgeForButtons, updatePermissionInStorage } from "shared/scripts/new_badge_button"
import { Action, Query, DIYComposerState, Trigger, Service, Tier } from "./utils/interfaces"
import { compareTier } from "./tiers"

interface Props {
  dispatch: (v: object) => void
  editing: boolean
  makeItYourOwnFeaturePrompt: () => void
  makingItTheirOwn: boolean
  mobileFilterCodeGate: () => void
  onCreationSuccess: () => void
  proFeatureGate: (v: string) => void
  showFilterCodeModal: () => void
  showUpgradeModal: (v: string) => void
  sourceAppletId: string | null
  state: DIYComposerState
  userOwned: boolean
  user: {
    permissions: {
      [key: string]: {
        permitted: boolean
      }
    }
    tier: Tier
  }
}

interface UpdateAppletResult {
  errors: Array<{
    attribute: string
    message: string
  }>
  user_token?: string
  normalized_applet: {
    id: string
  }
}
const InitialStep = ({
  editing,
  sourceAppletId,
  userOwned,
  dispatch,
  state,
  user,
  makeItYourOwnFeaturePrompt,
  makingItTheirOwn,
  mobileFilterCodeGate,
  proFeatureGate,
  onCreationSuccess,
  showFilterCodeModal,
  showUpgradeModal,
}: Props) => {
  const showProFeatures = user.tier === "pro"
  const triggerAdded = !!state.selectedTrigger
  const queriesAdded = state.selectedQueries.length > 0
  const filterCodeAdded = !!state.filterCode
  const delayAdded = state.delay > 0
  const actionsAdded = state.selectedActions.length > 0
  const [queryFilterButtonsVisible, updateQueryFilterButtons] = useState(false)
  const [saving, updateSaving] = useState(false)

  useEffect(() => {
    const hasChanges = editing || triggerAdded || queriesAdded || filterCodeAdded || actionsAdded || delayAdded

    if (!hasChanges) {
      dispatch({ type: "SET_OVERRIDE_STATE", dataChanged: false })
    }

    attachNewBadgeForButtons()
  }, [editing, triggerAdded, queriesAdded, filterCodeAdded, actionsAdded, delayAdded])

  const onUpdate = ({ errors, normalized_applet }: UpdateAppletResult) => {
    const id = normalized_applet?.id

    if (!id || errors?.length) {
      onUpdateFailure(errors)
    } else {
      let callback = () => window.history.back()
      if (makingItTheirOwn) {
        callback = () => window.location.assign(`/applets/${id}`)
      }

      onCreationSuccess?.()
      window.appendFlash(`Applet ${makingItTheirOwn ? "saved" : "updated"}`, "success", callback)
    }
  }

  const onUpdateFailure = (errors: Array<{ attribute: string, message?: string }>) => {
    updateSaving(false)

    const attributeHasError = (attribute: string) => errors?.some(e => e.attribute === attribute)

    let displayedErrorCopy = "Your Applet can't be created. Please contact support."

    if (attributeHasError("/applet/filter_code")) {
      dispatch({ type: "FILTER_CODE_ERROR" })
      displayedErrorCopy = "Your Applet can't be created because of a filter code error."
    } else if (errors[0]?.message) {
      displayedErrorCopy = errors[0].message

      if (displayedErrorCopy == "Applet actions is invalid") {
        displayedErrorCopy = "Your Applet has an action error. Please check your actions."
      }
    }

    console.error(errors)
    window.appendFlash(displayedErrorCopy, "danger")
  }

  const onClickContinue = () => {
    if (compareTier(state.appletTier, user.tier) > 0) {
      let feature = "default"
      if (state.appletFeatures.length === 1) {
        feature = state.appletFeatures[0]
      }

      showUpgradeModal(feature)
    } else {
      updateSaving(true)
      dispatch({
        type: "SAVING",
        value: true,
      })

      const communityApplet = editing && !(userOwned || makingItTheirOwn)

      if (communityApplet) {
        handleCommunityApplet()
      } else if (editing) {
        handleEditApplet()
      } else {
        dispatch({ type: "PREVIEW" })
      }
    }
  }

  const handleCommunityApplet = () => {
    updateCommunityApplet(state)
      .then(({ normalizedAppletEnable }: { normalizedAppletEnable: UpdateAppletResult }) =>
        onUpdate(normalizedAppletEnable)
      )
      .catch(err => {
        captureException(err)
        onUpdateFailure(err)
      })
  }

  const handleEditApplet = () => {
    editApplet({
      appletId: sourceAppletId as string,
      name: state.name,
      trigger: state.selectedTrigger as Trigger,
      queries: state.selectedQueries,
      actions: state.selectedActions,
      filterCode: state.filterCode,
      delay: state.delay,
    })
      .then(({ diyAppletEdit }: { diyAppletEdit: UpdateAppletResult }) => {
        onUpdate(diyAppletEdit)
      })
      .catch(err => {
        captureException(err)
        onUpdateFailure(err)
      })
  }

  return (
    <>
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          <section styleName="diy-block">
            {triggerAdded ? (
              <DIYTandaSelected
                canEdit={state.selectedTrigger.trigger_fields.length > 0}
                label="if"
                stepName={state.selectedTrigger.name}
                service={state.selectedTrigger.service as Service}
                liveChannelId={state.selectedTrigger.live_channel_id}
                onEditClick={async () => {
                  dispatch({
                    type: "SELECTING_TRIGGER_FIELDS",
                    value: state.selectedTrigger,
                  })
                }}
                onDeleteClick={async (dataConfirmation: () => Promise<void>) => {
                  try {
                    await makeItYourOwnFeaturePrompt()
                    if (queriesAdded || filterCodeAdded || actionsAdded) {
                      await dataConfirmation()
                    }
                    dispatch({ type: "TRIGGER_REMOVED" })
                  } catch (err) {
                    if (!knownErrors.includes(err as string)) {
                      throw new Error(err as string)
                    }
                  }
                }}
                showProFeatures={showProFeatures}
              />
            ) : (
              <DIYTandaSelect label="if" onClick={() => dispatch({ type: "SELECTING_TRIGGER_SERVICE" })} />
            )}
            <div styleName="separator" />

            {queriesAdded && (
              <>
                {state.selectedQueries.map((query: Query, index: number) => (
                  <Fragment key={index}>
                    <DIYTandaSelected
                      canEdit={query.query_fields.length > 0}
                      label="with"
                      stepName={query.name}
                      service={query.service}
                      liveChannelId={query.live_channel_id}
                      onEditClick={async () => {
                        dispatch({
                          type: "SELECTING_QUERY_FIELDS",
                          value: query,
                          index,
                        })
                      }}
                      onDeleteClick={async dataConfirmation => {
                        await proFeatureGate("queries")
                        await makeItYourOwnFeaturePrompt()
                        await dataConfirmation()
                        dispatch({
                          type: "QUERY_REMOVED",
                          value: index,
                        })
                      }}
                      showProFeatures={showProFeatures}
                    />
                    <div styleName="separator" />
                  </Fragment>
                ))}
              </>
            )}

            {filterCodeAdded && (
              <>
                <DIYTandaSelected
                  canEdit
                  label="when"
                  stepName="Filter code"
                  service={{
                    allow_multiple_live_channels: false,
                    brand_color: colors.primaryTextColor,
                    id: "",
                    image: "",
                    live_channels: [],
                    name: "",
                  }}
                  liveChannelId="0"
                  onEditClick={async () => {
                    await proFeatureGate("filter_code")
                    await mobileFilterCodeGate()
                    await makeItYourOwnFeaturePrompt()
                    dispatch({
                      type: "EDITING_FILTER_CODE",
                    })
                  }}
                  onDeleteClick={async dataConfirmation => {
                    await proFeatureGate("filter_code")
                    await makeItYourOwnFeaturePrompt()
                    await dataConfirmation()
                    dispatch({
                      type: "FILTER_CODE_REMOVED",
                    })
                  }}
                />
                <div styleName="separator" />
              </>
            )}

            {triggerAdded && (
              <>
                {queryFilterButtonsVisible ? (
                  <>
                    <DIYAddFilterQueryButtons
                      onDelayButtonClick={async () => {
                        await makeItYourOwnFeaturePrompt()
                        dispatch({
                          type: delayAdded ? "EDITING_DELAY_ACTION" : "ADDING_DELAY_ACTION",
                        })
                      }}
                      onQueryButtonClick={async () => {
                        await updatePermissionInStorage("queries", false)
                        await proFeatureGate("queries")
                        await makeItYourOwnFeaturePrompt()
                        dispatch({ type: "SELECTING_QUERY_SERVICE" })
                      }}
                      onFilterButtonClick={async () => {
                        await updatePermissionInStorage("filter_code", false)
                        await proFeatureGate("filter_code")
                        await mobileFilterCodeGate()
                        await makeItYourOwnFeaturePrompt()
                        if (actionsAdded) {
                          dispatch({
                            type: filterCodeAdded ? "EDITING_FILTER_CODE" : "ADDING_FILTER_CODE",
                          })
                        } else {
                          await showFilterCodeModal()
                        }
                      }}
                      filterCodeAdded={filterCodeAdded}
                      delayAdded={delayAdded}
                      userTier={user.tier}
                    />
                  </>
                ) : (
                  <DIYAddTandaButton
                    anchorTitle="Add queries, delay action, or filter code"
                    allowNewBadge="filter_code_queries_delay"
                    onClick={() => {
                      updateQueryFilterButtons(true)
                    }}
                  />
                )}
              </>
            )}

            {delayAdded && (
              <>
                <div styleName="separator" />
                <DIYTandaSelected
                  canEdit
                  label="wait"
                  stepName={formatToTimestamp(state.delay)}
                  service={{
                    brand_color: colors.primaryTextColor,
                    image: "",
                    name: "",
                    id: "",
                  }}
                  onEditClick={async () => {
                    dispatch({
                      type: "EDITING_DELAY_ACTION",
                    })
                  }}
                  onDeleteClick={async () => {
                    await makeItYourOwnFeaturePrompt()
                    dispatch({
                      type: "DELAY_ACTION_REMOVED",
                    })
                  }}
                />
              </>
            )}

            {actionsAdded ? (
              <>
                {state.selectedActions.map((action: Action, index: number) => (
                  <Fragment key={index}>
                    <div styleName="separator" />
                    <DIYTandaSelected
                      canEdit={action.action_fields.length > 0}
                      label={index === 0 ? "then" : "and"}
                      stepName={action.name}
                      service={action.service}
                      liveChannelId={action.live_channel_id}
                      onEditClick={async () => {
                        if (index > 0) {
                          await proFeatureGate("multi_action")
                        }

                        dispatch({
                          type: "SELECTING_ACTION_FIELDS",
                          value: action,
                          index,
                        })
                      }}
                      onDeleteClick={async dataConfirmation => {
                        if (index > 0) {
                          await proFeatureGate("multi_action")
                        }

                        try {
                          await makeItYourOwnFeaturePrompt()
                          await dataConfirmation()
                          dispatch({
                            type: "ACTION_REMOVED",
                            value: index,
                          })
                        } catch (err) {
                          if (!knownErrors.includes(err as string)) {
                            throw new Error(err as string)
                          }
                        }
                      }}
                      showProFeatures={showProFeatures}
                    />
                  </Fragment>
                ))}
                {triggerAdded && (
                  <>
                    <div styleName="separator" />
                    <DIYAddTandaButton
                      hasProBadge={!user.permissions["multi_action"]?.permitted}
                      anchorTitle="Add more actions"
                      allowNewBadge="multi_action"
                      onClick={async () => {
                        try {
                          await proFeatureGate("multi_action")
                          await makeItYourOwnFeaturePrompt()
                          dispatch({
                            type: "SELECTING_ACTION_SERVICE",
                            value: true,
                          })
                        } catch (err) {
                          if (!knownErrors.includes(err as string)) {
                            throw new Error(err as string)
                          }
                        }
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <div styleName="separator" />
                <DIYTandaSelect
                  disabled={!triggerAdded}
                  label="then"
                  onClick={() => dispatch({ type: "SELECTING_ACTION_SERVICE" })}
                />
              </>
            )}
          </section>

          {triggerAdded && actionsAdded && (
            <section styleName="footer">
              <button
                className="button-primary"
                onClick={onClickContinue}
                disabled={!state.dataChanged || saving}
                {...(!editing && { "data-dd-action-name": "diy_applet_maker_setup_continue" })}
              >
                {editing ? (makingItTheirOwn ? "Save" : "Update") : "Continue"}
              </button>
            </section>
          )}
        </>
      )}
    </>
  )
}

export default InitialStep
