import { Component } from "react"
import PropTypes from "prop-types"

export default class Description extends Component {
  render() {
    return (
      <div className="connection-description">
        <p className="txt-body-2">{this.props.text}</p>
      </div>
    )
  }
}

Description.propTypes = {
  text: PropTypes.string.isRequired,
}
