import PropTypes from "prop-types"

export const ProfileHeader = props => {
  return (
    <section className="profile-header">
      {props.headerText && <h2>{props.headerText}</h2>}
      <div
        className="profile-avatar-container"
        style={{ backgroundImage: `url(${props.avatar})` }}
      >
        <span role="img" aria-label="Profile image" />
      </div>
      {props.children}
    </section>
  )
}

ProfileHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  children: PropTypes.node,
  headerText: PropTypes.string,
}
