import { useRef } from "react"
import Modal from "shared/components/modal"
import SharedMarkdown from "shared/components/shared_markdown"
import { capitalize } from "humanize-plus"
import OpenLinksInNewWindow from "shared/components/open_links_in_new_window"
import parse from "html-react-parser"

import "./generator_modal.scss"

interface Props {
  generator: Generator
  onClose: object
}

interface Generator {
  description: string
  helper_text: string
  id: string
  title: string
  type: string
  templates: Array<Template>
  variables: Array<Variable>
}

interface Template {
  action: string
  variations: Array<Variation>
  code: string
}

interface Variation {
  code: string
  validation: string
}

interface Variable {
  term: string
  type: string
  options: Array<VariableOption>
}

interface VariableOption {
  label: string
  value: string
}

const GeneratorModal = (props: Props) => {
  const { generator, onClose } = props

  if (!generator) return null

  const generatorTypeSelect = useRef()
  const generatorDescription = useRef()

  const onCopyClick = (): void => {
    const action = generatorTypeSelect.current.value
    const template = generator.templates.find(template => template.action == action)
    const userParamsInput = generatorDescription.current.querySelectorAll("input, select")

    let code = template.variations.find(variation => {
      if (variation.validation == "true") return true

      let validation = variation.validation
      userParamsInput.forEach(input =>
        validation = validation.replaceAll(input.name, input.value)
      )

      return eval(validation) == true
    }).code

    userParamsInput.forEach(input =>
      code = code.replace(input.name, input.value)
    )
    navigator.clipboard.writeText(code)
    window.appendFlash("Copied to clipboard", "success")

    onClose()
  }

  const buildTemplateDescription = () => {
    let description:string = generator.description

    generator.variables.map(variable => {
      description = description.replace(variable.term, buildInput(variable))
    })

    return description
  }

  const buildInput = (variable:Variable): string => {
    switch(variable.type) {
      case "dropdown":
        return `<select name="${variable.term}">
          ${variable.options.map(option =>
            `<option value="${option.value}">${option.label}</option>`
          )}
        </select>`
      case "input":
        return `<input name="${variable.term}" type='text' value='keyword'/>`
      default:
        return variable.term
    }
  }

  return (
    <div styleName={`modal-container ${generator.id}`}>
      <Modal show={true} closeListener={onClose} includeCloseX={false}>
        <h3 className="mt0 mb4">{generator.title}</h3>

        {generator.helper_text && (
          <div className="mb4" styleName="helper-text">
            <OpenLinksInNewWindow>
              <SharedMarkdown source={generator.helper_text} />
            </OpenLinksInNewWindow>
          </div>
        )}

        <section styleName="template">
          <div>
            <select ref={generatorTypeSelect}>
              {generator.templates.map(template => (
                <option key={`${generator.id}-${template.action}`} value={template.action}>{capitalize(template.action)}</option>
              ))}
            </select>
            <span ref={generatorDescription}>{parse(buildTemplateDescription())}</span>
          </div>
        </section>

        <div className="action-buttons-container mt4">
          <button className="button-tertiary" onClick={onCopyClick}>
            Copy to clipboard
          </button>
          <button
            className="button-tertiary button-cancel-link"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default GeneratorModal
