import PropTypes from "prop-types"
import classnames from "classnames"
import parse from "html-react-parser"

import chevronLeftBlack from "images/layout/chevron-left-black.svg"
import chevronLeftWhite from "images/layout/chevron-left-white.svg"
import XIcon from "shared/components/icons/x_icon"

import "./return_button.scss"

export default function ReturnButton({ initialStep, color, onClick }) {
  const text = initialStep ? "Cancel" : "Back"
  const icon = color === "white" ? chevronLeftWhite : chevronLeftBlack
  const buttonStyle = classnames("button-tertiary button-outlined", {
    "button-on-color": color === "white",
    "button-with-icon": !initialStep,
  })
  const chevronStyle = classnames("chevron-icon", { hide: initialStep })
  const xIconStyle = classnames("x-icon", { hide: !initialStep })

  return (
    <div styleName="button-with-icon">
      <a
        className={buttonStyle}
        styleName="link"
        title={text}
        onClick={onClick}
      >
        <span className="button-icon" styleName={xIconStyle}>
          <XIcon width="24" height="24" color={color} />
        </span>
        <span className="button-icon" styleName={chevronStyle}>
          {parse(icon)}
        </span>
        <span styleName="text">{text}</span>
      </a>
    </div>
  )
}

ReturnButton.propTypes = {
  initialStep: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

ReturnButton.defaultProps = {
  initialStep: false,
  color: "white",
  onClick: () => {},
}
