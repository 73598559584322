import PropTypes from "prop-types"

import FadeIn from "shared/components/fade_in"

import ChevronRightIcon from "shared/components/icons/chevron_right_icon"
import InfiniteFooter from "./infinite_footer"

import colors from "foundation/_colors_export.scss?variables"

export default function FooterLink({
  label,
  backgroundColor,
  onClick,
  href,
  leftIcon,
  rightIcon,
  fadeIn,
  fadeInDelay,
}) {
  if (backgroundColor === "darken") {
    backgroundColor = "rgba(0, 0, 0, 0.2)"
  } else if (backgroundColor === "lighten") {
    backgroundColor = "rgba(255, 255, 255, 0.15)"
  } else if (!backgroundColor) {
    backgroundColor = colors.quaternaryBackgroundColor
  }

  return (
    <FadeIn fade={fadeIn ? 500 : 0} delay={fadeInDelay || 0}>
      <a
        className="sdk-footer-link"
        href={href}
        style={{ backgroundColor }}
        onClick={onClick}
      >
        {leftIcon && (
          <span className="sdk-footer-link-left-icon">{leftIcon}</span>
        )}
        <span className="sdk-footer-link-label">{label}</span>
        {rightIcon && (
          <span className="sdk-footer-link-right-icon">{rightIcon}</span>
        )}
      </a>
      <InfiniteFooter backgroundColor={backgroundColor} />
    </FadeIn>
  )
}

FooterLink.propTypes = {
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  fadeIn: PropTypes.bool,
  fadeInDelay: PropTypes.number,
}

FooterLink.defaultProps = {
  leftIcon: null,
  rightIcon: <ChevronRightIcon />,
  fadeIn: false,
  fadeInDelay: 500,
}
