import { gql } from "shared/lib/graphql"

export const InviteUserToGroupMutation = gql`
  mutation InviteUserToGroupMutation($user_email: String!, $group_guid: String!) {
    inviteUserToGroup(
      input: { user_email: $user_email, group_guid: $group_guid }
    ) {
      errors {
        attribute
        message
      }
    }
  }
`

export const LeaveGroupMutation = gql`
  mutation LeaveGroupMutation($group_guid: String!, $user_email: String!) {
    userGroupMemberDelete(
      input: { group_guid: $group_guid, user_email: $user_email }
    ) {
      errors {
        attribute
        message
      }
    }
  }
`
export const RescindInvitationMutation = gql`
  mutation RescindInvitationMutation($group_guid: String!, $user_email: String!) {
    userGroupInviteRescind(
      input: { group_guid: $group_guid, user_email: $user_email }
    ) {
      errors {
        attribute
        message
      }
    }
  }
`

export const RespondToInvitationMutation = gql`
  mutation RespondToInvitationMutation($group_guid: String!, $accepted: Boolean!) {
    userGroupInviteRespond(
      input: { group_guid: $group_guid, accepted: $accepted }
    ) {
      errors {
        attribute
        message
      }
    }
  }
`
