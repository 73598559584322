import { Component } from "react"
import PropTypes from "prop-types"

import ProfileProviderView from "./profile_provider_view"

export default class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: props.source,
    }
  }

  render() {
    const defaultUserProps = {
      profile: this.props.profile.me,
      email: this.props.email,
      source: this.state.source,
    }

    return (
      <div className="profile-container">
        <ProfileProviderView
          avatar={this.props.avatar}
          profileProviders={this.props.profile.profile_providers}
          updateProviderPath={this.props.updateProviderPath}
          backButtonPath={this.props.backButtonPath}
          {...defaultUserProps}
        />
      </div>
    )
  }
}

Profile.propTypes = {
  avatar: PropTypes.string,
  profile: PropTypes.object,
  email: PropTypes.string,
  updateProviderPath: PropTypes.string,
  backButtonPath: PropTypes.string.required,
  source: PropTypes.string,
}
