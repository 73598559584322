import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useBgColorContext } from "./context"

import LimitedTextInput from "shared/limited_text_input"
import { headerColored } from "./utils/container_background_color"
import Spinner from "shared/components/spinner"
import ErrorMessageForField from "./error_message"

import { createApplet } from "./api"
import { formatCreateErrors } from "./utils/format_create_errors"

import "./preview.scss"

export default function Preview({
  actions,
  errors,
  filterCode,
  onCreationError,
  onCreationSuccess,
  preview,
  queries,
  trigger,
  userLogin,
  delay,
  userAtQuota,
  showAppletQuotaModal,
  upgradeWarningCopy,
}) {
  const bgColor = useBgColorContext()
  const [submitingApplet, updateSubmitingApplet] = useState(false)
  const [titleReady, updateTitleReady] = useState(false)
  const titleLimit = 140

  useEffect(() => {
    titleLengthValidation(preview.name)
  }, [preview.name])

  useEffect(() => {
    headerColored(bgColor)

    return () => {
      headerColored("initial")
    }
  }, [bgColor])

  useEffect(() => {
    if (errors?.applet?.base) {
      window.appendFlash(errors.applet.base[0].message || "Could not create your Applet.", "danger")
    }
  }, [errors])

  const titleLengthValidation = title => {
    const length = title?.length || 0
    updateTitleReady(length > 0 && length <= titleLimit)
  }

  const onClick = e => {
    e.preventDefault()
    window.App.Utils?.logCustomDatadogAction("diy_applet_maker_preview_finish")

    if (userAtQuota) {
      return showAppletQuotaModal()
    } else {
      const description = document.querySelector("textarea[name='description']").value
      return onSubmit({ description })
    }
  }

  const onSubmit = async ({ description }) => {
    let response = {}
    let errors = {}

    try {
      updateSubmitingApplet(true)

      response = await createApplet({
        actions,
        description,
        filterCode,
        queries,
        serviceSlug: preview.serviceSlug,
        trigger,
        delay,
      })

      errors = response?.diyAppletCreate?.errors

      const appletId = response?.diyAppletCreate?.normalized_applet?.id

      if (appletId) {
        onCreationSuccess?.()
        window.App.Utils?.logCustomDatadogAction?.("diy_applet_enabled", { applet_id: appletId })
        location.assign(`/applets/${appletId}`)
      } else {
        updateSubmitingApplet(false)

        if (errors !== null) {
          onCreationError(formatCreateErrors(errors))
        } else {
          onCreationError({
            applet: {
              base: "An unknown error occurred. Please try again later.",
            },
          })
        }
      }
    } catch (error) {
      updateSubmitingApplet(false)
      onCreationError({
        applet: { base: "An unknown error occurred. Please try again later." },
      })
    }
  }

  const serviceImages = () => {
    const steps = actions.map(step => step.service)
    steps.unshift(queries.map(step => step.service))
    steps.unshift(trigger.service)

    const reducer = (ac, step) => ({
      ...ac,
      [step.module_name]: {
        name: step.name,
        image: step.image,
      },
    })

    return steps.flat().reduce(reducer, {})
  }

  const buttonCTA = userAtQuota ? "Upgrade" : submitingApplet ? "Finishing..." : "Finish"

  return (
    <>
      {Object.entries(preview).length === 0 ? (
        <Spinner />
      ) : (
        <>
          <>
            <div styleName="preview-header" style={{ backgroundColor: preview.brandColor }}>
              <div styleName="services">
                {Object.entries(serviceImages()).map(([module_name, entry]) => (
                  <img key={module_name} src={entry.image} title={entry.name} alt={entry.name} />
                ))}
              </div>

              <div styleName="diy-meta">
                <span styleName="title">Applet Title</span>
                <ErrorMessageForField errors={errors} field="applet.description" />
                <LimitedTextInput
                  name="description"
                  value={preview.name}
                  styleName="title-input"
                  onChange={value => titleLengthValidation(value)}
                  limit={titleLimit}
                />
                <span styleName="applet-author">
                  by <b>{userLogin}</b>
                </span>
              </div>
            </div>

            <div styleName="cta">
              {userAtQuota && (
                <p styleName="upgrade-warning">
                  {upgradeWarningCopy.title}. {upgradeWarningCopy.copy}
                </p>
              )}

              <button
                type="submit"
                role="button"
                disabled={!titleReady || submitingApplet}
                onClick={onClick}
                className="button-primary"
                data-dd-action-name="diy_applet_maker_preview_finish"
              >
                {buttonCTA}
              </button>
            </div>
          </>
        </>
      )}
    </>
  )
}

Preview.propTypes = {
  actions: PropTypes.array.isRequired,
  errors: PropTypes.object,
  filterCode: PropTypes.string.isRequired,
  onCreationError: PropTypes.func.isRequired,
  onCreationSuccess: PropTypes.func,
  preview: PropTypes.shape({
    brandColor: PropTypes.string,
    name: PropTypes.string,
    serviceSlug: PropTypes.string,
  }),
  queries: PropTypes.array.isRequired,
  trigger: PropTypes.object.isRequired,
  userLogin: PropTypes.string.isRequired,
  delay: PropTypes.number,
  userAtQuota: PropTypes.bool,
  showAppletQuotaModal: PropTypes.func,
  upgradeWarningCopy: PropTypes.shape({
    title: PropTypes.string,
    copy: PropTypes.string,
  }),
}
