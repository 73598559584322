import { useState } from "react"
interface Props {
  context: string
  services: Array<Service>
}

interface Service {
  image: string
  module_name: string
  name: string
  normalized_module_name: string
}

const ServiceList = (props: Props) => {
  const DEFAULT_VISIBLE = 2
  const MAX_TO_SHOW = 15

  const [numberVisibleServices, updateNumberOfServicesToShow] = useState(DEFAULT_VISIBLE)

  const servicesNonDuplicated = Object.entries(
    props.services.reduce(
      (acc, service) => ({
        ...acc,
        [service.name]: service,
      }),
      {}
    )
  )
  const totalServices = servicesNonDuplicated.length
  const remainingSlots = totalServices - numberVisibleServices
  const showExpanderCounter = remainingSlots > 0 && numberVisibleServices != MAX_TO_SHOW

  return (
    <>
      {servicesNonDuplicated.slice(0, numberVisibleServices).map(([serviceName, service]) => (
        <a
          key={`${props.context}-${serviceName}`}
          href={`/${service.module_name}/details`}
          target="_blank"
          title={`Click to see documentation for ${serviceName}`}
        >
          <img src={service.image}/>
        </a>
      ))}
      {showExpanderCounter && (
        <a
          key={`${props.context}-expand`}
          onClick={() => updateNumberOfServicesToShow(MAX_TO_SHOW)}
          title={`Click to see all services`}
        >
          +{remainingSlots}
        </a>
      )}
    </>
  )
}

export default ServiceList
