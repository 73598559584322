import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import find from "lodash/find"

import AuthorInfo from "./author_info"
import GrowingTextArea from "shared/growing_text_area"
import "./header.scss"
import sparkleIcon from "images/icons/icon-sparkle-white.svg"

export default class Header extends Component {
  titleLimit = 140

  constructor(props) {
    super(props)

    this.state = {
      editable: props.includeEditableTitle,
      editableClass: props.includeEditableTitle ? "title-editable" : "",
      editing: false,
      change: false,
      title: props.connection.name,
      titleValid: true,
    }
  }

  onEditTitleClick = () => {
    this.setState({ editing: true })
    setTimeout(() => this.inputRef.focus(), 0)
  }

  handleBlurTextarea = () => {
    // Change the state milliseconds later to avoid called the edit click action
    // if the user click outsite textarea but inside connection-edition div.
    setTimeout(() => this.setState({ editing: false }), 100)

    // Update only when changed and is valid
    if (this.state.change && this.state.titleValid) {
      this.props.onSaveName(this.state.title)
    } else if (!this.state.titleValid) {
      window.appendFlash("There was an error with Applet name", "danger")
    }
  }

  onTextChange = value => {
    this.setState({
      title: value,
      titleValid: value.length <= this.titleLimit,
      change: true,
    })
  }

  altTextForServiceIcon = serviceName => {
    const permission = find(this.props.connection.permissions, {
      service_name: serviceName,
    })
    return permission && `${permission.service_name}: ${permission.name}.`
  }

  render() {
    const bgColor = { backgroundColor: `${this.props.connection.brand_color}` }
    const author = this.props.authorInfo

    return (
      <div style={bgColor} className="connection-header">
        <div className={`cta ${this.state.editableClass}`}>
          {!this.props.programmable && (
            <div className="dependency-icons">
              {Object.keys(this.props.icons).map((key, index) => (
                <img
                  key={index}
                  src={this.props.icons[key]}
                  width="60"
                  height="60"
                  alt={this.altTextForServiceIcon(key)}
                  title={this.altTextForServiceIcon(key)}
                />
              ))}
            </div>
          )}

          {this.state.editable ? (
            this.state.editing ? (
              <div className="connection-title-editable editing">
                <GrowingTextArea
                  ref={r => (this.inputRef = r)}
                  value={this.state.title}
                  onChange={this.onTextChange}
                  onBlur={this.handleBlurTextarea}
                  name="name"
                />

                <div className={`counter ${!this.state.titleValid && "error"}`}>
                  {this.state.title.length}/{this.titleLimit}
                </div>
              </div>
            ) : (
              <div
                className="connection-title-editable"
                onClick={this.onEditTitleClick}
              >
                <div className="connection-title">{this.state.title}</div>

                <div className="edit-action">
                  <span>Edit title</span>
                </div>
              </div>
            )
          ) : (
            <h1 className="connection-title">{this.props.connection.name}</h1>
          )}

          <div styleName="author-info-container">
            <AuthorInfo
              connection={this.props.connection}
              serviceIcon={author.mainServiceIcon}
              linkTo={{
                url: author.linkTo.url,
              }}
            />
            {
              this.props.showCompleteWithAI && (
                <button
                  disabled={this.props.loadingSuggestions}
                  styleName="complete-with-ai"
                  onClick={() => this.props.onCompleteWithAI()}
                  className="button-tertiary"
                >
                  <span>{parse(sparkleIcon)}</span>
                  Complete with AI (Beta)
                </button>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  mainService: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lrg_variant_image_url: PropTypes.string,
  }),
  authorInfo: PropTypes.shape({
    mainServiceIcon: PropTypes.string,
    linkTo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
  icons: PropTypes.object,
  connection: PropTypes.shape({
    author: PropTypes.string,
    brand_color: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    permissions: PropTypes.array,
  }),
  includeEditableTitle: PropTypes.bool,
  programmable: PropTypes.bool,
  onSaveName: PropTypes.func.isRequired,
  loadingSuggestions: PropTypes.bool,
  showCompleteWithAI: PropTypes.bool,
  onCompleteWithAI: PropTypes.func.isRequired,
}

Header.defaultPropTypes = {
  includeEditableTitle: false,
}
