export function fullColoredPage(color, headerColor = color) {
  document.body.style.backgroundColor = color
  const pageElement = document.querySelector(".container.web")
  if (pageElement) {
    pageElement.style.backgroundColor = headerColor
  }
}

export function headerColored(color) {
  fullColoredPage(color, "white")
}
