import { createElement } from "react"

// Replace text with JSX component in the way that isn't rendered as [Object] string.
export function replaceTextWithComponent(text, match, component) {
  const id = Date.now()

  const reduce = (prev, current, i) => {
    if (!i) {
      return [current]
    }
    const {
      type,
      props: { children, ...props },
    } = component
    props.key = `${id}-${i}`
    const componentWithKey = createElement(type, props, children)
    return prev.concat(componentWithKey, current)
  }

  return text
    .split(match)
    .reduce(reduce, [])
    .filter(elem => elem !== "")
}

export function formatTextStyle(textToSearch, elemToReplace) {
  return item => {
    if (Array.isArray(item)) {
      // work only with nodes from tree
      return item.map(formatTextStyle(textToSearch, elemToReplace))
    } else if (typeof item !== "string" || item.length === 0) {
      // Don't check on React components and avoid empty strings
      return item
    }

    return replaceTextWithComponent(item, textToSearch, elemToReplace)
  }
}
