import PropTypes from "prop-types"
import { serviceShape } from "app/lib/shared_prop_types"
import "./service.scss"
import parse from "html-react-parser"
export default function Service({ service }) {
  return (
    <div styleName="service-item">
      <a href={`/${service.module_name}`}>
        <img
          src={service.icon_bw}
          title={service.name}
          alt={service.name}
          width="99"
          height="99"
          loading="lazy"
          styleName="service-icon"
        />
        <h3 styleName="service-title">{service.name}</h3>
      </a>
      <div styleName="service-description">
        <span>{parse(service.description)}</span>
      </div>
      {service.works_well_with_channels.length > 0 && (
        <>
          <div styleName="service-works-well-with">
            <span>Works well with </span>
            {service.works_well_with_channels.length &&
              service.works_well_with_channels.map((s, key) => (
                <span key={key}>
                  <a
                    href={`/connect/${[service.module_name, s.module_name].sort()[0]}/${
                      [service.module_name, s.module_name].sort()[1]
                    }`}
                    title={s.name}
                  >
                    {s.name}
                  </a>
                  {(service.works_well_with_channels.length > key + 2 && ", ") ||
                    (service.works_well_with_channels.length == key + 2 && " & ") ||
                    (service.works_well_with_channels.length == key + 1 && ".")}
                </span>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

Service.propTypes = {
  service: PropTypes.shape({
    call_to_action: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
    description: PropTypes.string.isRequired,
    icon_bw: PropTypes.string.isRequired,
    module_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    works_well_with_channels: PropTypes.arrayOf(serviceShape),
  }),
}
