import PropTypes from "prop-types"

import Modal from "shared/components/modal"
import "../../../upgrade_plan_modal/shared_modal.scss"

const Prompt = ({
  visible,
  prompt,
  onClose,
  onConfirm,
  onCancel,
}) => {
  // events
  function didClickCancel() {
    onCancel?.()
    onClose()
  }

  function didClickConfirm() {
    onConfirm()
    onClose()
  }

  // render
  return (
    <div styleName="modal-container">
      <Modal show={visible} closeListener={didClickCancel}>
        {prompt.title && <h2>{prompt.title}</h2>}

        <p>{prompt.body}</p>

        <div className="action-buttons-container">
          <button
            className="button-tertiary"
            onClick={didClickConfirm}
          >
            {prompt.confirm}
          </button>

          <button
            className="button-tertiary button-cancel-link"
            onClick={didClickCancel}
          >
            {prompt.cancel}
          </button>
        </div>
      </Modal>
    </div>
  )
}

Prompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  prompt: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    confirm: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

export default Prompt
