import PropTypes from "prop-types"

import Modal from "shared/components/modal"

import "../upgrade_plan_modal/shared_modal.scss"

const FilterCodeModal = ({ visible, onClose }) => {
  return (
    <div styleName="modal-container">
      <Modal show={visible} includeCloseX={false}>
        <p className="txt-body-3">
          Before adding filter code to your Applet, add an action.
        </p>
        <div className="action-buttons-container">
          <button className="button-tertiary" onClick={onClose}>
            Ok
          </button>
        </div>
      </Modal>
    </div>
  )
}

FilterCodeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FilterCodeModal
