import { useState, useEffect, useRef } from "react"
import { User, OpenAIResult } from "./utils/interfaces"
import ErrorIcon from "shared/components/icons/error_icon"
import classNames from "classnames"
import parse from "html-react-parser"

import { generateAIAppletSuggestions, AISuggestionResponse } from "./api"
import { captureException, captureMessage, withScope } from "@sentry/react"
import proBadge from "images/plan-badges/pro.svg"

import "./automate_with_ai.scss"
import loadingDots from "images/web/loading-dots.gif"

interface Props {
  active: boolean
  openAIMessage: string
  showPreview: (result: OpenAIResult) => void
  showUpgradeModal: () => void
  updateOpenAIMessage: (message: string) => void
  user: User
}

const AutomateWithAI = ({ active, openAIMessage, showUpgradeModal, showPreview, updateOpenAIMessage, user }: Props) => {
  const [prompt, setprompt] = useState(openAIMessage)
  const [loading, setLoading] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [rateLimited, setRateLimited] = useState(false)
  const [upgradeable, setUpgradeable] = useState(false)
  const [errorMessage, updateErrorMessage] = useState("")

  const examplePrompts = [
    "If there is a new Tweet with a keyword, then add it to a Google Sheet.",
    "If I miss a phone call, then send myself an email.",
    "If an RSS feed has a new item, then send me a notification.",
  ]

  useEffect(() => {
    textAreaRef?.current?.focus?.()
  }, [active])

  useEffect(() => {
    if (user.tier !== "free" && rateLimited && upgradeable) {
      setRateLimited(false)
      setUpgradeable(false)
    }
  }, [user.tier])

  const onContinue = () => {
    if (rateLimited && upgradeable) {
      showUpgradeModal()
      updateErrorMessage("")
      return
    }

    const message: string = textAreaRef.current?.value || ""

    if (user.tier === "free") {
      window.App.Utils?.logCustomDatadogAction?.("free_user_ai_prompt", { message })
    }

    updateErrorMessage("")

    if (message.length === 0) {
      updateErrorMessage("Please enter a prompt.")
    } else {
      setLoading(true)
      updateOpenAIMessage(message)

      window.App.Utils?.logCustomDatadogAction?.("click_submit_ai_applet_maker", { prompt: message })

      generateAIAppletSuggestions(message)
        .then(({ ai_applet_suggestion }: { ai_applet_suggestion: AISuggestionResponse }) => {
          if (ai_applet_suggestion.errors.length === 0) {
            showPreview({
              selectedTrigger: ai_applet_suggestion.trigger,
              selectedQueries: ai_applet_suggestion.queries,
              selectedActions: ai_applet_suggestion.actions,
              enablement_rules: ai_applet_suggestion.enablement_rules,
            })
          } else {
            if (ai_applet_suggestion.errors.find(error => error.attribute === "free_rate_limit")) {
              setRateLimited(true)
              setUpgradeable(true)
              updateErrorMessage(
                "You have reached the free limit of AI Applet creation attempts. Upgrade to Pro or try again later."
              )
            } else if (ai_applet_suggestion.errors.find(error => error.attribute === "paid_rate_limit")) {
              setRateLimited(true)
              setUpgradeable(false)
              updateErrorMessage("You have reached your limit of AI Applet creation attempts. Please try again later.")
            } else {
              updateErrorMessage("Sorry, we could not understand your prompt. Please try again.")
            }
          }
        })
        .catch(error => {
          if (window.App.allowDebugErrorMessages) console.warn(error?.message)
          captureException(error?.message)
          updateErrorMessage("Sorry, we could not understand your prompt. Please try again.")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      <section styleName="ai-maker-section">
        <div styleName="input-area">
          <p styleName="tips">What would you like your Applet to do?</p>
          <div styleName="textarea-wrapper">
            <textarea
              ref={textAreaRef}
              styleName={classNames({ "with-errors": !!errorMessage })}
              placeholder="Ex: If a new Calendly event is added, then create event in Google Calendar."
              rows={3}
              maxLength={500}
              value={prompt}
              onFocus={() => {
                !!errorMessage && updateErrorMessage("")
              }}
              onKeyDown={evt => {
                if (evt.keyCode === 13) {
                  evt.preventDefault()
                  if (rateLimited && !upgradeable) return

                  onContinue()
                }
              }}
              onChange={evt => {
                setprompt(evt.target.value)
                !!errorMessage && updateErrorMessage("")
              }}
            />
          </div>
          {errorMessage ? (
            <div styleName="hint-helper">
              <span styleName="error-message">
                <ErrorIcon width="24" height="24" />
                <span styleName="badge">{errorMessage}</span>
              </span>
            </div>
          ) : (
            <div styleName="example-spacer" />
          )}
        </div>
        {!prompt && (
          <div styleName="examples">
            <p>Or, try these examples:</p>
            <ul>
              {examplePrompts.map((example, index) => (
                <li key={index}>
                  <button
                    onClick={() => {
                      setprompt(example)
                      updateErrorMessage("")
                    }}
                  >
                    {example}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div styleName="controls">
          <ContinueButton
            loading={loading}
            onContinue={onContinue}
            rateLimitExceeded={rateLimited}
            upgradeable={upgradeable}
          />
        </div>
      </section>
    </>
  )
}

const ContinueButton = ({
  onContinue,
  loading,
  rateLimitExceeded,
  upgradeable,
}: {
  onContinue: () => void
  loading: boolean
  rateLimitExceeded: boolean
  upgradeable: boolean
}) => {
  let buttonContents = null

  if (rateLimitExceeded && upgradeable) {
    buttonContents = (
      <span styleName="upgrade-copy">
        <span>Upgrade to</span>
        <span styleName="badge">{parse(proBadge)}</span>
      </span>
    )
  } else if (loading) {
    buttonContents = (
      <>
        <span styleName="loading">Building your Applet</span>
        <img styleName="loading-dots" src={loadingDots} />
      </>
    )
  } else {
    buttonContents = "Continue"
  }

  return (
    <button
      id="continue"
      className="button-secondary"
      onClick={onContinue}
      disabled={rateLimitExceeded && !upgradeable}
    >
      {buttonContents}
    </button>
  )
}

export default AutomateWithAI
