import PropTypes from "prop-types"
import Switch from "shared/components/switch"

import colors from "foundation/_colors_export.scss?variables"

// Render a header consisting of the relevant service icon and the applet's
// name and description.
export default function AppletHeader({
  description,
  iconUrl,
  name,
  enabled,
  onChange,
}) {
  return (
    <div className="sdk-applet-header">
      <div className="sdk-applet-header-top">
        <img
          className="sdk-applet-header-icon"
          src={iconUrl}
          alt={name}
          title={name}
        />
        {enabled !== null && (
          <Switch
            circleColor={colors.tertiaryTextColor}
            circleColorEnabled={colors.successColor}
            backgroundColorEnabled={colors.primaryBackgroundColor}
            textColor={"white"}
            labels={{ on: "On", off: "Off" }}
            onChange={onChange}
            value={enabled}
          />
        )}
      </div>
      <h1>{name}</h1>
      <p>{description}</p>
    </div>
  )
}

AppletHeader.propTypes = {
  description: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  enabled: PropTypes.any,
  onChange: PropTypes.func,
}

AppletHeader.defaultProps = {
  enabled: null,
}
