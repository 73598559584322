import { useState } from "react"
import { assignWithBustedCache } from "shared/lib/utils"
import classNames from "classnames"
import { post } from "shared/lib/api"

import "./exclusive_offer.scss"

interface UserSubscription {
  hasDiscount: boolean
  paymentInterval: "month" | "year"
  tier: string
}

interface Props {
  asset: string
  claimOfferUrl: string
  exploreUrl: string
  handleCancellation: func
  trackMutationError: func
  userSubscription: UserSubscription
}

const ExclusiveOffer = ({
  asset,
  claimOfferUrl,
  exploreUrl,
  handleCancellation,
  trackMutationError,
  userSubscription,
}: Props) => {
  const [loading, updateLoading] = useState(false)
  const errorMessage = "There was a problem claiming your offer. Please contact support for more information."

  let warningCopy
  if (userSubscription.hasDiscount) {
    warningCopy = "This will replace any existing promotions on your account."
  }

  const handleOffer = () => {
    window.App.Utils?.logCustomDatadogAction?.("click_exclusive_offer")
    updateLoading(true)
    post(claimOfferUrl)
      .then(data => {
        if (data.ok) {
          window.appendFlash("You got 20% off for your next payment!", "success", () => {
            assignWithBustedCache(exploreUrl)
          })
        } else {
          trackMutationError("userSubscriptionPromoCodeUpdate", data.error, errorMessage, updateLoading)
        }
      })
      .catch(error => {
        trackMutationError("userSubscriptionPromoCodeUpdate", error, errorMessage, updateLoading)
      })
  }

  const handleCancel = () => {
    window.App.Utils?.logCustomDatadogAction?.("click_cancel_subscription")
    handleCancellation(updateLoading)
  }

  return (
    <div>
      <h1 className="mt4 mb4 mb2--phone mb2--small-phone">Save 20%</h1>

      <p className={classNames("mt0", { mb4: !warningCopy })}>
        {"Get 20% off your next payment and continue to use" +
          (userSubscription.tier === "pro"
            ? " filter code, queries including our AI services, multiple actions, Webhooks, Twitter, and more."
            : " multiple actions, exclusive services, and more.")}
      </p>
      {warningCopy && (
        <p className="mt0 mb4" styleName="exlusive-offer-warning">
          {warningCopy}
        </p>
      )}

      <img src={asset} width="288" height="256" />

      <div className="cancellation-actions mt4 mt8--phone mt8--small-phone">
        <button className="button-primary" onClick={handleOffer} disabled={loading}>
          Get 20% off
        </button>
        <a className={`link-standalone mt4 ${loading && "disabled"}`} onClick={handleCancel}>
          No thanks, just cancel my subscription
        </a>
      </div>
    </div>
  )
}

export default ExclusiveOffer
