import PropTypes from "prop-types"
import formatStoredFields from "shared/lib/format_stored_field"

// This is so convoluted. It really should just use the applet_type
// structure without any data changes.
export function buildFilterContextDataForDIY({
  selectedTrigger,
  selectedQueries,
  selectedActions,
}) {
  const selectedTriggers = selectedTrigger ? [selectedTrigger] : []
  const applet = {
    applet_trigger: buildStubAppletStep("trigger", selectedTrigger),
    applet_queries: selectedQueries.map(s => buildStubAppletStep("query", s)),
    applet_actions: selectedActions.map(s => buildStubAppletStep("action", s)),
  }

  return buildFilterContextData({
    applet,
    triggers: stepsByService("trigger", selectedTriggers),
    queries: stepsByService("query", selectedQueries),
    actions: stepsByService("action", selectedActions),
    services: serviceByModuleName(
      selectedTriggers.concat(selectedQueries).concat(selectedActions)
    ),
  })
}

const stepFieldsToArray = step =>
  Object.keys(step.fields).map(k => step.fields[k])

function buildStubAppletStep(type, step) {
  return (
    step && {
      channel_module_name: step.service.module_name,
      module_name: step.module_name,
      full_module_name: step.full_module_name,
      fields: stepFieldsToArray(step),
    }
  )
}

function stepsByService(type, steps) {
  return {
    byService: steps.reduce((services, step) => {
      services[step.service.module_name] =
        services[step.service.module_name] || {}

      services[step.service.module_name][step.module_name] = {
        ...step,
        fields: stepFieldsToArray(step).map(field => ({
          ...field,
          stored_field: formatStoredFields(type, step.full_module_name, field),
        })),
      }
      return services
    }, {}),
  }
}

function serviceByModuleName(steps) {
  return {
    byModuleName: steps.reduce((services, step) => {
      services[step.service.module_name] = step.service
      return services
    }, {}),
  }
}

/**
 * Given various store data, build the data expected by the TypeScript template
 * for filter/transform code. See Ifttt::Applets::FilterCodeCompiler in IFE for
 * more details.
 */
export default function buildFilterContextData({
  actions,
  applet,
  queries,
  services,
  triggers,
}) {
  const { applet_trigger, applet_actions, applet_queries } = applet
  const appletTriggers = filterNullAppletSteps([applet_trigger])
  const appletQueries = filterNullAppletSteps(applet_queries)
  const appletActions = filterNullAppletSteps(applet_actions)
  const appletSteps = appletTriggers.concat(appletActions).concat(appletQueries)

  const serviceData = createServiceData(appletSteps)
  const collisionTracker = new CollisionTracker(appletSteps)
  let triggerData

  // The ordering needs to match IFE's filter code compiler
  buildDataForSteps({
    collisionTracker,
    appletSteps: appletTriggers,
    steps: triggers,
    hasIngredients: true,
  }).forEach(([serviceModuleName, data]) => {
    triggerData = data
    serviceData[serviceModuleName].triggers.push(data)
  })

  buildDataForSteps({
    collisionTracker,
    appletSteps: appletActions,
    steps: actions,
    canSetFields: true,
  }).forEach(([serviceModuleName, data]) =>
    serviceData[serviceModuleName].actions.push(data)
  )

  buildDataForSteps({
    collisionTracker,
    appletSteps: appletQueries,
    steps: queries,
    hasIngredients: true,
  }).forEach(([serviceModuleName, data]) =>
    serviceData[serviceModuleName].queries.push(data)
  )

  return {
    trigger: triggerData,
    services: Object.keys(serviceData).map(serviceModuleName => {
      const service = services.byModuleName[serviceModuleName]

      return {
        label: service.name,
        name: service.normalized_module_name,
        actions: serviceData[serviceModuleName].actions,
        queries: serviceData[serviceModuleName].queries,
        trigger: serviceData[serviceModuleName].triggers[0] || null,
      }
    }),
  }
}

const stepsByServiceShape = PropTypes.shape({
  byService: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        normalized_module_name: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            normalized_module_name: PropTypes.string.isRequired,
            stored_field: PropTypes.shape({
              resolve_options: PropTypes.string.isRequired,
            }),
          })
        ),
        ingredients: PropTypes.arrayOf({
          label: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          example: PropTypes.string.isRequired,
          value_type: PropTypes.string.isRequired,
        }),
      })
    )
  ).isRequired,
})

const appletShape = PropTypes.shape({
  channel_module_name: PropTypes.string.isRequired,
  module_name: PropTypes.string.isRequired,
  full_module_name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      hidden: PropTypes.bool.isRequired,
    })
  ),
})

buildFilterContextData.propTypes = {
  triggers: stepsByServiceShape.isRequired,
  actions: stepsByServiceShape.isRequired,
  queries: stepsByServiceShape.isRequired,
  services: PropTypes.shape({
    byModuleName: PropTypes.objectOf({
      name: PropTypes.string.isRequired,
      normalized_module_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  applet: PropTypes.shape({
    applet_trigger: appletShape,
    applet_queries: PropTypes.arrayOf(appletShape),
    applet_actions: PropTypes.arrayOf(appletShape),
  }).isRequired,
}

function filterNullAppletSteps(appletSteps) {
  return appletSteps
    .filter(Boolean)
    .filter(
      appletStep =>
        appletStep.channel_module_name &&
        appletStep.module_name &&
        appletStep.full_module_name
    )
}

function createServiceData(appletSteps) {
  return appletSteps.reduce((services, appletStep) => {
    services[appletStep.channel_module_name] = {
      triggers: [],
      queries: [],
      actions: [],
    }
    return services
  }, {})
}

function buildDataForSteps({
  collisionTracker,
  appletSteps,
  steps,
  hasIngredients = false,
  canSetFields = false,
}) {
  return appletSteps.map(appletStep => {
    const serviceModuleName = appletStep.channel_module_name
    const moduleName = appletStep.module_name
    const step = steps.byService[serviceModuleName][moduleName]
    const stepData = {
      label: step.name,
      name: collisionTracker.nonCollidingName(step, appletStep),
    }

    if (canSetFields) {
      stepData["fields"] = buildDataForFields(step.fields)
    }

    if (hasIngredients) stepData["ingredients"] = buildDataForIngredients(step)

    return [serviceModuleName, stepData]
  })
}

function buildDataForIngredients(ingredientable) {
  return ingredientable.ingredients
    .filter(ingredient => ingredient.value_type !== "query")
    .map(ingredient => ({
      label: ingredient.label || ingredient.name,
      name: ingredient.name,
      example: ingredient.example,
    }))
}

function buildDataForFields(stepFields) {
  return stepFields
    .filter(field => field.name !== "_live_channel_id")
    .map(field => ({
      label: field.label,
      name: field.normalized_module_name,
      ...(field.stored_field.resolve_options && {
        resolveUrl: window.App.storedFieldPath(field.stored_field.resolve_options),
      }),
    }))
}

/**
 * Utility for tracking collisions among trigger and action names and returning
 * disambiguated versions. This needs to always stay consistent with the
 * behavior of IFE's Ifttt::Applets::AppletMetadataTransformer.
 */
class CollisionTracker {
  constructor(appletSteps) {
    const seenSoFar = new Set()
    this.collisions = appletSteps
      .map(appletStep => appletStep.full_module_name)
      .reduce((collisions, fullModuleName) => {
        if (seenSoFar.has(fullModuleName)) {
          collisions[fullModuleName] = 0
        } else {
          seenSoFar.add(fullModuleName)
        }
        return collisions
      }, {})
  }

  nonCollidingName(step, appletStep) {
    if (appletStep.full_module_name in this.collisions) {
      const id = ++this.collisions[appletStep.full_module_name]
      return `${step.normalized_module_name}${id}`
    } else {
      return step.normalized_module_name
    }
  }
}
