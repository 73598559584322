import strftime from "strftime"
import { get } from "shared/lib/api"
import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"

export default function setupActivityFeed() {
  localizeDates()
  setupPagination()
}

function setupPagination() {
  const moreButton = document.querySelector("#more")
  let limit, moreUrl

  moreButton &&
    moreButton.addEventListener("click", event => {
      event.preventDefault()
      moreUrl = new URL(moreButton.href)
      moreButton.blur()
      moreButton.innerHTML = "Loading…"
      moreButton.style.pointerEvents = "none"

      const feedItems = document.querySelector(".feed .items")

      limit = +moreUrl.searchParams.get("limit") || 0

      get(moreButton.href)
        .then(data => {
          // should return DOMString with <li>s
          if (data.trim().startsWith("<div>")) {
            moreButton.remove()
            return
          }

          const itemsBefore = document.querySelectorAll(".feed .items li").length
          feedItems.insertAdjacentHTML("beforeend", data)
          const itemsAfter = document.querySelectorAll(".feed .items li").length

          const items = itemsAfter - itemsBefore
          if (items < limit) {
            moreButton.remove()
            return
          }

          const lastItemTimestamp = getLastItemTimestamp()
          moreUrl.searchParams.set("before_ts", lastItemTimestamp)
          moreButton.href = moreUrl
          moreButton.innerHTML = "More"
          moreButton.style.pointerEvents = "auto"

          localizeDates()

          // After get activities via Load, it's necessary to initialize component manually.
          // TODO: Create a index to load async and manage load more directly (without render components).
          ReactRailsUJS.mountComponents()
        })
        .catch(
          rejectedXHRPromiseLogger(() => {
            moreButton.remove()
          })
        )
    })
}

function getLastItemTimestamp() {
  const items = document.querySelectorAll(".feed .items li")
  const item = items[items.length - 1]
  return item.dataset["timestamp"]
}

function localizeDates() {
  document.querySelectorAll(".item-time").forEach(item => {
    const time = item.dataset["time"]
    const format = item.dataset["format"]

    const date = new Date(time * 1000)
    item.innerHTML = strftime(format, date)
  })
}
