import PropTypes from "prop-types"

import SearchInput from "./../shared/search_input"

const MyApplets = ({ onSearch, value }) => {
  return (
    <div className="ifttt-next-search">
      <SearchInput
        autoComplete="off"
        autoFocus={false}
        inputID="service-search"
        maxLength="1024"
        onChange={e => onSearch(e.target.value)}
        onXClick={() => onSearch("")}
        placeholder="Filter"
        value={value}
      />
    </div>
  )
}

MyApplets.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default MyApplets
