import StripeJsContainer from "../stripe_js_container"
import CreditCardForm from "./credit_card_form"

export default function UpdatePaymentInfo(props) {
  return (
    <StripeJsContainer>
      <CreditCardForm {...props} />
    </StripeJsContainer>
  )
}

UpdatePaymentInfo.propTypes = {
  ...CreditCardForm.propTypes,
}
