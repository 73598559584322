import PropTypes from "prop-types"

import CompleteIcon from "shared/components/icons/complete_icon"

/**
 * This component renders an individual service's name in the "works with"
 * header. It's pretty awkward because we want to let the name break in the
 * middle but avoid orphaning the icons at the beginning and end.
 */
export default function WorksWithService({
  name,
  color,
  iconUrl,
  connected,
  justConnected,
}) {
  const words = name.split(" ")

  const serviceIcon = (
    <img
      className="sdk-works-with-service-icon"
      src={iconUrl}
      style={{ backgroundColor: color }}
      title={name}
      alt={name}
    />
  )

  const completeIcon = connected && <CompleteIcon animate={justConnected} />

  let content

  if (words.length === 1) {
    content = (
      <span className="sdk-works-with-service-nowrap">
        {serviceIcon}
        {name}
        {completeIcon}
      </span>
    )
  } else {
    content = (
      <span>
        <span className="sdk-works-with-service-nowrap">
          {serviceIcon}
          {words[0]}
        </span>{" "}
        {words.slice(1, words.length - 1).join(" ")}{" "}
        <span className="sdk-works-with-service-nowrap">
          {words[words.length - 1]}
          {completeIcon}
        </span>
      </span>
    )
  }

  return (
    <div className="sdk-works-with-service" style={{ color }}>
      {content}
    </div>
  )
}

WorksWithService.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  connected: PropTypes.bool.isRequired,
  iconUrl: PropTypes.string.isRequired,
}

WorksWithService.defaultProps = {
  justConnected: false,
}
