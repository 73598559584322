import { useState } from "react"
import classNames from "classnames"
import parse from "html-react-parser"

import ServiceList from "./service_list.tsx"

import "./ingredient_listbox.scss"

interface Props {
  apiList: ApiList
  name: string
  serviceList: Array<Object>
}

interface ApiList {
  options: Array<string>
  placeholder: string
}

import closedChevron from "images/layout/chevron-right-black.svg"
import openedChevron from "images/layout/chevron-down.svg"

const IngredientListbox = (props: Props) => {
  if (!props.apiList?.options) return null

  const [listOpened, updateListVisibility] = useState(false)

  const listStyle = classNames("ingredient-combobox", {
    opened: listOpened,
  })

  const copyContent = e => {
    const target = e.target.classList.contains("copy-content") ? e.target : e.target.parentNode

    navigator.clipboard.writeText(target.title)
    target.classList.add("copied")
    target.querySelector("input").select()

    setTimeout(() => {
      target.classList.remove("copied")
    }, 1500)
  }

  const renderList = (serviceName: string, options: Array<string>) => (
    <>
      <li styleName="service">
        {serviceName}
      </li>
      <li styleName="service-options">
        <ul>
          {options.map(option => (
            <li
              key={`${serviceName}.${option}`}
              title={`${serviceName}.${option}`}
              onClick={copyContent}
              className="copy-content"
            >
              <input
                type="text"
                readOnly="readonly"
                value={`.${option}`}
              />
            </li>
          ))}
        </ul>
      </li>
    </>
  )

  return (
    <section styleName="ingredient-combo-container" className="mb4">
      <header>
        <span styleName="header-name">{props.name}</span>
        {props.serviceList?.length && (
          <span styleName="service-list">
            <ServiceList context={props.name} services={props.serviceList}/>
          </span>
        )}
      </header>

      <ul role="listbox" styleName={listStyle} className="mt1">
        <li
          styleName="placeholder"
          onClick={() => updateListVisibility(!listOpened)}
          title={listOpened ? "Click to close" : "Click to open"}
        >
          <span>{props.apiList.placeholder}</span>
          <span>{parse(listOpened ? openedChevron : closedChevron)}</span>
        </li>
        {listOpened &&
          Object.entries(props.apiList.options)
            .map(([serviceName, options]) => renderList(serviceName, options))
        }
      </ul>
    </section>
  )
}

export default IngredientListbox
