import { useEffect, useState } from "react"

import Steps from "./steps"
import PlatformSelection from "./platform_selection"
import GoalSelection from "./goal_selection"
import ServiceSelection from "./service_selection"
import Upsell from "./upsell"
import { Service } from "types"
import { TrialConfigAssets, TrialConfigUrls } from "./upsell"
import { submitPreferredPlatforms } from "./api"
import { assignWithBustedCache } from "shared/lib/utils"

interface Props {
  browserPlatform: string
  connectedServiceObj: Service
  connectingToAssets: {
    desktop: string
    responsive: string
  }
  pageId: string | null
  paidUser: boolean
  previouslySelectedPlatforms: string | null
  progress?: string
  trialConfig: {
    assets: TrialConfigAssets
    urls: TrialConfigUrls
  }
  urls: {
    exploreUrl: string
    bulkChannelBaseUrl: string
    returnToUrl: string
    clearExploreCacheUrl: string
  }
  userIsOnboarding: boolean
  userIsStudent: boolean
  userGoal: string
}

const getSteps = (step: string, progress?: number): Array<string> => {
  const possibleSteps = ["platforms", "goals", "services"]

  if (progress && progress === 3) {
    return possibleSteps
  } else if (progress && progress === 2) {
    return possibleSteps.slice(1)
  }

  switch (step) {
    case "goals":
      return possibleSteps.slice(1)
    case "services":
      return possibleSteps.slice(2)
    default:
      return possibleSteps
  }
}

const NewUserIntake = ({
  browserPlatform,
  connectingToAssets,
  pageId,
  paidUser,
  previouslySelectedPlatforms,
  progress,
  trialConfig,
  urls,
  userIsOnboarding,
  userIsStudent,
  userGoal,
}: Props) => {
  const pathName = userIsOnboarding ? "/onboarding" : "/applet_discovery"
  const STEPS = Object.values(Steps)
  if (userIsStudent) {
    Steps.UPSELL = "student_discount"
  }
  const [step, setStep] = useState<string>(
    browserPlatform === "desktop" ? Steps.PLATFORM_SELECTION : Steps.GOAL_SELECTION
  )
  const [stepHistory, setStepHistory] = useState<Array<string>>([])
  const [progressBarSteps, setProgressBarSteps] = useState<Array<string>>([])
  const [selectedGoal, setSelectedGoal] = useState<string>(userGoal)

  const browserToMobilePlatformMapping: { [key: string]: string } = {
    ios: "iphone",
    android: "android",
  }

  useEffect(() => {
    const userMobilePlatform = browserToMobilePlatformMapping[browserPlatform]

    if (userIsOnboarding && userMobilePlatform) {
      submitPreferredPlatforms([userMobilePlatform])
    }
  }, [browserPlatform, userIsOnboarding])

  useEffect(() => {
    const validStep = pageId ? STEPS.includes(pageId) : false

    if (validStep && pageId) {
      setStep(pageId)
    } else {
      const url = pathName + `/${step}`
      window.history.replaceState({}, "", url)
    }
  }, [])

  useEffect(() => {
    setProgressBarSteps(getSteps(step, Number(progress)))
  }, [])

  const handleBrowserBackButton = () => {
    const step = window.location.pathname.split("/")[2]

    if (step && STEPS.includes(step)) {
      setStep(step)
    }
  }

  useEffect(() => {
    window.addEventListener("popstate", handleBrowserBackButton)
    return () => window.removeEventListener("popstate", handleBrowserBackButton)
  }, [])

  const handleInAppBackButton = (): void => {
    window.history.back()
  }

  const navigateToNextStep = (currentStep: string, nextStep: string) => {
    setStep(nextStep)

    const url = pathName + `/${nextStep}`
    window.history.pushState({}, "", url)

    if (!stepHistory.includes(currentStep)) {
      setStepHistory([...stepHistory, currentStep])
    }
  }

  const onPlatformSelected = (): void => {
    navigateToNextStep(Steps.PLATFORM_SELECTION, Steps.GOAL_SELECTION)
  }

  const onGoalSelected = (goal: string) => {
    setSelectedGoal(goal)

    let nextStep = Steps.SERVICE_SELECTION
    if (userIsOnboarding && !paidUser) {
      nextStep = Steps.UPSELL
    } else if (goal === "something_else") {
      assignWithBustedCache(urls.exploreUrl)
    }

    navigateToNextStep(Steps.GOAL_SELECTION, nextStep)
  }

  const onServiceSelected = (service: Service): void => {
    if (service.connected) assignWithBustedCache(urls.exploreUrl)
  }

  const onSkipUpsell = () => {
    if (selectedGoal === "something_else") {
      assignWithBustedCache(urls.exploreUrl)
    } else {
      navigateToNextStep(Steps.UPSELL, Steps.SERVICE_SELECTION)
    }
  }

  const onSkipServiceSelection = () => {
    assignWithBustedCache(urls.exploreUrl)
  }

  switch (step) {
    case Steps.PLATFORM_SELECTION:
      return (
        <PlatformSelection
          browserPlatform={browserPlatform}
          onClickBack={handleInAppBackButton}
          onPlatformSelected={onPlatformSelected}
          previouslySelectedPlatforms={previouslySelectedPlatforms}
          progressBarSteps={progressBarSteps}
          userIsOnboarding={userIsOnboarding}
        />
      )
    case Steps.GOAL_SELECTION:
      return (
        <GoalSelection
          browserPlatform={browserPlatform}
          onClickBack={handleInAppBackButton}
          onGoalSelected={onGoalSelected}
          progressBarSteps={progressBarSteps}
          userIsOnboarding={userIsOnboarding}
          urls={urls}
        />
      )
    case Steps.UPSELL:
      return (
        <Upsell
          goal={selectedGoal}
          onClickBack={handleInAppBackButton}
          onSkip={onSkipUpsell}
          trialConfig={trialConfig}
          userIsStudent={userIsStudent}
        />
      )
    case Steps.SERVICE_SELECTION:
      return (
        <ServiceSelection
          connectingToAssets={connectingToAssets}
          onClickBack={handleInAppBackButton}
          onServiceSelected={onServiceSelected}
          onSkip={onSkipServiceSelection}
          progressBarSteps={progressBarSteps}
          urls={urls}
          userIsOnboarding={userIsOnboarding}
        />
      )
    default:
      return null
  }
}

export default NewUserIntake
