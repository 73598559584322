import { Component } from "react"
import PropTypes from "prop-types"

import { combineReducers, createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import reducers from "./reducers"

import GetMoreLandingPage from "./get_more_landing_page"

export default class GetMore extends Component {
  constructor(props) {
    super(props)
    const combinedReducers = combineReducers({
      getMoreResults: reducers.getMoreResults.reducer,
      searchResults: reducers.searchResults.reducer,
      serviceResults: reducers.serviceResults.reducer,
      viewToggle: reducers.viewToggle.reducer,
    })

    const landingEmptySearch =
      props.currentView === "search" && props.searchApplets.length === 0 && props.searchServices.length === 0

    this.store = createStore(
      combinedReducers,
      {
        viewToggle: {
          ...reducers.viewToggle.initialState,
          currentView: props.currentView,
          origin: props.origin,
        },
        getMoreResults: {
          ...reducers.getMoreResults.initialState,
          applets: props.getMoreApplets,
          services: props.getMoreServices,
          stories: props.stories,
          diyPath: props.diyPath,
          belowFoldPath: props.belowFoldPath,
          offset: 0,
          limit: 20,
        },
        serviceResults: {
          ...reducers.serviceResults.initialState,
          allServices: props.allServices,
          allServicesCategories: props.allServicesCategories,
          loadAllServicesPath: props.loadAllServicesPath,
          serviceCategoryFilter: props.selectedServiceCategory,
        },
        searchResults: {
          ...reducers.searchResults.initialState,
          searchTerm: props.searchTerm,
          signedIn: props.signedIn,
          applets: props.searchApplets,
          services: props.searchServices,
          recommendedServices: props.recommendedServices,
          hideSuggestions: !landingEmptySearch,
          shouldLoadMoreApplets: true,
        },
      },
      applyMiddleware(thunk)
    )

    window.App.Utils?.logCustomDatadogAction?.("explore_visit")
  }

  render() {
    return (
      <Provider store={this.store}>
        <GetMoreLandingPage />
      </Provider>
    )
  }
}

GetMore.propTypes = {
  currentView: PropTypes.oneOf(["discover_all", "discover_applets", "discover_services", "discover_stories", "search"]),
  searchTerm: PropTypes.string,
  signedIn: PropTypes.bool,
  getMoreApplets: PropTypes.array,
  getMoreServices: PropTypes.array,
  stories: PropTypes.array,
  allServices: PropTypes.array,
  allServicesCategories: PropTypes.array,
  searchApplets: PropTypes.array,
  searchServices: PropTypes.array,
  recommendedServices: PropTypes.array,
  getMoreToken: PropTypes.string,
  diyPath: PropTypes.string,
  belowFoldPath: PropTypes.string.isRequired,
  loadAllServicesPath: PropTypes.string.isRequired,
  origin: PropTypes.string,
  selectedServiceCategory: PropTypes.string,
}

GetMore.defaultProps = {
  currentView: "discover_all",
  getMoreApplets: [],
  getMoreServices: [],
  allServices: [],
  allServicesCategories: [],
  stories: [],
  origin: "",
  selectedServiceCategory: "all",
}
