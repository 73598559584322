interface Props {
  expirationDate: string
  exploreUrl: string
  maxFreeApplets: string
  tierName: string
}

const CancelConfirmation = ({ expirationDate, exploreUrl, maxFreeApplets, tierName }: Props) => {
  return (
    <div>
      <h1 className="mt4 mb4 mb2--phone mb2--small-phone">Plan cancelled</h1>

      <p>Your IFTTT {tierName} subscription will end on {expirationDate}.<br/>Your account will be limited to {maxFreeApplets} Applets and basic features.</p>

      <div className="cancellation-actions mt16 mt20--phone mt20--small-phone">
        <a className="button-primary" href={exploreUrl}>Return to home</a>
      </div>
    </div>
  )
}

export default CancelConfirmation
