import { Fragment, useState } from "react"
import DIYTandaSelected from "./diy_tanda_selected"
import { OpenAIResult, OpenAIStepResult, User } from "./utils/interfaces"
import { captureException } from "@sentry/react"
import parse from "html-react-parser"

import proBadge from "images/plan-badges/pro.svg"
import proPlusBadge from "images/plan-badges/pro_plus.svg"
import Qs from "qs"

import { createAIMakerApplet } from "./api"

import "./initial_step.scss"

interface tandaSelectedProps {
  label: "if" | "with" | "then" | "and"
  separator: boolean
  showProFeatures: boolean
  step: OpenAIStepResult
}

const tandaSelected = ({ label, separator, showProFeatures, step }: tandaSelectedProps) => {
  return (
    <Fragment key={step.full_module_name}>
      {separator && <div styleName="separator" />}
      <DIYTandaSelected
        readOnly={true}
        label={label}
        stepName={step.name}
        service={{
          id: "",
          image: step.channel.monochrome_image_url,
          name: step.full_module_name,
          brand_color: `#${step.channel.brand_color}`,
          allow_multiple_live_channels: false,
          live_channels: [],
        }}
        showProFeatures={showProFeatures}
      />
    </Fragment>
  )
}
interface Props {
  activationPath: string
  appletName: string
  previewState: OpenAIResult
  showProFeatures: boolean
  showUpgradeModal: (step: string, tier: string) => void
  tryAgainHandler: () => void
  user: User
  userAtQuota: boolean
}

const AutomateWithAIPreview = ({
  activationPath,
  appletName,
  previewState,
  showProFeatures,
  tryAgainHandler,
  showUpgradeModal,
  user,
  userAtQuota,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { block_user, block_reason, minimum_tier } = previewState.enablement_rules || {}
  const needUpgrade: boolean = block_user && user.tier !== minimum_tier

  const onSubmit = () => {
    if (userAtQuota) {
      showUpgradeModal("applet_quota")
    } else if (needUpgrade) {
      showUpgradeModal(block_reason, minimum_tier)
    } else {
      setLoading(true)

      createAIMakerApplet({
        appletName,
        triggerModuleNames: previewState.selectedTrigger.full_module_name,
        queriesModuleName: previewState.selectedQueries.map(query => query.full_module_name),
        actionsModuleName: previewState.selectedActions.map(action => action.full_module_name),
      })
        .then(result => {
          const normalizedApplet = result.diyAppletAIMakerCreate.normalized_applet
          if (normalizedApplet.id) {
            const returnTo = `${window.location.origin}/applets/${normalizedApplet.id}/edit`

            const servicesToConnect = normalizedApplet.permissions.filter(permission => !permission.channel.connected)

            if (servicesToConnect.length == 0) {
              localStorage.setItem("ai_applet_being_created", normalizedApplet.id)
              window.location.assign(returnTo)
            } else {
              // IFE takes service_slugs as ?c[]=hue&c[]=alexa
              const slugsToParams = servicesToConnect.reduce(
                (ac, conn) => {
                  ac.c = [...ac.c, conn.channel.module_name]
                  return ac
                },
                { c: [] }
              )

              slugsToParams.return_to = returnTo

              window.location.assign(
                `${activationPath}?${Qs.stringify(slugsToParams, {
                  arrayFormat: "brackets",
                })}`
              )
            }
          } else {
            setLoading(false)
            window.appendFlash("Error creating your Applet", "danger")
          }
        })
        .catch(err => {
          setLoading(false)
          captureException(err)
          window.appendFlash("Error creating your Applet", "danger")
        })
    }
  }

  return (
    <>
      <section styleName="diy-block">
        {tandaSelected({ label: "if", separator: false, showProFeatures, step: previewState?.selectedTrigger })}
        {previewState?.selectedQueries.map(query =>
          tandaSelected({ label: "with", separator: true, showProFeatures, step: query })
        )}
        {previewState?.selectedActions.map((action, index) =>
          tandaSelected({ label: index == 0 ? "then" : "and", separator: true, showProFeatures, step: action })
        )}
      </section>
      <div styleName="footer">
        <section className="action-buttons-container">
          <button className="button-secondary" onClick={onSubmit} disabled={loading}>
            {needUpgrade ? (
              <span styleName="upgrade-copy">
                <span>Upgrade to</span>
                <span styleName="badge">{parse(minimum_tier == "pro" ? proPlusBadge : proBadge)}</span>
              </span>
            ) : (
              "Continue"
            )}
          </button>
          <button styleName="retry" className="button-tertiary button-cancel-link" onClick={tryAgainHandler}>
            Try again
          </button>
        </section>
      </div>
    </>
  )
}

export default AutomateWithAIPreview
