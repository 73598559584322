import { Component, cloneElement } from "react"
import PropTypes from "prop-types"

import AuthPageContent from "./auth_page_content"
import FooterButton from "./footer_button"
import Form from "./form"
import FormField from "./form_field"
import FooterLink from "./footer_link"
import SdkLayout from "./sdk_layout"
import WorksWith from "./works_with"
import { post } from "shared/lib/api"
import { facebookSSO } from "shared/sso"

import FacebookOnColorIcon from "shared/components/icons/facebook_on_color_icon.js"

import "./sign_in_page.scss"

export default class SignInPage extends Component {
  state = {
    submitting: false,
    email: this.props.suggestedEmail || "",
    password: "",
    tfaCode: null,
    errors: {},
    signedIn: false,
    useSSO: false,
    tfaUrl: null,
    ssoSource: "",

    // These keys are both props and state because we have to update them when
    // the user has signed in. Normally this is an antipattern but in this case
    // it doesn't really matter since the props are embedded in the page and
    // can't be updated anyway.
    nextStepColor: this.props.nextStepColor,
    nextStepLabel: this.props.nextStepLabel,
    nextStepUrl: this.props.nextStepUrl,
    services: this.props.services,
  }

  handleChangeEmail = e => this.setState({ email: e.target.value })
  handleChangePassword = e => this.setState({ password: e.target.value })
  handleChangeTfaCode = e => this.setState({ tfaCode: e.target.value })

  handleFacebookSSO = e => {
    e.preventDefault()
    this.setState({ ssoSource: "facebook" })
    const { fbReauth } = this.props
    facebookSSO(
      this.props.facebookSSOPath,
      { sdk: 1 },
      {
        fbReauth,
      }
    )
      .then(this.onSignInSuccess)
      .catch(this.onSignInFailure)
  }

  onSignInSuccess = ({ email, flow }) => {
    this.setState({ signedIn: true, email, ...flow, submitting: false })
  }

  onSignInFailure = response => {
    let displayFlash = true

    if (response && response.error) {
      const { error, tfa_url, email } = response

      let errorState = {}

      switch (error.code) {
        case "bad_tfa":
          if (this.state.tfaCode) {
            errorState = { errors: { tfaCode: [error.message] } }
          } else if (tfa_url) {
            errorState = { tfaCode: "", errors: {}, tfaUrl: tfa_url }
            displayFlash = false
          } else {
            errorState = { tfaCode: "", errors: {} }
            displayFlash = false
          }
          break
        case "bad_password":
          errorState = { errors: { password: [error.message] } }
          break
        case "unlinked_account":
          errorState = {
            useSSO: false,
            email,
            errors: { password: [error.message] },
          }
          break
        case "no_account_found":
        case "provider_error":
        case "wrong_account":
        default:
          errorState = {
            tfaCode: null,
            useSSO: false,
            errors: { password: [error.message] },
          }
      }

      this.setState({ ...errorState, submitting: false })
    }

    if (displayFlash)
      window.appendFlash("There was an error signing in", "danger")
  }

  handleSubmit = e => {
    e.preventDefault()

    const { apiSignInUrl, requiredUserLogin } = this.props
    const { submitting, email, password, tfaCode, useSSO, tfaUrl } = this.state

    let submitUrl = apiSignInUrl

    if (submitting) {
      return
    } else {
      this.setState({ submitting: true })
    }

    if (useSSO && tfaUrl) {
      submitUrl = tfaUrl
    }

    post(submitUrl, {
      username: requiredUserLogin || email,
      password,
      tfa_code: tfaCode,
    })
      .then(this.onSignInSuccess)
      .catch(this.onSignInFailure)
  }

  renderLayout(footer, content) {
    return (
      <SdkLayout footer={footer && cloneElement(footer, { fadeIn: true })}>
        <WorksWith
          services={this.state.services}
          loggedIn={this.state.signedIn}
          justLoggedIn={this.state.signedIn}
        />
        <AuthPageContent>{content}</AuthPageContent>
      </SdkLayout>
    )
  }

  renderSignIn() {
    const { resetUrl, requiredUserLogin } = this.props
    const { submitting, email, password, errors } = this.state

    const signInEnabled = (requiredUserLogin || email.trim()) && password.trim()
    const disabled = submitting || !signInEnabled

    return this.renderLayout(
      <FooterButton
        label="Log in to IFTTT"
        disabled={disabled}
        onClick={this.handleSubmit}
      />,
      <>
        {requiredUserLogin ? (
          <p>
            Enter the password for <b>{requiredUserLogin}</b> on IFTTT to
            continue.
          </p>
        ) : (
          <p>
            Enter your <b>IFTTT account</b> email and password to continue.
          </p>
        )}

        <Form onSubmit={this.handleSubmit}>
          {!requiredUserLogin && (
            <FormField
              name="email"
              type="email"
              label="Email"
              onChange={this.handleChangeEmail}
              value={email}
              errors={errors.email}
            />
          )}

          <FormField
            name="password"
            type="password"
            label="Password"
            onChange={this.handleChangePassword}
            value={password}
            errors={errors.password}
          />

          <p className="note">
            Forget your password?&nbsp;
            <a className="link-inline" href={resetUrl}>
              Reset it
            </a>
          </p>

          <p className="note">
            <a
              href="#"
              className="link-standalone"
              onClick={e => {
                e.preventDefault()
                this.setState({ useSSO: true })
              }}
            >
              Continue with Facebook
            </a>
          </p>
        </Form>
      </>
    )
  }

  renderTfa() {
    const { tfaCode, errors } = this.state

    return this.renderLayout(
      <FooterButton
        label="Log in to IFTTT"
        disabled={!tfaCode.trim()}
        onClick={this.handleSubmit}
      />,
      <div>
        <p>Enter your two-step verification code to continue.</p>

        <Form onSubmit={this.handleSubmit}>
          <FormField
            name="tfa"
            type="tel"
            label="Two-step verification code"
            onChange={this.handleChangeTfaCode}
            value={tfaCode}
            noHelp={true}
            errors={errors.tfaCode}
          />
        </Form>
      </div>
    )
  }

  renderSignedIn() {
    const { requiredUserLogin } = this.props
    const { email, nextStepColor, nextStepLabel, nextStepUrl } = this.state

    return this.renderLayout(
      <FooterLink
        label={nextStepLabel}
        backgroundColor={nextStepColor}
        href={nextStepUrl}
      />,
      <p>
        You’re now signed into IFTTT as <b>{requiredUserLogin || email}</b>.
      </p>
    )
  }

  renderSSO() {
    return this.renderLayout(
      null,
      <div className="sdk-auth-page-content">
        <a
          href="#"
          onClick={this.handleFacebookSSO}
          styleName="sso-button sso-button-facebook"
        >
          <span styleName="sso-button-content">
            <FacebookOnColorIcon iconStyles="icon small margin-right-small" />
            Continue with Facebook
          </span>
        </a>
        <p className="note">
          <a
            href="#"
            className="link-standalone"
            onClick={e => {
              e.preventDefault()
              this.setState({ useSSO: false })
            }}
          >
            Or use your email and password to log in
          </a>
        </p>
      </div>
    )
  }

  render() {
    if (this.state.signedIn) {
      return this.renderSignedIn()
    } else if (this.state.tfaCode != null) {
      return this.renderTfa()
    } else if (this.state.useSSO) {
      return this.renderSSO()
    } else {
      return this.renderSignIn()
    }
  }
}

SignInPage.propTypes = {
  apiSignInUrl: PropTypes.string.isRequired,
  nextStepColor: PropTypes.string,
  nextStepLabel: PropTypes.string.isRequired,
  nextStepUrl: PropTypes.string.isRequired,
  requiredUserLogin: PropTypes.string,
  services: WorksWith.propTypes.services,
  suggestedEmail: PropTypes.string,
  resetUrl: PropTypes.string.isRequired,
  fbReauth: PropTypes.bool.isRequired,
  facebookSSOPath: PropTypes.string.isRequired,
}
