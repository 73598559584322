import { StepField, StoredFieldProps } from "app/components/diy_composer/utils/interfaces"
import { pluralizeStepType } from "./utils"

/**
 * Convert the GraphQL representation of a trigger or action field (per the
 * queries in the reducers) to be compatible with <StoredField>.
 * @param {string} stepType
 * @param {string} stepFullModuleName
 * @param {T} field
 * @return {object}
 */
export default function formatStoredField(
  stepType: string,
  stepFullModuleName: string,
  field: StepField
): StoredFieldProps {
  const owner = storedFieldOwner(stepType, stepFullModuleName)

  let resolve_options = ""
  if (field.normalized_field_type.indexOf("option") > -1) {
    resolve_options = `/stored_fields${owner}/${field.name}/options`
  }

  return {
    id: `${owner}@${field.name}`,
    type: "stored_field",
    name: field.name,
    label: field.label,
    owner,
    field_subtype: field.field_ui_type,
    required: field.required,
    resolve_options,
    shareable: field.shareable,
    hideable: field.hideable,
    can_have_default: field.can_have_default,
    helper_text: field.helper_text,
  }
}

export function storedFieldOwner(stepType, fullModuleName) {
  return `/${pluralizeStepType(stepType)}/${fullModuleName}`
}
