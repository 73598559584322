import { Component } from "react"
import PropTypes from "prop-types"

import classnames from "classnames"
import isEmpty from "lodash/isEmpty"
import { formatHexColorValue } from "shared/lib/alternate_brand_color"

import TransitionEvent from "app/scripts/transition_event"

import "./returning_user_flow.scss"

export default class EmailStatusDisplayer extends Component {
  state = { transitionStarted: false, transitionEnded: false }

  // PageViewed event is fired here because we need to track ConnectURL and ConnectSDK:
  //   - Connect SDK API (user just land directly here)
  //   - ConnectURL: when email is not provided and users need to fill in VerifyEmailAddress
  //   - ConnectURL: when user is provided and user lands directly here
  componentDidMount() {
    this.element = document.querySelector("#email-info")
    this.timeOut = setTimeout(
      () =>
        this.setState({ transitionStarted: true }, () => {
          this.element.addEventListener(
            TransitionEvent(),
            this.handleTransitionEnd
          )
        }),
      this.props.delayFadeInBy
    )
  }

  // in case user navigates away before the timeout kicks in
  componentWillUnmount() {
    clearTimeout(this.timeOut)
    this.element.removeEventListener(
      TransitionEvent(),
      this.handleTransitionEnd
    )
  }

  handleTransitionEnd = () => {
    const { emailAppScheme, userDevice } = this.props

    this.setState({ transitionEnded: true }, () => {
      this.element.removeEventListener(
        TransitionEvent(),
        this.handleTransitionEnd
      )
      // send the user to the email app if it's in the props
      if (!isEmpty(emailAppScheme)) {
        userDevice === "android" &&
          alert("Opening your email app to verify your connection.")

        location.assign(emailAppScheme)
      }
    })
  }

  sentEmailNotification = idStrategy => {
    const {
      localization: { verification_email_sent_to, verification_email_sent },
    } = this.props

    if (idStrategy.indexOf("@") !== -1) {
      return verification_email_sent_to.replace("%@", idStrategy)
    } else {
      return verification_email_sent
    }
  }

  render() {
    const { idStrategy, emailAppScheme, brandColor } = this.props
    const bgColor = {
      backgroundColor: formatHexColorValue(brandColor),
    }

    return (
      <div
        style={bgColor}
        styleName={classnames({
          "email-status-container": true,
          clickable: !isEmpty(emailAppScheme),
        })}
        onClick={this.handleClick}
      >
        <div styleName="email-status">
          <div className="paper-plane" />
          <div
            styleName={classnames({
              info: true,
              "fade-in": this.state.transitionStarted,
            })}
            id="email-info"
          >
            <span>{this.sentEmailNotification(idStrategy)}</span>
          </div>
        </div>
      </div>
    )
  }

  handleClick = event => {
    event.preventDefault()
    !isEmpty(this.props.emailAppScheme) &&
      location.assign(this.props.emailAppScheme)
  }
}

EmailStatusDisplayer.propTypes = {
  brandColor: PropTypes.string.isRequired,
  idStrategy: PropTypes.string.isRequired,
  delayFadeInBy: PropTypes.number,
  emailAppScheme: PropTypes.string,
  userDevice: PropTypes.string.isRequired,
  localization: PropTypes.shape({
    verification_email_sent: PropTypes.string.isRequired,
    verification_email_sent_to: PropTypes.string.isRequired,
  }).isRequired,
  connectionSlug: PropTypes.string.isRequired,
  sdkAnonymousId: PropTypes.string,
}

EmailStatusDisplayer.defaultProps = {
  delayFadeInBy: 1000,
}
