import { Component } from "react"
import PropTypes from "prop-types"

export default class Footer extends Component {
  handleClick = event => {
    event.preventDefault()
    this.props.onSaveClicked()
  }

  render() {
    return (
      <div className="config-footer">
        {this.props.includeSubmit && (
          <div className="button">
            <button
              className="button-primary"
              onClick={this.handleClick}
              disabled={!this.props.enabled}
            >
              Done
            </button>
          </div>
        )}
      </div>
    )
  }
}

Footer.propTypes = {
  applet: PropTypes.object,
  onSaveClicked: PropTypes.func,
  enabled: PropTypes.bool,
  includeSubmit: PropTypes.bool,
}
