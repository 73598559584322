import { Component } from "react"
import PropTypes from "prop-types"

import { connectionShape } from "app/lib/shared_prop_types"

import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"
import appletOrConnectionPath from "app/scripts/applet_or_connection_path"

export class AppletCard extends Component {
  render() {
    return (
      <li
        className="my-web-applet-card web-applet-card"
        ref={el => {
          this.scrollObserverEl = el
        }}
      >
        <SmallAppletCard appletPath={appletOrConnectionPath(this.props.applet)} applet={this.props.applet} />
      </li>
    )
  }
}

AppletCard.propTypes = {
  applet: connectionShape.isRequired,
  position: PropTypes.number.isRequired,
}

export default AppletCard
