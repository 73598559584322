import classnames from "classnames"
import { useBgColorContext } from "./context"

import LearnMoreButton from "shared/components/learn_more_button"
import ReturnButton from "./return_button"
import UpgradeBanner from "./upgrade_banner"
import { steps } from "./steps"
import colors from "foundation/_colors_export.scss?variables"
import { compareTier } from "./tiers"
import { Tier } from "./utils/interfaces"

import "./header.scss"

interface HeaderProps {
  aiPreviewEnablementRules: {
    block_user: boolean
    block_reason: string
    minimum_tier: string
  }
  editing: boolean
  backButtonTarget: () => void
  title: string
  updateLoading: (loading: boolean) => void
  user: {
    tier: Tier
  }
  userAtQuota: boolean
  step: number
  showingCheckoutModal: boolean
  showUpgradeModal: (step: string, tier: Tier | undefined) => void
  appletTier: Tier
}

export const Header = ({
  aiPreviewEnablementRules,
  editing,
  backButtonTarget,
  title,
  updateLoading,
  user,
  userAtQuota,
  step,
  showingCheckoutModal,
  showUpgradeModal,
  appletTier,
}: HeaderProps) => {
  const onReturn = () => {
    if (initialStep && editing) {
      window.history.back()
    } else if (initialStep) {
      updateLoading(true)
      window.location.assign("/my_applets")
    } else {
      backButtonTarget()
    }
  }

  const bgColor = useBgColorContext()
  const bgOnColor = bgColor !== "white"
  const color = bgOnColor ? "white" : colors.primaryTextColor
  const classes = classnames("diy-header", {
    "on-color": bgOnColor,
  })

  let bannerContent = null
  let bannerClickHandler = () => {}
  const initialStep = step === steps.INITIAL
  const shouldShowBanners = (initialStep || step === steps.PREVIEW_OPEN_AI_RESULT) && !showingCheckoutModal

  if (shouldShowBanners && userAtQuota && !editing) {
    bannerContent = "You have reached your Applet limit."
    bannerClickHandler = () => showUpgradeModal("applet_quota")
  } else if (shouldShowBanners && compareTier(appletTier, user.tier) > 0) {
    const aiPreviewBlockStep = aiPreviewEnablementRules?.block_user && aiPreviewEnablementRules.block_reason

    bannerContent = `This Applet uses features only available to ${appletTier === "pro" ? "Pro+" : "Pro"} users.`
    bannerClickHandler = () =>
      showUpgradeModal(
        aiPreviewBlockStep || (appletTier === "pro" ? "default" : "default_pro"),
        aiPreviewEnablementRules?.minimum_tier || appletTier
      )
  }

  return (
    <>
      <header style={{ backgroundColor: bgColor }} styleName={classes}>
        <ReturnButton onClick={onReturn} initialStep={initialStep} color={color} />
        {title && <h1 style={{ color }}>{title}</h1>}
        {user.tier == "free" ? (
          <LearnMoreButton
            url="https://ift.tt/applets-101"
            darkHeader={bgOnColor}
            title="Learn more: Best Practices - Applets 101"
          />
        ) : (
          <div />
        )}
      </header>
      {bannerContent && <UpgradeBanner onClick={bannerClickHandler} content={bannerContent} />}
    </>
  )
}

export default Header
