import PropTypes from "prop-types"

import StepDetails from "./step_details"
import "./styles.scss"

const ActivitySteps = ({ applet, filterIconUrl, runDetails }) => {
  const addDelayDetails = details => {
    if (!details || details.length === 0) return
    if (details.find(obj => obj.outcome === "delay_skip")) return
    if (applet.permissions.find(obj => obj.id === "delay")) return

    let delayRunDetails = details.find(obj => obj.step === "delay")

    if (!delayRunDetails?.actions_delay) return

    let actionIndex = applet.permissions.findIndex(obj =>
      obj.id.match(/^\/actions\//)
    )

    let delayDetails = {
      id: "delay",
      name: "delay before the next action",
      icon_url: "",
      brand_color: "#1e2023",
      service_name: "Delay",
      detailsForStep: {
        step: delayRunDetails.step,
        actions_delay: delayRunDetails.actions_delay,
        ts: delayRunDetails.ts,
        outcome: delayRunDetails.outcome,
      },
    }

    applet.permissions.splice(actionIndex, 0, delayDetails)
  }

  const addFilterCodeDetails = details => {
    if (!details || details.length === 0) return

    let filterCodeRunDetails = details.find(obj => obj.step === "filter")

    if (!filterCodeRunDetails || !filterCodeRunDetails.message) return

    let actionIndex = applet.permissions.findIndex(obj =>
      obj.id.match(/^\/actions\//)
    )

    let filterCodeDetails = {
      id: "filter",
      name: "This Applet has a filter that can affect how its actions run",
      icon_url: filterIconUrl,
      brand_color: "#eee",
      service_name: "Filter",
      detailsForStep: {
        step: filterCodeRunDetails.step,
        message: filterCodeRunDetails.message,
        message_title: filterCodeRunDetails.message_title,
        outcome: filterCodeRunDetails.outcome,
        ts: filterCodeRunDetails.ts,
      },
    }

    applet.permissions.splice(actionIndex, 0, filterCodeDetails)
  }

  addFilterCodeDetails(runDetails)
  addDelayDetails(runDetails)

  return (
    <div styleName="activity-steps">
      {applet.permissions.map((step, i) => (
        <StepDetails
          key={step.id}
          finalStep={applet.permissions.length === i + 1}
          detailsForStep={
            ["Filter", "Delay"].includes(step.service_name)
              ? step.detailsForStep
              : runDetails?.find(
                  stepDetail => stepDetail.permission_id === step.id
                )
          }
          brand_color={step.brand_color}
          icon_url={step.icon_url}
          id={step.id}
          name={step.name}
          service_name={step.service_name}
        />
      ))}
    </div>
  )
}

ActivitySteps.propTypes = {
  applet: PropTypes.object,
  filterIconUrl: PropTypes.string,
  runDetails: PropTypes.array,
}

export default ActivitySteps
