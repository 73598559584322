import { useState } from "react"
import PropTypes from "prop-types"

import "./upsell.scss"
import UpgradePlanModal from "app/components/upgrade_plan_modal"

export default function Upsell({
  extraClass,
  myAppletsTotal,
  proPlusProductId,
  proPlusSubscriptionUrl,
  proSubscriptionUrl,
  subscriptionPaymentType,
  tier,
  total,
  trialing,
}) {
  const [showModal, updateShowModal] = useState(false)

  if (["pro", "legacy_pro"].includes(tier)) {
    return ""
  }

  let copy = "Get Pro to get 20 Applets"
  let link = proSubscriptionUrl
  let tierRequirement = "intermediate_pro"

  if (tier === "intermediate_pro") {
    copy = "Get Pro+ for unlimited Applets"
    link = proPlusSubscriptionUrl
    tierRequirement = "pro"
  }

  const handleOnClick = e => {
    if (tier === "intermediate_pro") {
      e.preventDefault()
      updateShowModal(true)
    }
  }
  return (
    <>
      <a href={link} onClick={handleOnClick} className="link-standalone" styleName={`pro-upsell ${extraClass}`}>
        {copy}
      </a>
      {tier === "intermediate_pro" && (
        <UpgradePlanModal
          appletFeatureFlags={false}
          onClose={() => updateShowModal(false)}
          onUpgrade={() => {
            window.appendFlash("Upgrade complete. Welcome to IFTTT Pro!", "success", () => window.location.reload())
          }}
          proPlusProductId={proPlusProductId}
          step={myAppletsTotal >= total ? "applet_quota" : "plan_upgrade"}
          trackLocationType="my_applets"
          tierRequirement={tierRequirement}
          urls={{
            newProSubscriptionUrl: proSubscriptionUrl,
            newProPlusSubscriptionUrl: proPlusSubscriptionUrl,
          }}
          user={{
            eligibleForTrial: false,
            eligibleTrialPeriod: 0,
            isTrialing: trialing,
            subscriptionPaymentType,
            tier,
          }}
          visible={showModal}
        />
      )}
    </>
  )
}

Upsell.propTypes = {
  extraClass: PropTypes.string,
  proPlusSubscriptionUrl: PropTypes.string.isRequired,
  proPlusProductId: PropTypes.string.isRequired,
  proSubscriptionUrl: PropTypes.string.isRequired,
  subscriptionPaymentType: PropTypes.string,
  tier: PropTypes.string.isRequired,
  myAppletsTotal: PropTypes.number,
  total: PropTypes.number,
  trialing: PropTypes.bool.isRequired,
}

Upsell.defaultProps = {
  extraClass: "",
}
