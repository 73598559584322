import OnboardingTile from "./onboarding_tile"
import Header from "./header"

import home from "images/icons/automate-home.svg"
import business from "images/icons/automate-business.svg"
import somethingElse from "images/icons/something-else.svg"

import "./index.scss"
import { submitUserGoals } from "./api"

interface Props {
  onGoalSelected: (goal: string) => void
  onClickBack: () => void
  progressBarSteps: Array<string>
  userIsOnboarding: boolean
  browserPlatform: string
  urls: {
    clearExploreCacheUrl: string
  }
}

const GoalSelection = ({
  userIsOnboarding,
  onGoalSelected,
  onClickBack,
  progressBarSteps,
  browserPlatform,
  urls,
}: Props) => {
  const goals = [
    {
      name: "Automate my home",
      formattedName: "smart_home",
      icon: home,
      description: "I want to use automation to control devices in and around my home to simplify my lifestyle.",
    },
    {
      name: "Automate my business",
      formattedName: "eo1",
      icon: business,
      description: "I want to automate my business processes to simplify them and save time.",
    },
    {
      name: "Something else",
      formattedName: "something_else",
      icon: somethingElse,
      description: "I want to automate workflows to do something else.",
    },
  ]
  const hideBackButton = userIsOnboarding && browserPlatform !== "desktop"

  const onSelect = async (goal: string): Promise<void> => {
    await submitUserGoals([goal], userIsOnboarding ? null : urls.clearExploreCacheUrl)
    onGoalSelected(goal)
    window.App.Utils?.logCustomDatadogAction?.(`${goal}_goal_selected`)
  }

  return (
    <div styleName="goal-selection-view">
      <Header
        hideBackButton={hideBackButton}
        onClickBack={onClickBack}
        page="goals"
        progressBarSteps={progressBarSteps}
        title="What brings you to IFTTT?"
      />
      <div styleName="container goal-selection">
        <div>
          <p className="txt-body-3">Pick one to help us suggest the best Applets and tools for you.</p>
          <ul styleName="goal-list">
            {goals.map(p => (
              <li key={p.name}>
                <OnboardingTile onClick={() => onSelect(p.formattedName)} onboardingItem={p} isGoalSelect />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default GoalSelection
