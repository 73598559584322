import { Component } from "react"
import PropTypes from "prop-types"

import appletOrConnectionPath from "app/scripts/applet_or_connection_path"
import { connectionShape } from "app/lib/shared_prop_types"
import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"

export default class AppletCard extends Component {
  render() {
    const applet = this.props.applet
    return (
      <li className="my-web-applet-card web-applet-card" id={`applet-${applet.id}`}>
        <SmallAppletCard appletPath={appletOrConnectionPath(applet)} applet={applet} />
      </li>
    )
  }
}

AppletCard.propTypes = {
  applet: connectionShape,
}
