export const steps = {
  INITIAL: 0,
  SELECTING_TRIGGER_SERVICE: 1,
  CONNECTING_TRIGGER_SERVICE: 2,
  SELECTING_TRIGGER: 3,
  SELECTING_TRIGGER_FIELDS: 4,
  SELECTING_ACTION_SERVICE: 5,
  CONNECTING_ACTION_SERVICE: 6,
  SELECTING_ACTION: 7,
  SELECTING_ACTION_FIELDS: 8,
  SELECTING_QUERY_SERVICE: 9,
  CONNECTING_QUERY_SERVICE: 10,
  SELECTING_QUERY: 11,
  SELECTING_QUERY_FIELDS: 12,
  ADDING_FILTER_CODE: 13,
  EDITING_FILTER_CODE: 14,
  ADDING_DELAY_ACTION: 15,
  EDITING_DELAY_ACTION: 16,
  PREVIEW: 17,
  PREVIEW_OPEN_AI_RESULT: 18,
}

const previousStepMap = {
  [steps.SELECTING_TRIGGER_SERVICE]: steps.INITIAL,
  [steps.CONNECTING_TRIGGER_SERVICE]: steps.SELECTING_TRIGGER,
  [steps.SELECTING_TRIGGER]: steps.SELECTING_TRIGGER_SERVICE,
  [steps.SELECTING_TRIGGER_FIELDS]: steps.SELECTING_TRIGGER,
  [steps.SELECTING_ACTION_SERVICE]: steps.INITIAL,
  [steps.CONNECTING_ACTION_SERVICE]: steps.SELECTING_ACTION,
  [steps.SELECTING_ACTION]: steps.SELECTING_ACTION_SERVICE,
  [steps.SELECTING_ACTION_FIELDS]: steps.SELECTING_ACTION,
  [steps.SELECTING_QUERY_SERVICE]: steps.INITIAL,
  [steps.CONNECTING_QUERY_SERVICE]: steps.SELECTING_QUERY,
  [steps.SELECTING_QUERY]: steps.SELECTING_QUERY_SERVICE,
  [steps.SELECTING_QUERY_FIELDS]: steps.SELECTING_QUERY,
  [steps.ADDING_FILTER_CODE]: steps.INITIAL,
  [steps.EDITING_FILTER_CODE]: steps.INITIAL,
  [steps.ADDING_DELAY_ACTION]: steps.INITIAL,
  [steps.EDITING_DELAY_ACTION]: steps.INITIAL,
  [steps.PREVIEW]: steps.INITIAL,
  [steps.PREVIEW_OPEN_AI_RESULT]: steps.INITIAL,
}

export const previousStep = ({ addingStep, step }) =>
  addingStep ? previousStepMap[step] : steps.INITIAL

export const stepsTitle = ({ step, editing, makingItTheirOwn, addingStep }) =>
  ({
    [steps.INITIAL]: editing && !makingItTheirOwn ? "Edit Applet" : "Create",
    [steps.SELECTING_TRIGGER_SERVICE]: "Choose a service",
    [steps.CONNECTING_TRIGGER_SERVICE]: "Connect service",
    [steps.SELECTING_TRIGGER]: "Choose a trigger",
    [steps.SELECTING_TRIGGER_FIELDS]: addingStep
      ? "Complete trigger fields"
      : "Edit trigger fields",
    [steps.SELECTING_ACTION_SERVICE]: "Choose a service",
    [steps.CONNECTING_ACTION_SERVICE]: "Connect service",
    [steps.SELECTING_ACTION]: "Choose an action",
    [steps.SELECTING_ACTION_FIELDS]: addingStep
      ? "Complete action fields"
      : "Edit action fields",
    [steps.SELECTING_QUERY_SERVICE]: "Choose a service",
    [steps.CONNECTING_QUERY_SERVICE]: "Connect service",
    [steps.SELECTING_QUERY]: "Choose a query",
    [steps.SELECTING_QUERY_FIELDS]: addingStep
      ? "Complete query fields"
      : "Edit query fields",
    [steps.ADDING_FILTER_CODE]: "Add filter code",
    [steps.EDITING_FILTER_CODE]: "Edit filter code",
    [steps.ADDING_DELAY_ACTION]: "Add delay",
    [steps.EDITING_DELAY_ACTION]: "Edit delay",
    [steps.PREVIEW]: "Review and finish",
    [steps.PREVIEW_OPEN_AI_RESULT]: "Your Applet",
  }[step])

export const creationModeOptions = {
  AUTOMATE_WITH_AI: 0,
  DIY: 1,
}
