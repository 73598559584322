import { fetchAuthenticityToken } from "app/scripts/refresh_form_authenticity_token"

export default function TFASendPin() {
  let smsForm = document.querySelector(".sms-form")

  smsForm?.addEventListener("submit", async function (event) {
    event.preventDefault()
    let phoneErrors = document.querySelector(".phone-errors")
    let textMeButton = document.querySelector("#text-me-button")
    let phoneErrorsText = phoneErrors.children[0]

    let data = new FormData()
    data.append("phone_number", document.getElementById("phone_number").value)

    fetch(
      new Request(smsForm.action, {
        method: "POST",
        cache: "no-store",
        body: data,
        headers: {
          "X-CSRF-Token": await fetchAuthenticityToken(),
        },
      })
    )
      .then(function (response) {
        if (!response.ok) {
          phoneErrors.style.display = "block"
          response
            .text()
            .then(body => (phoneErrorsText.innerText = body ? body : "We weren't able to reach you. Please try again."))
          textMeButton.toggleAttribute("disabled", false)
          textMeButton.innerText = "Text me"
          return
        }
        phoneErrors.style.display = "none"
      })
      .catch(function () {
        phoneErrors.style.display = "block"
        phoneErrorsText.innerText = "There was an issue during the request. Please try again."
        textMeButton.toggleAttribute("disabled", false)
        textMeButton.innerText = "Text me"
      })

    return false
  })
}
