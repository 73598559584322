import AccountInfo from "./account_info"

interface Props {
  service: object
  liveChannels: Array<object>
  addAccountPath: string
  allowMultipleLiveChannels: boolean
}
export default function MyServiceSettingsView({
  service,
  liveChannels,
  addAccountPath,
  allowMultipleLiveChannels = false,
}: Props) {
  return (
    <>
      <div className="service-settings-view">
        <AccountInfo
          service={service}
          liveChannels={liveChannels}
          addAccountPath={addAccountPath}
          allowMultipleLiveChannels={allowMultipleLiveChannels}
        />
      </div>
    </>
  )
}
