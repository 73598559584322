import parse from "html-react-parser"

import Modal from "shared/components/modal"

import appStoreQR from "images/web/onboarding/app_store_qr.svg"
import appStoreLogo from "images/icons/apple_appstore_download.svg"
import playStoreQR from "images/web/onboarding/play_store_qr.svg"
import playStoreLogo from "images/icons/google_play_store_download.svg"

import "./index.scss"

interface Props {
  downloadPlatform: string
  showDownload: boolean
  setShowDownload: (v: boolean) => void
}

const DownloadModal = ({ downloadPlatform, showDownload, setShowDownload }: Props) => {
  const appStoreDownloadUrl = "https://apps.apple.com/app/ifttt-automation-workflow/id660944635"
  const playStoreDownloadUrl = "https://play.google.com/store/apps/details?id=com.ifttt.ifttt"
  const iphonePlatform = downloadPlatform === "iphone"

  return (
    <Modal show={showDownload} closeListener={() => setShowDownload(false)}>
      <p className="txt-body-3">Scan code to get the IFTTT app</p>
      <span styleName="qr">{parse(iphonePlatform ? appStoreQR : playStoreQR)}</span>
      <a
        styleName="store-logo"
        href={iphonePlatform ? appStoreDownloadUrl : playStoreDownloadUrl}
        target="_blank"
        rel="noreferrer"
      >
        <span>{parse(iphonePlatform ? appStoreLogo : playStoreLogo)}</span>
      </a>
      <div>
        <button onClick={() => setShowDownload(false)} className="button button-tertiary">
          Close
        </button>
      </div>
    </Modal>
  )
}

export default DownloadModal
