import { forwardRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import FlipMove from "react-flip-move"

import { getUserArchivedApplets, restoreApplet } from "shared/lib/graphql"
import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"
import Search from "../my_applets/search"
import AppletCard from "./applet_card"
import Spinner from "shared/components/spinner"
import SearchIndex from "shared/lib/search_index"
import { captureMessage } from "@sentry/react"
import EmptyStateCta from "shared/components/empty_state"

import "./index.scss"

const AppletCardWithRef = forwardRef((props, ref) => (
  <div ref={ref}>
    <AppletCard {...props} />
  </div>
))
AppletCardWithRef.displayName = "AppletCardWithRef"

const PER_PAGE = 24

export default function ArchiveApplets({ proBannerUrls, user }) {
  const { appletQuota, isPro, login } = user
  const [applets, setApplets] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [restoringApplet, setRestoringApplet] = useState(false)
  const [usedAppletsCount, setUsedAppletsCount] = useState(
    appletQuota.total - appletQuota.remaining
  )
  const [appletsIndex] = useState(new SearchIndex())
  const [offset, setOffset] = useState(0)
  const [pageEndReached, setPageEndReached] = useState(false)

  useEffect(() => {
    if (applets === null) {
      return
    } else if (searchTerm) {
      const appletIds = appletsIndex.search(searchTerm)
      setSearchResults(applets.filter(applet => appletIds.includes(applet.id)))
    } else {
      setSearchResults(applets)
    }

    // Resize window to fix positioning issues
    window.dispatchEvent(new Event("resize"))
  }, [applets, searchTerm])

  const loadNextPage = async () => {
    const { applets } = await getUserArchivedApplets(login, PER_PAGE, offset)
    const connections = applets
      .map(a => a.normalized_applet)
      .map(c => ({
        ...c,
        service_names: c.channels.map(ch => ch.name),
      }))

    setOffset(offset + PER_PAGE)
    if (connections.length < PER_PAGE) {
      setPageEndReached(true)
    }
    return connections
  }

  const handleShowMore = async () => {
    const connections = await loadNextPage()
    setApplets([...applets, ...connections])
  }

  useEffect(() => {
    loadNextPage()
      .then(connections => {
        setApplets(connections)
        connections.forEach(doc => {
          appletsIndex.add(doc.id, doc.name, [
            ...doc.service_names,
            ...(doc.description || "").split(" "),
          ])
        })
      })
      .catch(
        rejectedXHRPromiseLogger(() => {
          setApplets([])
        })
      )
  }, [])

  const onRestoreApplet = applet => {
    if (restoringApplet) return null

    setRestoringApplet(true)

    restoreApplet(applet.id)
      .then(data => {
        const { errors, normalized_applet } = data.restorePersonalApplet

        if (errors.length > 0 || normalized_applet.archived) {
          if (normalized_applet.archived) {
            const msg =
              "Restore Applet mutation success but Applet continues archived"
            if (window.App.allowDebugErrorMessages) {
              console.warn("catch", msg)
            }
            captureMessage(msg)
          }

          if (errors[0].message === "You cannot update this applet.") {
            window.appendFlash(errors[0].message, "danger")
          } else {
            window.appendFlash(
              "Could not restore your Applet. Please try again later",
              "danger"
            )
          }
        } else if (!normalized_applet.archived) {
          setApplets(applets.filter(a => a.id !== applet.id))

          setUsedAppletsCount(usedAppletsCount + 1)
          window.appendFlash("Applet restored successfully")
        }
      })
      .catch(
        rejectedXHRPromiseLogger(() => {
          window.appendFlash(
            "Could not restore your Applet. Please try again later",
            "danger"
          )
        })
      )
      .finally(() => {
        setRestoringApplet(false)
      })
  }

  const remainingQuota = appletQuota.total - usedAppletsCount
  const availableToRestore = isPro || remainingQuota > 0

  if (applets === null) {
    return <Spinner />
  } else if (applets.length) {
    return (
      <>
        <h1>Archive</h1>

        <Search onSearch={setSearchTerm} value={searchTerm} />

        <section className="dashboard-applets">
          {searchResults.length > 0 ? (
            <FlipMove typeName="ul" className="web-applet-cards">
              {searchResults.map(applet => (
                <AppletCardWithRef
                  key={applet.id}
                  applet={applet}
                  isPro={isPro}
                  availableToRestore={availableToRestore}
                  onRestoreApplet={onRestoreApplet}
                  subscriptionUrl={proBannerUrls.subscriptionUrl}
                />
              ))}
            </FlipMove>
          ) : (
            <EmptyStateCta
              heading="No results found..."
              copy="Looks like we couldn't find any Applet matches. Please try again."
            />
          )}
        </section>
        {!pageEndReached && (
          <button
            styleName="show-more"
            className="button-secondary"
            onClick={handleShowMore}
          >
            Show more
          </button>
        )}

        <div className="breath-space" />
      </>
    )
  } else {
    return (
      <>
        <h1>Archive</h1>
        <EmptyStateCta
          heading="No archived Applets"
          copy="Archived Applets can be restored when needed."
          illustration="box"
        />
      </>
    )
  }
}

ArchiveApplets.propTypes = {
  proBannerUrls: PropTypes.object.isRequired,
  user: PropTypes.shape({
    appletQuota: PropTypes.shape({
      total: PropTypes.number.isRequired,
    }),
    isPro: PropTypes.bool.isRequired,
    login: PropTypes.string.isRequired,
  }).isRequired,
}

ArchiveApplets.defaultPropTypes = {
  isPro: false,
}
