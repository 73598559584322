import { gql, graphQueryBang } from "shared/lib/graphql"
import { post } from "shared/lib/api"

interface Applet {
  id: string
  status: string
}

const RecommendedAppletFragment = gql`
  type
  id
  name
  config_type
  archived
  description
  icon_url
  monochrome_icon_url
  status
  service_slug
  brand_color
  installs_count
  author
  author_image_url
  author_tier
  run_count
  just_enabled
  last_run
  created_at
  updated_at
  author_id
  source_applet_id
  service_name
  by_service_owner
  instant
  speed
  is_tombstoned
  push_enabled
  can_push_enable
  pro_features
  published
  is_hidden
  requires_mobile_app
  requires_android_app
  requires_ios_app
  background_images {
    sm_background_image_url
    lg_background_image_url
    background_image_url_1x
    background_image_url_2x
    background_image_url_3x
  }
  channels {
    name
    module_name
    image_url
    monochrome_image_url
    brand_color
    available_to_everyone
  }
`

const RecommendedAppletsQuery = gql`
  query RecommendedAppletsQuery {
    me {
      onboarding_applets {
        ${RecommendedAppletFragment}
      }
    }
  }
`

const RecommendedAppletsV2Query = gql`
  query RecommendedAppletsV2Query($service_module_name: String) {
    me {
      onboarding_applets_v2(service_module_name: $service_module_name) {
        ${RecommendedAppletFragment}
      }
    }
  }
`

const RecommendedServicesV2Query = gql`
  query RecommendedServicesV2Query{
    me {
      onboarding_services_v2 {
        brand_color
        lrg_monochrome_image_url
        module_name
        name
        connected
      }
    }
  }
`

const UserPreferredPlatformsMutation = gql`
  mutation UserPreferredPlatformsMutation($preferred_platforms: [String!]!, $overwrite: Boolean) {
    userPreferredPlatformsUpdate(input: { preferred_platforms: $preferred_platforms, overwrite: $overwrite }) {
      preferred_platforms
      errors {
        attribute
        message
      }
    }
  }
`

const UserGoalsMutation = gql`
  mutation UserGoalsMutation($goals: [String!]!, $overwrite: Boolean) {
    userGoalsUpdate(input: { goals: $goals, overwrite: $overwrite }) {
      goals
      errors {
        attribute
        message
      }
    }
  }
`

export const submitPreferredPlatforms = async (preferredPlatforms: string[], overwrite = false) => {
  try {
    await graphQueryBang(
      UserPreferredPlatformsMutation,
      {
        preferred_platforms: preferredPlatforms,
        overwrite,
      },
      { shouldRetryOnFailure: true } // this is critical to the remaining onboarding flow
    )
  } catch (error) {
    window.appendFlash("We failed to save some important info! You might have better luck later", "danger")
  }
}

export const submitUserGoals = async (goals: string[], clearExploreCacheUrl: string | null, overwrite = true) => {
  try {
    await graphQueryBang(
      UserGoalsMutation,
      {
        goals,
        overwrite,
      },
      { shouldRetryOnFailure: true } // this is critical to the remaining onboarding flow
    )
    if (clearExploreCacheUrl) post(clearExploreCacheUrl)
  } catch (error) {
    window.appendFlash("We failed to save some important info! You might have better luck later", "danger")
  }
}

export const fetchRecommendedServicesV2 = async (): Promise<[]> => {
  const result = await graphQueryBang(RecommendedServicesV2Query)
  return result.me.onboarding_services_v2
}

export const fetchRecommendedApplets = async (): Promise<Applet[]> => {
  const result = await graphQueryBang(RecommendedAppletsQuery)
  return result.me.onboarding_applets
}

export const fetchRecommendedAppletsV2 = async (serviceModuleName: string): Promise<Applet[]> => {
  const result = await graphQueryBang(RecommendedAppletsV2Query, { service_module_name: serviceModuleName })
  return result.me.onboarding_applets_v2.filter((applet: Applet) => applet.status === "never_enabled_for_user")
}
