import { Component } from "react"
import PropTypes from "prop-types"
import Spinner from "shared/components/spinner"

import "./spinner.scss"

export default class Footer extends Component {
  handleSubmit = event => {
    event.preventDefault()
    this.props.onSaveClicked()
  }

  render() {
    const { includeSubmit, enabled, isSaving } = this.props

    return (
      <div className="config-footer">
        {isSaving ? (
          <div styleName="spinner">
            <Spinner
              config={{
                position: "relative",
                top: "inherit",
              }}
            />
          </div>
        ) : (
          includeSubmit && (
            <div className="button">
              <button className="button-primary" onClick={this.handleSubmit} disabled={!enabled}>
                Save
              </button>
            </div>
          )
        )}
      </div>
    )
  }
}

Footer.propTypes = {
  enabled: PropTypes.bool,
  includeSubmit: PropTypes.bool,
  onSaveClicked: PropTypes.func,
  isSaving: PropTypes.bool.isRequired,
}
