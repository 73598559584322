import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import settingsImg from "images/layout/icon-settings.svg"

export default class SettingsButton extends Component {
  state = {
    appletEverEnabled: ["disabled_for_user", "enabled_for_user"].includes(
      this.props.connection.status
    ),
    publishedByCurrentUser:
      this.props.connection.author_id === window.App.user?.id && this.props.connection.published === true,
  }

  componentDidMount() {
    window.addEventListener("appletEnabled", this.appletEnabled)
  }

  componentWillUnmount() {
    window.removeEventListener("appletEnabled", this.appletEnabled)
  }

  appletEnabled = event => {
    this.setState({ appletEverEnabled: !!event.detail.enabled })
  }

  render() {
    if (this.state.appletEverEnabled || this.state.publishedByCurrentUser) {
      let props = {
        href: this.state.appletEverEnabled ? this.props.editPath : this.props.publishedAppletEditPath,
      }

      if (this.state.publishedByCurrentUser) {
        props["target"] = "_blank"
        props["rel"] = "noreferrer"
      }

      return (
        <a
          {...props}
          className="button-tertiary button-on-color button-outlined button-with-icon only-icon-on-mobile settings-button-fixed"
          aria-label="Settings"
        >
          <span className="button-icon">{parse(settingsImg)}</span>
          <span className="hide-on-xs">
            {this.state.appletEverEnabled
              ? "Settings"
              : "Published Applet Settings"}
          </span>
        </a>
      )
    } else {
      return null
    }
  }
}

SettingsButton.propTypes = {
  connection: PropTypes.shape({
    status: PropTypes.string,
    author_id: PropTypes.string,
    published: PropTypes.bool,
  }),
  editPath: PropTypes.string.isRequired,
  publishedAppletEditPath: PropTypes.string,
}
