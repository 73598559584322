import moment from "moment"

export const sortByCreatedAt = applets => {
  const now = new Date()

  return [...applets]
    .sort((a, b) => {
      const aCreatedAt = a.created_at ? new Date(a.created_at) : now
      const bCreatedAt = b.created_at ? new Date(b.created_at) : now
      const aDate = new moment(aCreatedAt).format("YYYYMMDD")
      const bDate = new moment(bCreatedAt).format("YYYYMMDD")
      return aDate - bDate
    })
    .reverse()
}
