import { steps } from "./steps"
import { arrayAdd, arrayRemove, arrayReplace } from "shared/lib/utils"
import { maxTier } from "./tiers"

export const initialState = ({ user }) =>
  Object.freeze({
    appletId: null,
    name: null,
    loading: false,
    dataChanged: false,
    saving: false,
    services: [],
    step: steps.INITIAL,
    selectedStep: null,
    selectedStepIndex: null,
    selectedTrigger: null,
    selectedActions: [],
    selectedQueries: [],
    filterCode: "",
    delay: 0, // setting as 0 instead of null
    connectingService: null,
    preview: {},
    errors: {},
    editing: false,
    addingStep: false,
    makingItTheirOwn: false,
    appletTier: "free",
    appletFeatures: [],
    openAIResult: null,
    user,
  })

const resetProps = Object.freeze({
  loading: false,
  errors: {},
})

export const formatStep = ({ step, fields }, type, service = step.service) => {
  let sortedFields = Object.values(fields)
  if (sortedFields.some(f => !!f.order)) {
    sortedFields = sortedFields.sort((a, b) => a.order - b.order)
  } else {
    sortedFields = step[`${type}_fields`].map(
      stepField => fields[stepField.name]
    )
    const liveChannelField = fields._live_channel_id
    if (
      liveChannelField &&
      !sortedFields.find(f => f.name === "_live_channel_id")
    ) {
      sortedFields.unshift(liveChannelField)
    }
  }

  return {
    id: step.id,
    name: step.name,
    ingredients: step.ingredients,
    module_name: step.module_name,
    full_module_name: step.full_module_name,
    normalized_module_name: step.normalized_module_name,
    channel_id: service.id,
    live_channel_id: step.live_channel_id,
    fields,
    tier: step.tier,
    [`${type}_fields`]: sortedFields,
    service,
  }
}

export const appletFeatures = state => {
  const { selectedActions, selectedQueries, filterCode } = state
  let features = []
  if (selectedActions.length > 1) {
    features.push("multi_action")
  }
  if (selectedQueries.length > 0) {
    features.push("queries")
  }
  if (filterCode) {
    features.push("filter_code")
  }

  return features
}

export const appletTier = state => {
  let featureTiers = appletFeatures(state).map(feature => {
    return state.user.permissions[feature]?.minimum_tier
  })
  const stepTiers = [state.selectedTrigger, ...state.selectedActions, ...state.selectedQueries].map(step => step?.tier)

  return [...featureTiers, ...stepTiers].reduce((curMax, tier) => {
    return maxTier(curMax, tier)
  }, "free")
}

export const reducer = (state, action) => {
  const completeStepsChange = updatedSteps => {
    const newState = {
      ...state,
      ...resetProps,
      ...updatedSteps,
      step: steps.INITIAL,
      connectingService: null,
      selectedStep: null,
      addingStep: false,
      dataChanged: true,
    }

    newState.appletFeatures = appletFeatures(newState)
    newState.appletTier = appletTier(newState)
    return newState
  }

  switch (action.type) {
    case "INITIAL":
      return {
        ...state,
        ...resetProps,
        step: steps.INITIAL,
        connectingService: null,
        selectedStep: null,
        addingStep: false,
      }
    case "SELECTING_TRIGGER_SERVICE":
    case "SELECTING_ACTION_SERVICE":
    case "SELECTING_QUERY_SERVICE":
      return {
        ...state,
        ...resetProps,
        step: steps[action.type],
        connectingService: null,
        addingStep: true,
      }
    case "SELECTING_TRIGGER":
    case "SELECTING_ACTION":
    case "SELECTING_QUERY":
      return {
        ...state,
        ...resetProps,
        selectedStep: null,
        step: steps[action.type],
        connectingService: action.value,
      }
    case "CONNECTING_TRIGGER_SERVICE":
    case "CONNECTING_ACTION_SERVICE":
    case "CONNECTING_QUERY_SERVICE":
      return {
        ...state,
        ...resetProps,
        step: steps[action.type],
        selectedStep: action.value,
      }
    case "TRIGGER_SERVICE_CONNECTED":
    case "ACTION_SERVICE_CONNECTED":
    case "QUERY_SERVICE_CONNECTED":
      return {
        ...state,
        ...resetProps,
        connectingService: {
          ...state.connectingService,
          live_channels: action.live_channels,
        },
      }
    case "UPDATE_SERVICE_ACCOUNT_LIST":
      return {
        ...state,
        selectedStep: {
          ...state.selectedStep,
          service: {
            ...state.selectedStep.service,
            live_channels: action.live_channels,
          },
        },
      }
    case "SELECTING_TRIGGER_FIELDS":
    case "SELECTING_ACTION_FIELDS":
    case "SELECTING_QUERY_FIELDS":
      return {
        ...state,
        ...resetProps,
        step: steps[action.type],
        selectedStep: action.value,
        ...(state.addingStep || {
          // editing selectedStep
          selectedStepIndex: action.index,
        }),
      }
    case "CHANGE_SERVICE_ACCOUNT":
      return {
        ...state,
        selectedStep: {
          ...state.selectedStep,
          live_channel_id: action.live_channel_id,
        },
      }
    case "ADDING_FILTER_CODE":
    case "EDITING_FILTER_CODE":
      return {
        ...state,
        ...resetProps,
        step: steps[action.type],
        connectingService: null,
      }
    case "FILTER_CODE_ERROR":
      return {
        ...state,
        step: steps.ADDING_FILTER_CODE,
      }
    case "ADDING_DELAY_ACTION":
    case "EDITING_DELAY_ACTION":
      return {
        ...state,
        ...resetProps,
        step: steps[action.type],
        connectingService: null,
      }
    case "TRIGGER_ADDED":
    case "TRIGGER_REPLACED":
      return completeStepsChange({
        selectedTrigger: formatStep(action.value, "trigger"),
      })
    case "ACTION_ADDED":
      return completeStepsChange({
        selectedActions: arrayAdd(
          state.selectedActions,
          formatStep(action.value, "action")
        ),
      })
    case "ACTION_REPLACED":
      return completeStepsChange({
        selectedActions: arrayReplace(
          state.selectedActions,
          state.selectedStepIndex,
          formatStep(action.value, "action")
        ),
      })
    case "QUERY_ADDED":
      return completeStepsChange({
        selectedQueries: arrayAdd(
          state.selectedQueries,
          formatStep(action.value, "query")
        ),
      })
    case "QUERY_REPLACED":
      return completeStepsChange({
        selectedQueries: arrayReplace(
          state.selectedQueries,
          state.selectedStepIndex,
          formatStep(action.value, "query")
        ),
      })
    case "FILTER_CODE_MODIFIED":
      return completeStepsChange({
        filterCode: action.value,
      })
    case "DELAY_ACTION_MODIFIED":
      return completeStepsChange({
        delay: action.value,
      })
    case "TRIGGER_REMOVED":
      return completeStepsChange({
        selectedTrigger: null,
      })
    case "ACTION_REMOVED":
      return completeStepsChange({
        selectedActions: arrayRemove(state.selectedActions, action.value),
      })
    case "QUERY_REMOVED":
      return completeStepsChange({
        selectedQueries: arrayRemove(state.selectedQueries, action.value),
      })
    case "FILTER_CODE_REMOVED":
      return completeStepsChange({
        filterCode: "",
      })
    case "DELAY_ACTION_REMOVED":
      return completeStepsChange({
        delay: 0, // sending 0 instead of null to remove delay via the mutation
      })
    case "SERVICES_LOADED":
      return {
        ...state,
        services: action.value,
      }
    case "LOADING":
      return {
        ...state,
        loading: action.value,
      }
    case "SAVING":
      return {
        ...state,
        saving: action.value,
      }
    case "CHANGE_STEP":
      return action.value === steps.INITIAL
        ? {
            ...state,
            ...resetProps,
            connectingService: null,
            selectedStep: null,
            addingStep: false,
            step: action.value,
          }
        : {
            ...state,
            ...resetProps,
            step: action.value,
          }
    case "STEP_CREATION_FAILED":
      return {
        ...state,
        ...resetProps,
        errors: action.value,
      }
    case "PREVIEW":
      return {
        ...state,
        step: steps.PREVIEW,
        connectingService: null,
      }
    case "UPDATE_PREVIEW":
      return {
        ...state,
        preview: action.value,
      }
    case "PREVIEW_OPEN_AI_RESULT":
      return {
        ...state,
        openAIResult: action.value,
        step: steps.PREVIEW_OPEN_AI_RESULT,
      }
    case "SET_OVERRIDE_STATE":
      return {
        ...state,
        ...resetProps,
        ...action.value,
      }
    case "MAKING_IT_THEIR_OWN":
      return {
        ...state,
        makingItTheirOwn: true,
        dataChanged: true,
      }
    case "SUCCESSFUL_PRO_UPGRADE":
      return {
        ...state,
        user: {
          ...state.user,
          // not boring users with applet quota after upgrade to pro, wait next reload to show the banner
          appletQuota: {
            total: -1,
            remaining: -1,
          },
          subscriptionPaymentType: "stripe",
          tier: "intermediate_pro",
          permissions: {
            ...state.user.permissions,
            filter_code: {
              ...state.user.permissions.filter_code,
              permitted: false,
            },
            multi_action: {
              ...state.user.permissions.multi_action,
              permitted: true,
            },
            queries: {
              ...state.user.permissions.queries,
              permitted: false,
            },
            multi_service_account: {
              ...state.user.permissions.multi_service_account,
              permitted: false,
            },
          },
        },
      }
    case "SUCCESSFUL_PRO_PLUS_UPGRADE":
      // ⚠️ these permissions need to match onUpgrade
      // in app/assets/javascripts/shared/components/connection_card/config/index.js
      return {
        ...state,
        user: {
          ...state.user,
          appletQuota: {
            total: -1,
            remaining: -1,
          },
          subscriptionPaymentType: "stripe",
          tier: "pro",
          permissions: {
            ...state.user.permissions,
            filter_code: {
              ...state.user.permissions.filter_code,
              permitted: true,
            },
            multi_action: {
              ...state.user.permissions.multi_action,
              permitted: true,
            },
            queries: {
              ...state.user.permissions.queries,
              permitted: true,
            },
            multi_service_account: {
              ...state.user.permissions.multi_service_account,
              permitted: true,
            },
          },
        },
      }
    default:
      throw new Error(`Unknown step type: ${action.type}`)
  }
}
