import parse from "html-react-parser"

interface Props {
  logo: string
  onBackClick: func
}

import chevronLeftBlack from "images/layout/chevron-left-black.svg"

import "./header.scss"

const Header = ({ logo, onBackClick }: Props) => {
  return (
    <header styleName="cancelling_billing">
      <div styleName="spacer">
        {onBackClick && (
          <button
            type="submit"
            className="button-tertiary button-outlined button-with-icon only-icon-on-mobile"
            aria-label="Back button"
            onClick={onBackClick}
          >
            <span className="button-icon">
              {parse(chevronLeftBlack)}
            </span>
            <span className="hide-on-xs">Back</span>
          </button>
        )}
      </div>
      <span styleName="ifttt-logo">
        <img src={logo} alt="IFTTT logo" />
      </span>
      <div styleName="spacer" />
    </header>
  )
}

export default Header
