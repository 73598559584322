export default function setupInviteMember(formID: string, ownerEmail: string) {
  const form = document.querySelector("#" + formID)
  const inviteButton = form.querySelector("#invite-button")
  const membersList = form.querySelector("#members-invited-list")
  const emailInput = form.querySelector("#email-to-be-invited")

  const maximiunMembers = 10

  const resetStatus = () => {
    emailInput.classList.remove("invalid")
    form.querySelector("#email-validation")?.remove()
  }

  const addError = (input: HTMLInputElement, message: string) => {
    const validationElement = document.createElement("span")
    validationElement.id = "email-validation"
    validationElement.innerText = message
    input.insertAdjacentElement("afterend", validationElement)
    input.classList.add("invalid")
  }

  const clickHandler = (e: Event) => {
    e.preventDefault()

    resetStatus()

    let membersCount = membersList?.querySelectorAll("li")?.length || 0

    if (membersCount >= maximiunMembers) {
      return
    }

    const email = emailInput.value
    const membersToBeInvited = []
    form.querySelectorAll("input[name='group[user_emails][]']").forEach(i => membersToBeInvited.push(i.value))

    if (email.length === 0 || !emailInput.checkValidity()) {
      addError(emailInput, "Please enter a valid email address")
      return
    } else if (email == ownerEmail) {
      addError(emailInput, "You can't invite your own email address")
      return
    } else if (membersToBeInvited.includes(email)) {
      addError(emailInput, "Duplicate email, member is already in your group")
      return
    }

    membersList?.querySelector("ul")?.insertAdjacentHTML("beforeend", `<li>${email}</li>`)
    form.insertAdjacentHTML("afterbegin", `<input type="hidden" name="group[user_emails][]" value="${email}" />`)
    membersCount += 1
    membersList.classList.remove("hidden")

    emailInput.value = ""

    if (membersCount >= maximiunMembers) {
      inviteButton.setAttribute("disabled", "true")
    }
  }

  inviteButton.addEventListener("click", clickHandler)
  emailInput.addEventListener("input", resetStatus)
  form.addEventListener("submit", resetStatus)
}
