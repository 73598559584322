import PropTypes from "prop-types"

import FadeIn from "shared/components/fade_in"

import SdkLayout from "./sdk_layout"
import FooterLink from "./footer_link"
import WorksWith from "./works_with"

export default function ConnectedServicePage({
  connectedServiceName,
  connectedUserName,
  connectionError,
  incorrectAccountError,
  nextStepColor,
  nextStepLabel,
  nextStepUrl,
  services,
}) {
  let message

  if (connectionError) {
    message = (
      <p>
        Sorry, we weren’t able to connect your <b>{connectedServiceName}</b> and{" "}
        <b>IFTTT</b> accounts. Please try again to continue setting up your
        integration.
      </p>
    )
  } else if (incorrectAccountError) {
    message = (
      <p>
        Your <b>IFTTT</b> account is now connected to{" "}
        <b>{connectedServiceName}</b>, but the {connectedServiceName} account{" "}
        <b>{connectedUserName}</b> doesn’t match the one you were already signed
        in with. To continue, please connect the service again using that
        account.
      </p>
    )
  } else {
    message = (
      <p>
        Your <b>{connectedServiceName}</b> and <b>IFTTT</b> accounts are now
        connected.
      </p>
    )
  }

  return (
    <SdkLayout
      footer={
        <FooterLink
          label={nextStepLabel}
          backgroundColor={nextStepColor}
          href={nextStepUrl}
          fadeIn={true}
          fadeInDelay={1000}
        />
      }
    >
      <FadeIn fade={500} delay={500}>
        <WorksWith
          services={services}
          loggedIn={true}
          connectedServiceName={connectedServiceName}
        />
        {message}
      </FadeIn>
    </SdkLayout>
  )
}

ConnectedServicePage.propTypes = {
  connectedServiceName: PropTypes.string.isRequired,
  connectedUserName: PropTypes.string,
  connectionError: PropTypes.bool.isRequired,
  incorrectAccountError: PropTypes.bool.isRequired,
  nextStepColor: PropTypes.string,
  nextStepLabel: PropTypes.string.isRequired,
  nextStepUrl: PropTypes.string.isRequired,
  services: WorksWith.propTypes.services,
}
