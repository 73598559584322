import { useEffect, useState } from "react"
import parse from "html-react-parser"

import ServiceBrand from "./shared/service_brand"
import SuggestionModal from "shared/components/suggestion_modal"
import { useBgColorContext } from "./context"
import { headerColored } from "./utils/container_background_color"
import { pluralizeStepType } from "shared/lib/utils"
import plusIcon from "images/icons/plus-icon-soft.svg"
import plusIconMobile from "images/icons/plus-icon-soft-mobile.svg"
import proPlusBadge from "images/plan-badges/pro_plus.svg"
import proBadge from "images/plan-badges/pro.svg"

import "./step_selector.scss"
import classNames from "classnames"
import { Service, TQAType } from "./utils/interfaces"

interface StepSelectorProps {
  onStepSelect: (v: TQAType) => void
  service: Service
  steps: []
  selectedSteps: Array<TQAType> | []
  stepType: "trigger" | "query" | "action"
}

interface SuggestionCardProps {
  isDesktop: boolean
  stepType: "trigger" | "query" | "action"
  onClick: () => void
}

const SuggestionCard = ({ stepType, onClick, isDesktop }: SuggestionCardProps) => (
  <li styleName="suggestion-card" role="listitem" onClick={onClick}>
    <a title={`Suggest a new ${stepType}`}>
      <span>{parse(isDesktop ? plusIcon : plusIconMobile)}</span>
      <span styleName="title">Suggest a new {stepType}</span>
    </a>
  </li>
)

export default function StepSelector({ onStepSelect, service, selectedSteps, steps, stepType }: StepSelectorProps) {
  const bgColor = useBgColorContext()
  const [showSuggestionModal, setShowSuggestionModal] = useState(false)
  const isDesktop = window.matchMedia?.(`(min-width: 724px)`)?.matches

  useEffect(() => {
    headerColored(bgColor)

    return () => {
      headerColored("initial")
    }
  }, [bgColor])

  const identifiersFromSelectedSteps = selectedSteps.map((step: TQAType) => step.full_module_name)

  const stepsAvailables = steps
    .filter((step: TQAType) => !identifiersFromSelectedSteps.includes(step.full_module_name))
    .sort((a, b) => a["weight"] - b["weight"])

  const tile = (step: TQAType) => {
    const onStepClicked = () => {
      onStepSelect({ ...step, service })
    }

    return (
      <li key={step.id} style={{ backgroundColor: service.brand_color }} role="listitem" onClick={onStepClicked}>
        <a title={`Choose ${stepType}: ${step.name}`} styleName={classNames({ badged: step.tier !== "free" })}>
          <span styleName="title">{step.name}</span>
          <span styleName="description">{step.description}</span>
        </a>
        {step.tier !== "free" && (
          <span title={`This ${stepType} has pro features`} className="card-badge fixed">
            {parse(step.tier === "pro" ? proPlusBadge : proBadge)}
          </span>
        )}
      </li>
    )
  }

  return (
    <>
      {showSuggestionModal && (
        <SuggestionModal
          channelId={service.id}
          onClose={() => setShowSuggestionModal(false)}
          feedbackTqaType={stepType}
          serviceName={service.name}
        />
      )}
      <div styleName="step-selector">
        <section styleName="brand" style={{ backgroundColor: bgColor }}>
          <ServiceBrand title={service.name} image={service.image} />
        </section>
        {stepsAvailables.length > 0 ? (
          <ul role="list">
            {stepsAvailables.map(step => tile(step))}
            <SuggestionCard stepType={stepType} onClick={() => setShowSuggestionModal(true)} isDesktop={isDesktop} />
          </ul>
        ) : (
          <span styleName="empty-list">You have selected all {pluralizeStepType(stepType)} from this service</span>
        )}
      </div>
    </>
  )
}
