import checkmark from "images/icons/checkmark.svg"
import { formatHexColorValue } from "shared/lib/alternate_brand_color"
import "./onboarding_tile.scss"
import classNames from "classnames"
import parse from "html-react-parser"

interface OnboardingItem {
  backgroundStyle?: string
  brand_color?: string
  color?: string
  description?: string
  icon?: string
  lrg_monochrome_image_url?: string
  module_name?: string
  name: string
}

interface Props {
  isGoalSelect?: boolean
  isPlatformSelect?: boolean
  isServiceSelection?: boolean
  selected?: boolean
  onboardingItem: OnboardingItem
  onClick: () => void
}

const OnboardingTile = ({
  selected,
  onboardingItem,
  onClick,
  isPlatformSelect,
  isGoalSelect,
  isServiceSelection,
}: Props) => (
  <a
    style={{
      background: `${isServiceSelection ? formatHexColorValue(onboardingItem.brand_color) : ""}`,
    }}
    onClick={onClick}
    styleName={classNames(
      "inner-tile",
      { "platform-select": isPlatformSelect },
      { "goal-select": isGoalSelect },
      { "service-tile": isServiceSelection }
    )}
  >
    {onboardingItem.lrg_monochrome_image_url && (
      <img styleName="onboarding-icon" src={onboardingItem.lrg_monochrome_image_url} />
    )}
    {onboardingItem.icon && <span>{parse(onboardingItem.icon)}</span>}
    {isServiceSelection ? <h3>{onboardingItem.name}</h3> : <div>{onboardingItem.name}</div>}
    {isGoalSelect && (
      <div styleName="description" className="txt-caption">
        {onboardingItem.description}
      </div>
    )}
    {isPlatformSelect &&
      (selected ? (
        <div styleName="selection-icon">{parse(checkmark)}</div>
      ) : (
        <div styleName="unselected selection-icon" />
      ))}
  </a>
)

export default OnboardingTile
