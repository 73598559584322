import { Component } from "react"
import PropTypes from "prop-types"

import StoryServiceCard from "./service_card"
import StoryAppletCard from "./applet_card"

import SharedMarkdown from "shared/components/shared_markdown"
import { storyFieldShape } from "app/lib/shared_prop_types"

import "./story_field.scss"

export default class StoryField extends Component {
  renderServicesField = channels => {
    return (
      <ul styleName="services-list">
        {channels.map((channel, idx) => {
          return (
            <StoryServiceCard
              service={channel}
              key={idx}
              position={idx}
              fieldPosition={this.props.fieldPosition}
            />
          )
        })}
      </ul>
    )
  }

  renderAppletsField = applets => {
    return (
      <ul
        className="web-applet-cards applets-index-applet-cards"
        styleName="applets-list"
      >
        {applets?.map((applet, idx) => {
          return (
            <StoryAppletCard
              applet={applet}
              key={idx}
              position={idx}
              fieldPosition={this.props.fieldPosition}
              linkAppletCardsToConsumerSite={
                this.props.linkAppletCardsToConsumerSite
              }
            />
          )
        })}
      </ul>
    )
  }

  renderTextField = text => {
    return <SharedMarkdown source={text} options={{ html: true }} />
  }

  render() {
    const { applets, channels, text, type } = this.props.field

    switch (type) {
      case "text":
        return (
          <li styleName="text" className="long-txt-body-2">
            {this.renderTextField(text)}
          </li>
        )
      case "applets":
        return <li>{this.renderAppletsField(applets)}</li>
      case "channels":
        return <li>{this.renderServicesField(channels)}</li>
      default:
        return null
    }
  }
}

StoryField.propTypes = {
  field: storyFieldShape.isRequired,
  fieldPosition: PropTypes.number,
  linkAppletCardsToConsumerSite: PropTypes.bool,
}

StoryField.defaultProps = {
  linkAppletCardsToConsumerSite: false,
}
