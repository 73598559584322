import PropTypes from "prop-types"

import Modal from "shared/components/modal"

import "../upgrade_plan_modal/shared_modal.scss"

const MakeItYourOwnModal = ({ visible, onClose, onConvert, onCancel }) => {
  const onCancelInternal = () => {
    onCancel?.()
    onClose()
  }
  return (
    <div styleName="modal-container">
      <Modal
        show={visible}
        closeListener={onCancelInternal}
        includeCloseX={false}
      >
        <h2>Make it your own</h2>
        <p>
          Editing this Applet will convert it to one of your own created
          Applets. It will allow you to add, change, or delete its triggers,
          queries, and actions.
        </p>
        <div className="action-buttons-container">
          <button
            className="button-tertiary"
            onClick={() => {
              onConvert?.()
              onClose()
            }}
          >
            Convert
          </button>
          <button
            className="button-tertiary button-cancel-link"
            onClick={onCancelInternal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  )
}

MakeItYourOwnModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConvert: PropTypes.func,
  onCancel: PropTypes.func,
}

export default MakeItYourOwnModal
