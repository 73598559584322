import PropTypes from "prop-types"
import parse from "html-react-parser"

import "./returning_user_flow.scss"
import icons from "../utils/art"

export default function Header(props) {
  const { services, idStrategy, connected, avatar, localization } = props

  return (
    <div styleName="header">
      <div styleName="masthead">
        <div styleName="ifttt-logo">
          {parse(icons.iftttLogoWhite)}
        </div>
        <div styleName="profile">
          {avatar ? (
            <div
              styleName="avatar"
              style={{ backgroundImage: `url(${avatar})` }}
            />
          ) : (
            <span>{idStrategy}</span>
          )}
        </div>
      </div>

      <div styleName="header-content">
        <div styleName="service-to-service">
          <div styleName="service">
            <img
              src={
                services[0].lrg_monochrome_image_url ||
                services[0].lrg_variant_image_url
              }
              title={services[0].name}
              alt={services[0].name}
            />
          </div>
          <div styleName="arrow" title={localization.to} alt={localization.to}>
            {parse(icons.arrow)}
          </div>
          <div styleName="service">
            <img
              src={
                services[1].lrg_monochrome_image_url ||
                services[1].lrg_variant_image_url
              }
              title={services[1].name}
              alt={services[1].name}
            />
          </div>
        </div>
        <p>
          {connected
            ? localization.connected_x_to_y
            : localization.connect_x_to_y}
        </p>
      </div>
    </div>
  )
}

Header.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      lrg_monochrome_image_url: PropTypes.string,
      lrg_variant_image_url: PropTypes.string,
    })
  ),
  idStrategy: PropTypes.string.isRequired,
  delayFadeInBy: PropTypes.number,
  connected: PropTypes.bool,
  avatar: PropTypes.string,
  localization: PropTypes.shape({
    connect_x_to_y: PropTypes.string.isRequired,
    connected_x_to_y: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
}

Header.defaultProps = {
  services: [],
  delayFadeInBy: 300,
  connected: false,
}
