import PropTypes from "prop-types"

import CompleteIcon from "shared/components/icons/complete_icon"
import IftttLogoIcon from "shared/components/icons/ifttt_logo_icon"
import WorksWithService from "./works_with_service"

/**
 * This component renders the "works with" header during the SDK flow.
 */
export default function WorksWith({
  services,
  loggedIn,
  connectedServiceName,
  justLoggedIn,
}) {
  return (
    <div className="sdk-works-with">
      <div>
        <WorksWithService
          {...services[0]}
          justConnected={services[0].name === connectedServiceName}
        />
      </div>
      {services.length > 1 ? (
        <div>
          <div className="sdk-works-with-text">works with</div>
          <div>
            {services.slice(1).map((service, i) => (
              <WorksWithService
                key={i}
                {...service}
                justConnected={service.name === connectedServiceName}
              />
            ))}
          </div>
          <div className="sdk-works-with-using-ifttt">
            using <IftttLogoIcon />
            {loggedIn && <CompleteIcon animate={justLoggedIn} />}
          </div>
        </div>
      ) : (
        <div className="sdk-works-with-using-ifttt">
          works with <IftttLogoIcon />
          {loggedIn && <CompleteIcon animate={justLoggedIn} />}
        </div>
      )}
    </div>
  )
}

WorksWith.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape(WorksWithService.propTypes).isRequired
  ).isRequired,
  loggedIn: PropTypes.bool.isRequired,
}

WorksWith.defaultProps = {
  connectedServiceName: "",
  justLoggedIn: false,
}
