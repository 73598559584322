import { Component } from "react"
import PropTypes from "prop-types"

import { partialStoryShape } from "app/lib/shared_prop_types"
import loadHeroImage from "shared/lib/load_hero_image"
import truncate from "lodash/truncate"

import "./story_card.scss"

class StoryCard extends Component {
  render() {
    return (
      <a href={`/${this.props.singleStoryBasePath}/${this.props.story.slug}`} rel="noreferrer" styleName="story-card">
        <div
          styleName="hero"
          title={this.props.story.title}
          aria-label={this.props.story.title}
          style={{
            backgroundColor: `${this.props.story.hero_image["background_color"]}`,
          }}
        >
          <img
            src={loadHeroImage(this.props.story.hero_image, "square")}
            title={this.props.story.title}
            alt={this.props.story.title}
            loading="lazy"
            width="336"
            height="200"
          />
        </div>
        <div styleName="title">
          <h3>{this.props.story.title}</h3>
        </div>

        <p styleName="description">
          {truncate(this.props.story.description, {
            length: 240,
            separator: /[ ,]/,
          })}
        </p>
      </a>
    )
  }
}

StoryCard.propTypes = {
  story: partialStoryShape.isRequired,
  position: PropTypes.number.isRequired,
  singleStoryBasePath: PropTypes.string.isRequired,
}

export default StoryCard
