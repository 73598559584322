import PropTypes from "prop-types"

import "./infinite_footer.scss"

/**
 * This component's job is to make full-bleed footer content look like it
 * extends down forever on Apple browsers that have "elastic" scrolling. For
 * consistency with `<SdkLayout>`, it only appears below the `phone-and-up`
 * break.
 */
export default function InfiniteFooter({ backgroundColor, opacity }) {
  // We need an outer element here to protect the inner element from having its
  // opacity manipulated by `<FadeIn>`.
  return (
    <div styleName="outer">
      <div styleName="inner" style={{ backgroundColor, opacity }} />
    </div>
  )
}

InfiniteFooter.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  opacity: PropTypes.number,
}

InfiniteFooter.defaultProps = {
  opacity: 1,
}
