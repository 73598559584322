export default function SetupDeleteAccountSurvey() {
  document.querySelectorAll(".survey.delete-account .item").forEach(el => {
    el.addEventListener("click", () => {
      document.getElementById("response_code").value = el.getAttribute(
        "data-reason"
      )

      document.querySelectorAll(".item-content").forEach(el => {
        el.classList.add("hidden")
      })

      el.querySelectorAll(".item-content")[0].classList.remove("hidden")

      // ensure that response_text will be the right one when switch between elements
      document.getElementById("response_text").value =
        el.querySelector("textarea:first-of-type").value || ""
    })

    el.querySelector("textarea:first-of-type").addEventListener("input", e => {
      document.getElementById("response_text").value = e.target.value
    })
  })
}
