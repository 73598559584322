import { Component, cloneElement } from "react"
import PropTypes from "prop-types"

import AuthPageContent from "./auth_page_content"
import FooterButton from "./footer_button"
import Form from "./form"
import FormField from "./form_field"
import FooterLink from "./footer_link"
import SdkLayout from "./sdk_layout"
import WorksWith from "./works_with"
import { post } from "shared/lib/api"

export default class ResetPasswordPage extends Component {
  state = {
    email: this.props.suggestedEmail || "",
    resetSent: false,
  }

  handleChangeEmail = e => this.setState({ email: e.target.value })

  handleSubmit = e => {
    e.preventDefault()

    const { apiResetPasswordUrl, requiredUserLogin } = this.props
    const { email } = this.state

    post(apiResetPasswordUrl, { login: requiredUserLogin || email })
      .then(() => {
        this.setState({
          resetSent: true,
        })
      })
      .catch(() => {
        window.appendFlash(
          "There was an error resetting your password",
          "danger"
        )
      })
  }

  renderLayout(footer, content) {
    return (
      <SdkLayout footer={cloneElement(footer, { fadeIn: true })}>
        <WorksWith services={this.props.services} loggedIn={false} />
        <AuthPageContent>{content}</AuthPageContent>
      </SdkLayout>
    )
  }

  renderReset() {
    const { requiredUserLogin } = this.props
    const { email } = this.state

    const resetEnabled = requiredUserLogin || email.trim()

    return this.renderLayout(
      <FooterButton
        label="Reset"
        disabled={!resetEnabled}
        onClick={this.handleSubmit}
      />,
      <div>
        {requiredUserLogin ? (
          <p>
            Click the button below to reset the password for{" "}
            <b>{requiredUserLogin}</b>.
          </p>
        ) : (
          <p>
            Enter the email address you use to log in to <b>IFTTT</b> to reset
            your password.
          </p>
        )}

        <Form onSubmit={this.handleSubmit}>
          {!requiredUserLogin && (
            <FormField
              name="email"
              label="Email"
              onChange={this.handleChangeEmail}
              value={email}
            />
          )}
        </Form>
      </div>
    )
  }

  renderResetSent() {
    const { signInUrl } = this.props

    return this.renderLayout(
      <FooterLink label="Log in to IFTTT" href={signInUrl} />,
      <p>
        We emailed you some instructions so that you can choose a new password.
      </p>
    )
  }

  render() {
    if (this.state.resetSent) {
      return this.renderResetSent()
    } else {
      return this.renderReset()
    }
  }
}

ResetPasswordPage.propTypes = {
  apiResetPasswordUrl: PropTypes.string.isRequired,
  requiredUserLogin: PropTypes.string,
  services: WorksWith.propTypes.services,
  suggestedEmail: PropTypes.string,
  signInUrl: PropTypes.string.isRequired,
}
