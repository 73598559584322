import { MainImage } from "./index"
import "./hello_interstitial.scss"

interface Props {
  clickAccept: () => void
  clickAcceptUrl: string
  ctaTitle: string
  declineCopy: string
  logoHtml: string
  mainBody: string
  mainTitle: string
  onClickDecline: () => void
}

const HelloInterstitial = ({
  clickAccept,
  clickAcceptUrl,
  ctaTitle,
  declineCopy,
  logoHtml,
  mainBody,
  mainTitle,
  onClickDecline,
}: Props) => {
  const clickAcceptHandler = event => {
    if (clickAccept) {
      event.preventDefault()
      clickAccept()
    }
  }

  return (
    <div styleName="base">
      <div styleName="popup-container" onClick={onClickDecline}>
        <div styleName="popup-card" onClick={e => e.stopPropagation()}>
          <a href={clickAcceptUrl} onClick={clickAcceptHandler}>
            <div styleName="sla-img-container">
              <MainImage image={logoHtml} />
            </div>
          </a>
          <div styleName="right">
            <a href={clickAcceptUrl} onClick={clickAcceptHandler}>
              <div styleName="copy">
                <h2>{mainTitle}</h2>
                <p>{mainBody}</p>
              </div>
            </a>
            {ctaTitle && (
              <div className="action-buttons-container">
                <a role="button" href={clickAcceptUrl} onClick={clickAcceptHandler} className="button-tertiary">
                  {ctaTitle}
                </a>
                <button onClick={onClickDecline} className="button-tertiary button-cancel-link">
                  {declineCopy}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelloInterstitial
