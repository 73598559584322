import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import GrowingTextArea from "shared/growing_text_area"

import "./title.scss"
import { updateApplet } from "./api"

const Title = ({ applet, includeEditableTitle }) => {
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState(applet.name)
  const inputRef = useRef(null)
  const maxLength = 140

  const onSave = async () => {
    setEditing(false)
    const { errors } = await updateApplet({ appletId: applet.id, name })
    if (errors?.length) {
      setName(applet.name)
    }
  }

  const startEditMode = () => {
    if (!includeEditableTitle) {
      return
    }

    setEditing(true)
    inputRef.current.focus(true) // unhide the textarea inline before focusing, so it's a direct user action
    inputRef.current.setCursorPosition(name.length)
    inputRef.current.selectionEnd = name.length
  }

  useEffect(() => {
    document.dispatchEvent(new Event("header_title_rendered"))
  })

  const canSave = name.length > 0 && name.length <= maxLength

  return (
    <>
      <div styleName={classNames("title-editor", { editing })}>
        <GrowingTextArea
          maxLength={maxLength}
          className="connection-title"
          ref={inputRef}
          value={name}
          onChange={value => setName(value)}
          onBlur={onSave}
          name="name"
          hideAtStart
        />
      </div>
      <h1
        className={classNames("connection-title", {
          dynamic: applet.config_type === "dynamic",
        })}
        styleName={classNames("connection-title", {
          editable: includeEditableTitle,
          editing,
        })}
        onClick={startEditMode}
      >
        {name}
      </h1>
      <div styleName="edit-details">
        {editing ? (
          <div styleName="name-length">
            {name.length}/{maxLength}
          </div>
        ) : (
          includeEditableTitle && <div />
        )}
        {editing ? (
          <button disabled={!canSave} onClick={onSave}>
            Save
          </button>
        ) : (
          includeEditableTitle && <button onClick={startEditMode}>Edit title</button>
        )}
      </div>
    </>
  )
}

Title.propTypes = {
  applet: PropTypes.object.isRequired,
  includeEditableTitle: PropTypes.bool.isRequired,
}
export default Title
