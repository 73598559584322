import { useEffect, useState } from "react"
import qs from "qs"
import HelloCard from "shared/components/hello_card"
import HelloInterstitial from "shared/components/hello_card/hello_interstitial"
import debounceMatchMedia from "shared/lib/debounce_match_media"
import { Service } from "types"

const minModalWidth = 850
const timeout = process.env.NODE_ENV === "test" ? null : 2500 // necessary to pass index.test.tsx specs

interface Props {
  connectingToAssets: {
    desktop: string
    responsive: string
  }
  hideModal: () => void
  progressBarSteps: Array<string>
  service: Service
  urls: {
    bulkChannelBaseUrl: string
    returnToUrl: string
  }
}

const ConnectingToService = ({ connectingToAssets, hideModal, urls, service, progressBarSteps }: Props) => {
  const [isDesktop, setDesktop] = useState(window.matchMedia(`(min-width: ${minModalWidth}px)`)?.matches)

  useEffect(debounceMatchMedia.bind(null, minModalWidth, setDesktop), [])

  const ResponsiveServiceActivation = isDesktop ? HelloInterstitial : HelloCard

  useEffect((): void => {
    const queryPart = qs.stringify(
      {
        c: [service.module_name],
        return_to: `${urls.returnToUrl}?service=${service.module_name}&progress=${progressBarSteps.length}`,
        live_channel_id: "",
      },
      { arrayFormat: "brackets" }
    )

    const connect = () => {
      hideModal()
      window.location.assign(`${urls.bulkChannelBaseUrl}?${queryPart}`)
    }

    timeout ? setTimeout(connect, 2500) : connect()
  }, [])

  return (
    <ResponsiveServiceActivation
      containerStyle="covering"
      mainTitle={`Connecting to ${service?.name}`}
      mainBody={`IFTTT partners securely with ${service?.name} to power your Applets`}
      logoHtml={isDesktop ? connectingToAssets.desktop : connectingToAssets.responsive}
      onboardingStyle={"onboarding"}
    />
  )
}

export default ConnectingToService
