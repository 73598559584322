import PropTypes from "prop-types"
import classnames from "classnames"
import { useRef, useEffect } from "react"

import IFTTTNextSearchInput from "app/components/ifttt_next/shared/search_input"

export default function SearchInput({
  onChange,
  clearSearch,
  searchTerm,
  placeholder,
}) {
  const inputRef = useRef(null)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const classes = classnames("ifttt-next-search", {
    search: searchTerm !== "",
  })

  return (
    <div className={classes}>
      <IFTTTNextSearchInput
        ref={inputRef}
        autoFocus={true}
        onChange={onChange}
        onXClick={clearSearch}
        placeholder={placeholder}
        value={searchTerm}
      />
    </div>
  )
}

SearchInput.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}
