import { Component } from "react"
import PropTypes from "prop-types"

import AppletStoredFieldsForm from "app/components/applet_stored_fields_form"
import { post } from "shared/lib/api"

import AppletHeader from "./applet_header"
import SdkLayout from "./sdk_layout"
import FooterButton from "./footer_button"
import CompleteIcon from "shared/components/icons/complete_icon"

import "./configure_page.scss"

export default class ConfigurePage extends Component {
  state = {
    isFormEnabled: false,
    isEnabling: false,
    saveSuccess: false,
    brandColor:
      this.props.appletStatus === "disabled_for_user"
        ? "#999"
        : this.props.brandColor,
    isAppletEnabled:
      this.props.appletStatus === "never_enabled_for_user"
        ? null
        : this.props.appletStatus === "enabled_for_user",
  }

  /**
   * If the applet is currently enabled or they're going through the flow for
   * the first time, clicking the button should save and enable the applet.
   * Otherwise, it should just leave the page.
   */
  isAppletSaveable() {
    const { appletStatus } = this.props
    const { isAppletEnabled } = this.state

    return isAppletEnabled || appletStatus === "never_enabled_for_user"
  }

  handleClickSave = () => {
    if (this.isAppletSaveable()) {
      this.storedFieldsForm.onSubmit(null, this.handleSaveSuccess)
    } else {
      window.location.assign(this.props.redirectUri)
    }
  }

  handleSaveSuccess = () => {
    this.setState({ saveSuccess: true })
    setTimeout(() => {
      window.location.assign(this.props.redirectUri)
    }, 1000)
  }

  toggleApplet = () => {
    if (this.state.isEnabling) return false

    const { disableUrl, enableUrl, brandColor } = this.props
    const { isAppletEnabled } = this.state

    this.setState({
      isEnabling: true,
      brandColor: isAppletEnabled ? "#999" : brandColor,
      isAppletEnabled: !isAppletEnabled,
    })

    post(isAppletEnabled ? disableUrl : enableUrl, {})
      .then(this.handleToggleSuccess)
      .catch(this.handleToggleError.bind(null, isAppletEnabled))
  }

  handleToggleSuccess = () => {
    this.setState({ isEnabling: false })
  }

  handleToggleError = wasAppletEnabled => {
    this.setState({
      isEnabling: false,
      isAppletEnabled: wasAppletEnabled,
    })

    if (wasAppletEnabled) {
      window.appendFlash("Disable failed", "danger")
    } else {
      window.appendFlash("Enable failed", "danger")
    }
  }

  render() {
    const {
      appletStatus,
      description,
      iconUrl,
      name,
      storedFieldsFormProps,
    } = this.props

    const { brandColor, isAppletEnabled, saveSuccess } = this.state

    const footerLabel = this.isAppletSaveable() ? "Save" : "Back"

    return saveSuccess ? (
      <SdkLayout backgroundColor={brandColor}>
        <div styleName="success-container">
          <div styleName="success-circle">
            <CompleteIcon color="white" animate large />
          </div>
        </div>
      </SdkLayout>
    ) : (
      <SdkLayout
        backgroundColor={brandColor}
        footer={
          <FooterButton
            label={footerLabel}
            backgroundColor={brandColor == "#000000" ? "lighten" : "darken"}
            onClick={this.handleClickSave}
          />
        }
      >
        <AppletHeader
          description={description}
          iconUrl={iconUrl}
          name={name}
          enabled={isAppletEnabled}
          onChange={this.toggleApplet}
        />
        <div className="sdk-configure-page-content">
          <AppletStoredFieldsForm
            ref={r => (this.storedFieldsForm = r)}
            includeDescriptions={false}
            includeFooter={false}
            useInlineErrors={true}
            showSuccessMessage={false}
            {...storedFieldsFormProps}
          />
        </div>
      </SdkLayout>
    )
  }
}

ConfigurePage.propTypes = {
  brandColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disableUrl: PropTypes.string.isRequired,
  enableUrl: PropTypes.string.isRequired,
  appletStatus: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  storedFieldsFormProps: PropTypes.shape(AppletStoredFieldsForm.propTypes)
    .isRequired,
}
