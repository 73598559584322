import PlusIcon from "shared/components/icons/plus_icon"
import { verifyIntendedPromiseRejection } from "./utils/modal_promise"
import classNames from "classnames"

import "./diy_add_tanda_button.scss"

interface Props {
  hasProBadge: boolean
  allowNewBadge: string
  anchorTitle: string
  onClick: () => void
}

export default function DIYAddTandaButton({ hasProBadge, allowNewBadge, anchorTitle, onClick }: Props) {
  const classes = classNames("plus-icon-button", { "button-with-badge-pro": hasProBadge })
  return (
    <a
      className={classes}
      styleName="add"
      onClick={verifyIntendedPromiseRejection(onClick)}
      title={anchorTitle}
      data-allow-new-badge={allowNewBadge}
    >
      <PlusIcon />
    </a>
  )
}
