import classNames from "classnames"
import "./header.scss"

interface Props {
  page: string
  progressBarSteps: Array<string>
}
export const ProgressBar = ({ page, progressBarSteps }: Props) => {
  return (
    <div styleName="progress-bar-container">
      {progressBarSteps.map((e, i) => (
        <div styleName={classNames("dot", { active: e === page })} key={i}></div>
      ))}
    </div>
  )
}
