import purify from "dompurify"

export default function displayFlash(text, type) {
  let $flash = document.querySelector(".flash-msg")

  if (!$flash) {
    $flash = document.createElement("div")
    document.body.appendChild($flash)
  } else if ($flash.dataset.timeout) {
    clearTimeout($flash.dataset.timeout)
  }

  $flash.className = `flash-msg fadein ${type ? type : "alert"}`
  $flash.innerText = purify.sanitize(text)

  $flash.dataset.timeout = setTimeout(() => {
    document.querySelectorAll(".flash-msg:not(.sticky)").forEach(el => {
      el.classList.remove("fadein")
      el.classList.add("fadeout")
      setTimeout(() => {
        el.style.display = "none"
        el.parentElement.removeChild(el)
      }, 500)
    })
  }, 4000)
}
