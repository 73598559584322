import { Children } from "react"
import PropTypes from "prop-types"
import { TransitionGroup, CSSTransition } from "react-transition-group"

export default function FadeIn({ children, fade, delay }) {
  if (fade) {
    // Ensure that we're using an Array without undefined items
    const items = Children.toArray(children)
      .filter(n => n)
      .map((item, i) => (
        <CSSTransition
          key={i}
          appear={!!delay}
          timeout={{ enter: fade, exit: 200, appear: fade + delay }}
          classNames={`delayed${delay}FadeIn${fade}`}
        >
          {item}
        </CSSTransition>
      ))

    return <TransitionGroup>{items}</TransitionGroup>
  } else {
    return <div>{children}</div>
  }
}

FadeIn.propTypes = {
  children: PropTypes.node.isRequired,
  fade: PropTypes.number,
  delay: PropTypes.number,
}

FadeIn.defaultProps = {
  fade: 200,
  delay: 0,
}
