import { useState, useEffect } from "react"

import OnboardingTile from "./onboarding_tile"
import Header from "./header"
import DownloadModal from "./download_modal"

import android from "images/components/new_user_intake/android.svg"
import apple from "images/icons/apple.svg"
import neither from "images/icons/neither.svg"

import "./index.scss"
import { submitPreferredPlatforms } from "./api"

interface Props {
  onPlatformSelected: () => void
  browserPlatform: string
  onClickBack: () => void
  previouslySelectedPlatforms: string | null
  progressBarSteps: Array<string>
  userIsOnboarding: boolean
}

const editPlatforms = (selectedPlatforms: Set<string>, platformName: string): Set<string> => {
  const platforms = new Set(selectedPlatforms)

  if (platforms.has(platformName)) {
    platforms.delete(platformName) // deselect platform
  } else if (platformName === "neither") {
    platforms.clear()
    platforms.add(platformName)
  } else if (["android", "iphone"].includes(platformName)) {
    platforms.delete("neither") // delete if previously selected
    platforms.add(platformName)
  } else {
    platforms.add(platformName)
  }

  return platforms
}

const PlatformSelection = ({
  browserPlatform,
  onClickBack,
  onPlatformSelected,
  previouslySelectedPlatforms,
  progressBarSteps,
  userIsOnboarding,
}: Props) => {
  const platforms = [
    {
      name: "Android",
      lowercaseName: "android",
      icon: android,
    },
    {
      name: "iPhone",
      lowercaseName: "iphone",
      icon: apple,
    },
    {
      name: "Neither",
      lowercaseName: "neither",
      icon: neither,
    },
  ]

  const [showDownload, setShowDownload] = useState(false)
  const [downloadPlatform, setDownloadPlatform] = useState("")
  const [selectedPlatforms, setSelectedPlatforms] = useState(new Set<string>())
  const hideBackButton = userIsOnboarding && browserPlatform === "desktop"

  useEffect(() => {
    if (previouslySelectedPlatforms) {
      const platforms = previouslySelectedPlatforms.split(",")
      setSelectedPlatforms(new Set(platforms))
    }
  }, [])

  const onSelect = (platformName: string): void => {
    const platforms = editPlatforms(selectedPlatforms, platformName)
    setSelectedPlatforms(platforms)
  }

  const onContinue = async (): Promise<void> => {
    await submitPreferredPlatforms(Array.from(selectedPlatforms as Set<string>))
    onPlatformSelected()
  }

  const showAppDownload = (platform: string) => {
    setDownloadPlatform(platform)
    setShowDownload(true)
  }

  return (
    <div styleName="platform-selection-view">
      <Header
        hideBackButton={hideBackButton}
        onClickBack={onClickBack}
        page="platforms"
        progressBarSteps={progressBarSteps}
        title="Which mobile device do you primarily use?"
      />
      <div styleName="container platform-selection">
        <div>
          <p className="txt-body-3">This is important and helps us find you the best Applets!</p>
          <ul styleName="platform-list">
            {platforms.map(p => (
              <li key={p.name}>
                <OnboardingTile
                  onClick={() => onSelect(p.lowercaseName)}
                  onboardingItem={p}
                  selected={selectedPlatforms.has(p.lowercaseName)}
                  isPlatformSelect
                />
                {p.lowercaseName !== "neither" && selectedPlatforms.has(p.lowercaseName) && (
                  <a
                    className="link-standalone"
                    styleName="download-link"
                    role="button"
                    onClick={() => showAppDownload(p.lowercaseName)}
                  >
                    Download App
                  </a>
                )}
              </li>
            ))}
          </ul>
          <p className="txt-body-3" styleName="options">
            You can pick more than one option.
          </p>
        </div>
      </div>
      {selectedPlatforms.size > 0 && (
        <div styleName="platform-selection-continue">
          <button onClick={onContinue} className="button-primary">
            Continue
          </button>
        </div>
      )}
      {
        <DownloadModal
          downloadPlatform={downloadPlatform}
          showDownload={showDownload}
          setShowDownload={setShowDownload}
        />
      }
    </div>
  )
}

export default PlatformSelection
