import { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useElements, useStripe } from "@stripe/react-stripe-js"
import parse from "html-react-parser"

import CreditCardField from "shared/components/credit_card_field/index"

import EncryptionLockIcon from "images/shared/encryption_lock_icon.svg"

import { userShape } from "app/lib/shared_prop_types"
import { patch } from "shared/lib/api"

import "../payment_info_form.scss"

export default function CreditCardForm({ user, updatePaymentInfoPath }) {
  const cardRef = useRef(null)
  const stripe = useStripe()
  const elements = useElements()
  const [handlingSubmit, setHandlingSubmit] = useState(false)
  const [cardReady, setCardReady] = useState(false)
  const [lastError, setLastError] = useState({})
  const [cardElement, setCardElement] = useState({})

  const handleError = (error = "There was a problem submitting your payment.") => {
    setHandlingSubmit(false)
    setLastError(error)
  }

  const clearCardEl = () => {
    stripe && elements && elements.getElement(cardRef.current.getElement()).clear()
  }

  useEffect(() => {
    lastError.cardnumber && lastError.cardnumber.length && cardElement.clear()
  }, [lastError, cardElement])

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    setHandlingSubmit(true)

    setCardElement(elements.getElement(cardRef.current.getElement()))

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(cardRef.current.getElement()),
      billing_details: {
        email: user.email,
      },
    })

    await stripePaymentMethodHandler(error, paymentMethod)
  }

  const stripePaymentMethodHandler = async (error, paymentMethod) => {
    if (error) {
      console.error(error)
      // Show error in payment form
      handleError(error)
      window.appendFlash(error.message, "error")
      return
    }

    if (!paymentMethod?.id) {
      handleError("There was a problem submitting your payment. Try again or contact us for help.", "danger")
      return
    }

    patch(updatePaymentInfoPath, paymentMethod)
      .then(data => {
        if (data.redirect_to) {
          window.location.assign(data.redirect_to)
        } else {
          window.appendFlash("Payment info updated!", "success")
        }
        setHandlingSubmit(false)
        clearCardEl()
      })
      .catch(response => {
        setHandlingSubmit(false)
        handleError(response?.data?.error)
        clearCardEl()
        window.appendFlash("Payment info failed. Please contact us to get help.", "danger")
        window.App.loadZendeskWidget()
      })
  }

  return (
    <form className="form" styleName="payment-info-form update-payment" onSubmit={handleSubmit}>
      <div styleName="stripe-payment-update-container">
        <div className="header with-icon">
          <span className="icon">{parse(EncryptionLockIcon)}</span>
          Your transaction is secure
        </div>
        <section>
          <CreditCardField ref={cardRef} onCardReadyChange={setCardReady} errors={lastError} />
        </section>
        <section styleName="action-buttons" className="mt4 mt2--phone">
          <span>
            <button
              type="submit"
              disabled={!cardReady || handlingSubmit}
              className="button-secondary"
              title="Update payment"
              aria-label="Update payment"
            >
              Update payment
            </button>
          </span>
        </section>
      </div>
    </form>
  )
}

CreditCardForm.propTypes = {
  user: userShape,
  updatePaymentInfoPath: PropTypes.string,
}
