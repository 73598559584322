// stepSelector receives `selectedSteps` as a way to avoid select same step again.
// It was the rule for queries and actions. Now, Applets can have multi same-action
export const selectedActionsAboveLimit = actionsSelected => {
  const maxNumberOfSameAction = 20
  const counts = {}

  actionsSelected.forEach(action => {
    counts[action.full_module_name] = (counts[action.full_module_name] || 0) + 1
  })

  return Object.entries(counts)
    .map(([full_module_name, quantity]) => {
      if (quantity >= maxNumberOfSameAction) {
        return actionsSelected.find(
          a => a.full_module_name === full_module_name
        )
      }
    })
    .filter(i => i) // Remove undefined
}
