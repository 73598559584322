import { useState } from "react"

import "./index.scss"
import CancelConfirmation from "./cancel_confirmation.tsx"
import CustomerSupport from "./customer_support.tsx"
import ExclusiveOffer from "./exclusive_offer.tsx"
import Header from "./header.tsx"
import ProFeatureCheck from "./pro_feature_check.tsx"
import Survey from "./survey.tsx"
import SwitchYourPlan from "./switch_your_plan.tsx"

import { post } from "shared/lib/api"

import { captureMessage } from "@sentry/react"

interface Assets {
  contactSupport: string
  exclusiveOffer: string
  iftttSvgUrl: string
  switchPlan: string
}

interface UserSubscription {
  hasDiscount: boolean
  paymentInterval: string
  tier: string
}

interface Props {
  assets: Assets
  userSubscription: UserSubscription
  expirationDate: string
  maxFreeApplets: string
  proPlusProductId: string
  proProductId: string
  urls: {
    billing: string
    claimOffer: string
    explore: string
    processProCancellation: string
  }
}

const BillingCancelContainer = (props: Props) => {
  const [surveyOption, selectSurveryOption] = useState("")
  const [view, updateView] = useState("initial")

  const tierName = props.userSubscription.tier === "pro" ? "Pro+" : "Pro"

  const trackMutationError = (mutationName, error, userFeedback, updateLoadingState) => {
    updateLoadingState?.(false)
    if (window.App.allowDebugErrorMessages) console.warn(error)
    captureMessage(`${mutationName} failed`, { extra: error })
    window.appendFlash(userFeedback, "danger")
  }

  const handleCancellation = updateLoading => {
    updateLoading?.(true)
    post(props.urls.processProCancellation, { survey_option: surveyOption }).then(data => {
      if (data.ok) {
        updateView("plan_cancelled")
      } else {
        trackMutationError(
          "userSubscriptionCancellation",
          data.error,
          "There was a problem cancelling your subscription. Please contact support for more information.",
          updateLoading
        )
      }
    })
  }

  const onBackClick = () => {
    switch (view) {
      case "initial":
      case "plan_cancelled":
        window.App.Scripts.BackButton("/billing")
        break
      case "survey":
        selectSurveryOption("")
        updateView("initial")
        break
      case "exclusive_offer":
      case "switch_your_plan":
      case "technical_issues":
        updateView("survey")
        break
    }
  }

  const choseView = () => {
    switch (view) {
      case "initial":
        return (
          <ProFeatureCheck
            billingUrl={props.urls.billing}
            expirationDate={props.expirationDate}
            maxFreeApplets={props.maxFreeApplets}
            moveNextView={() => updateView("survey")}
            tierName={tierName}
          />
        )
      case "survey":
        return (
          <Survey
            billingUrl={props.urls.billing}
            selectSurveryOption={selectSurveryOption}
            moveNextView={updateView}
            surveyOption={surveyOption}
            tierName={tierName}
          />
        )
      case "customer_support":
        return <CustomerSupport asset={props.assets.contactSupport} handleCancellation={handleCancellation} />
      case "exclusive_offer":
        return (
          <ExclusiveOffer
            asset={props.assets.exclusiveOffer}
            claimOfferUrl={props.urls.claimOffer}
            exploreUrl={props.urls.explore}
            handleCancellation={handleCancellation}
            trackMutationError={trackMutationError}
            userSubscription={props.userSubscription}
          />
        )
      case "switch_your_plan":
        return (
          <SwitchYourPlan
            assets={props.assets.switchPlan}
            exploreUrl={props.urls.explore}
            handleCancellation={handleCancellation}
            productId={tierName === "Pro+" ? props.proProductId : props.proPlusProductId}
            tierName={tierName}
            trackMutationError={trackMutationError}
          />
        )
      case "plan_cancelled":
        return (
          <CancelConfirmation
            expirationDate={props.expirationDate}
            exploreUrl={props.urls.explore}
            maxFreeApplets={props.maxFreeApplets}
            tierName={tierName}
          />
        )
    }
  }

  return (
    <section styleName="cancel_plan_container">
      <Header logo={props.assets.iftttSvgUrl} onBackClick={onBackClick} />
      {choseView()}
    </section>
  )
}

export default BillingCancelContainer
