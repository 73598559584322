import "./utils/initialize_highcharts"
import { applePost, facebookSSO } from "shared/sso"
import SignUpAndPurchase from "app/components/subscriptions/sign_up_and_purchase"
import Success from "app/components/subscriptions/success"
import UpdatePaymentInfo from "app/components/subscriptions/update_payment_info/index"
import SetupActivityFeed from "app/scripts/setup_activity_feed"
import InitializeFacebook from "./scripts/initialize_facebook"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import TFASendPin from "app/scripts/tfa_send_pin"
import AdminLayover from "app/scripts/admin_layover"
import SetupDeleteAccountSurvey from "app/scripts/setup_delete_account_survey"
import HandleVerifyDeleteAccountByEmail from "app/scripts/handle_verify_delete_account_by_email"
import UpdateParam from "app/scripts/update_param"

import handleRecaptcha from "shared/handle_recaptcha"
import AlreadySignedInPage from "app/components/sdk/already_signed_in_page"
import ConfigurePage from "app/components/sdk/configure_page"
import ConnectedServicePage from "app/components/sdk/connected_service_page"
import LandingPage from "app/components/sdk/landing_page"
import RefreshFormAuthenticityToken from "app/scripts/refresh_form_authenticity_token"
import ResetPasswordPage from "app/components/sdk/reset_password_page"
import SignInPage from "app/components/sdk/sign_in_page"
import SignUpPage from "app/components/sdk/sign_up_page"
import UnifiedProfileDropdown from "shared/components/unified_profile_dropdown"

import ActivateServiceWithPin from "app/components/activate_service/activate_service_with_pin"
import ActivateServiceWithToken from "app/components/activate_service/activate_service_with_token"

// Generic Helper components
import GrowingTextArea from "shared/growing_text_area"
import LimitedTextInput from "shared/limited_text_input"
import PasswordUnmasking from "shared/scripts/password_un_masking"

// Page Specific Components
import ActivitySteps from "app/components/activity_steps"
import SLAPrompt from "shared/components/sla_prompt"

// Stored Fields
import SelectField from "shared/components/stored_fields/select_field/index"
import LocationField from "shared/components/stored_fields/location_field/index"
import DoubleSelectField from "shared/components/stored_fields/double_select_field/index"
import IngredientTextField from "shared/components/stored_fields/ingredient_text_field/index"
import TimeOfDayField from "shared/components/stored_fields/time_of_day_field"
import MinutesPastHourField from "shared/components/stored_fields/minutes_past_hour_field"
import DateAndTimeField from "shared/components/stored_fields/date_and_time_field"

// Connect
import Container from "app/components/connect_url"
import ConnectSDKAppletConfig from "app/components/connect_sdk_applet/config"
import ConnectionCard from "shared/components/connection_card"
import ConnectionConfig from "shared/components/connection_card/config"
import ConnectButton from "shared/components/connection_card/connect_button"
import SettingsButton from "shared/components/connection_card/settings_button"
import ShareButton from "shared/components/share_button"
import AuthorInfo from "shared/components/connection_card/author_info"
import ConnectionCardTitle from "shared/components/connection_card/title"
import ReturningUserFlow from "app/components/connect_sdk_applet/returning_user_flow"
import TransitionAndRedirect from "app/components/connect_sdk_applet/returning_user_flow/transition_and_redirect"

// IFTTT Next
import DIY from "app/components/diy_composer"
import ConnectView from "app/components/ifttt_next/connect/index"
import MyServiceSettingsView from "app/components/ifttt_next/services/my_service_settings_view/index"
import GetMore from "app/components/ifttt_next/get_more/index"
import Profile from "app/components/ifttt_next/profile/index"
import IFTTTNextStory from "app/components/ifttt_next/stories/index"
import { DiscoverServiceView } from "app/components/ifttt_next/services/discover_service_view"
import GatedServiceAuthButton from "app/components/gated_service_auth_button"
import TQAHeader from "app/components/ifttt_next/services/tqa/header"
import TQAInfo from "app/components/ifttt_next/services/tqa/info"
import LearnMoreButton from "shared/components/learn_more_button"
import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"
import ShareableAppletCard from "shared/components/applet_card/ifttt_next/shareable_applet_card"
import MyApplets from "app/components/ifttt_next/my_applets/index"
import ArchiveApplets from "app/components/ifttt_next/archive/index"

import NewUserIntake from "app/components/new_user_intake"
import OnboardingExclusiveOffer from "app/components/new_user_intake/exclusive_offer"

import BillingSwitchPlanLink from "app/components/billing/switch_plan/index.tsx"
import BillingCancelContainer from "app/components/billing/cancel/index.tsx"
import PromotionContainer from "app/components/billing/cancel/promotion/index.tsx"
import ReferralCode from "app/components/referral/index.tsx"
import Rewards from "app/components/referral/rewards/index.tsx"
import ReceiptSettings from "app/components/billing/receipt_settings"

import FeedbackPrompt from "app/components/feedback_prompt/index.tsx"
import UserGroups from "app/components/user_groups/index.tsx"
import InstallAppToAcceptInvite from "app/components/user_groups/install_app_to_accept_invite"

import setupInviteMember from "app/components/group_location/create_and_invite_members"
import ToggleHelpWidgetVisibility from "shared/scripts/toggle_help_widget_visibility"

import ProsumerHome from "app/components/prosumer_home"
import PlanUpgradeButton from "app/components/plan_upgrade_button"

Object.assign(window.App.Comps, {
  // React components
  ActivateServiceWithPin,
  ActivateServiceWithToken,
  ActivitySteps,
  ArchiveApplets,
  BillingCancelContainer,
  PromotionContainer,
  ReferralCode,
  Rewards,
  BillingSwitchPlanLink,
  ConnectView,
  DateAndTimeField,
  DiscoverServiceView,
  DoubleSelectField,
  FeedbackPrompt,
  GatedServiceAuthButton,
  GetMore,
  GrowingTextArea,
  IFTTTNextStory,
  IngredientTextField,
  LearnMoreButton,
  LimitedTextInput,
  LocationField,
  MinutesPastHourField,
  MyApplets,
  MyServiceSettingsView,
  NewUserIntake,
  OnboardingExclusiveOffer,
  Profile,
  ReceiptSettings,
  SLAPrompt,
  SelectField,
  SmallAppletCard,
  ShareButton,
  ShareableAppletCard,
  TQAHeader,
  TQAInfo,
  TimeOfDayField,
  UnifiedProfileDropdown,
  DIY,
  PlanUpgradeButton,
  Subscriptions: {
    Success,
    SignUpAndPurchase,
    UpdatePaymentInfo,
  },
  Sdk: {
    AlreadySignedInPage,
    ConfigurePage,
    ConnectedServicePage,
    LandingPage,
    ResetPasswordPage,
    SignInPage,
    SignUpPage,
  },
  Access: {
    ConnectSDKAppletConfig,
    ReturningUserFlow,
    TransitionAndRedirect,
  },
  ConnectionCard: {
    ConnectionCard,
    ConnectionConfig,
    ConnectButton,
    SettingsButton,
    AuthorInfo,
    Title: ConnectionCardTitle,
  },
  ConnectURL: {
    Container,
  },
  UserGroups,
  InstallAppToAcceptInvite,
})

// Per-page functions to setup non-react behavior
Object.assign(window.App.Scripts, {
  AdminLayover,
  applePost,
  facebookSSO,
  handleRecaptcha,
  HandleVerifyDeleteAccountByEmail,
  InitializeFacebook,
  PasswordUnmasking,
  ProsumerHome,
  RefreshFormAuthenticityToken,
  SetupActivityFeed,
  SetupDeleteAccountSurvey,
  setupInviteMember,
  SetupUnifiedHeaderDropdown,
  SetupUnifiedMobileMenu,
  TFASendPin,
  ToggleHelpWidgetVisibility,
  UpdateParam,
})
