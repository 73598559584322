import "./service_brand.scss"
import parse from "html-react-parser"

interface Props {
  description?: string
  image: string
  title: string
}

export default function ServiceBrand({ description, image, title }: Props) {
  return (
    <>
      <img title={title} alt={title} styleName="service-brand" width="120" height="120" src={image} />
      <h1 styleName="brand-title">{parse(title)}</h1>
      {description && <p styleName="brand-description">{description}</p>}
    </>
  )
}
