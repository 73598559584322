import React, { useState } from "react"
import { capitalize } from "humanize-plus"
import classnames from "classnames"
import displayFlash from "partners/scripts/display_flash"
import { sendPin, activateWithPin } from "./api"
import ErrorIcon from "images/icons/icon-error-triangle.svg"
import CheckIcon from "images/components/check2.svg"
import { verifyPhoneNumber } from "shared/lib/verify_phone_number"
import { verifyEmailAddress } from "shared/lib/verify_email_address"
import parse from "html-react-parser"

import "./activation.scss"

interface Props {
  service: {
    name: string
    module_name: string
  }
  liveChannels: {
    [key: string]: string
  }[]
  serviceUrl: string
}

const ActivateServiceWithPin = ({ service, liveChannels, serviceUrl }: Props) => {
  let channelField = "phone_number"
  if (service.module_name.match(/email/)) channelField = "email_address"

  const fieldName = channelField.replace(/_/, " ")
  const fieldValue = liveChannels[0] ? liveChannels[0][channelField] : ""

  const [identifier, setIdentifier] = useState<string>(fieldValue || "")
  const [pinSent, setPinSent] = useState<boolean>(false)
  const [pinRetried, setPinRetried] = useState<boolean>(false)
  const [activationSent, setActivationSent] = useState<boolean>(false)
  const [pin, setPin] = useState<string>("")
  const [error, setError] = useState<string>("")

  const handleIdentifierChange = (e: Event) => {
    const identifier = (e.target as HTMLInputElement)?.value
    setError("")
    setIdentifier(identifier)
  }

  const handlePinChange = (e: Event) => {
    const pin = (e.target as HTMLInputElement).value
    setPin(pin)
  }

  const handleSendPin = async (e: Event) => {
    e.preventDefault()

    if (channelField === "email_address") {
      if (!verifyEmailAddress(identifier)) {
        setError("Invalid email address")
        return
      }
    } else {
      if (!verifyPhoneNumber(identifier)) {
        setError("Invalid phone number")
        return
      }
    }

    await sendPin(service.module_name, identifier)
    setPinSent(true)
  }

  const handleRetry = () => {
    if (pinRetried) return

    setPinRetried(true)
    setActivationSent(false)
    setError("")
  }

  const handlePinSubmit = async (e: Event) => {
    e.preventDefault()
    setActivationSent(true)
    try {
      const response = await activateWithPin(service.module_name, identifier, pin)
      if (response.newPinActivateLiveChannel.status === "successful") {
        displayFlash("Service connected successfully!")
        window.location.assign(serviceUrl)
      } else {
        // expected to be invalid pin message
        setError(response.newPinActivateLiveChannel.errors[0].message)
        setActivationSent(false)
      }
    } catch (e) {
      setError(e.details || "Unexpected failure to activate")
    }
  }

  return (
    <div styleName="activation">
      <div styleName="service-fields">
        <h1>Connect {service.name}</h1>
        <div styleName="service-activation-form">
          <form className="new_live_channel" id="new_live_channel" onSubmit={pinSent ? handlePinSubmit : handleSendPin}>
            <div styleName="live-channel-fields">
              <p>
                Enter the {fieldName} you would like to use for all of your {service.name} Applets.
              </p>
              <label htmlFor="channel-field">{capitalize(fieldName)}</label>
              <input
                id="channel-field"
                styleName={classnames({ "after-send": pinSent && !pin })}
                type={channelField === "email_address" ? "email" : "tel"}
                value={identifier}
                placeholder={channelField === "email_address" ? "someone@example.com" : "1 555 555 5555"}
                onChange={handleIdentifierChange}
                required
              />
              {error && (
                <div styleName="after-message submit">
                  <span>
                    {parse(ErrorIcon)}
                  </span>
                  {error}
                </div>
              )}
              {pinSent && !pin && !error && (
                <div styleName="after-message send">
                  <span>
                    {parse(CheckIcon)}
                  </span>
                  Pin sent to your {fieldName}
                </div>
              )}
              {pinSent ? (
                <>
                  <div styleName={classnames("pin-entry", { error: !!error })}>
                    <label htmlFor="pin">Please enter the PIN you received below</label>
                    <input
                      type="text"
                      name="pin"
                      id="pin"
                      value={pin}
                      onChange={handlePinChange}
                      placeholder="4-digit PIN"
                    />
                  </div>
                  {error && (
                    <div styleName="after-message submit">
                      <span>
                        {parse(ErrorIcon)}
                      </span>
                      Invalid PIN
                    </div>
                  )}
                  <div styleName="horizontal-ctas">
                    <button
                      className="link-inline"
                      styleName={classnames({ disabled: pinRetried })}
                      type="button"
                      onClick={handleRetry}
                    >
                      {pinRetried ? "PIN Resent" : "Retry"}
                    </button>
                    <input
                      className="button-tertiary"
                      type="submit"
                      name="commit"
                      value="Connect"
                      disabled={activationSent || pin.length !== 4}
                      onSubmit={handlePinSubmit}
                    />
                  </div>
                </>
              ) : (
                <div styleName="send-pin">
                  <button className="button-secondary" onClick={handleSendPin}>
                    Send PIN
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ActivateServiceWithPin
