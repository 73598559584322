import "./upgrade_banner.scss"

interface Props {
  onClick: () => void
  content: string
}

const UpgradeBanner = ({ onClick, content }: Props) => (
  <a onClick={onClick} styleName="upgrade-banner">
    {content} <span styleName="cta">You need to upgrade to enable this Applet.</span>
  </a>
)

export default UpgradeBanner
