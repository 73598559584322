import { Component } from "react"
import PropTypes from "prop-types"

import classNames from "classnames"
import { capitalizeAll } from "humanize-plus"
import { attachNewBadgeForButtons } from "shared/scripts/new_badge_button.tsx"

import EditAction from "./edit_action"

export default class AccountInfo extends Component {
  normalizeChannelFields = channelFields => {
    let credentials = {}
    const fields = JSON.parse(channelFields)
    const idKeys = [
      "username",
      "user_name",
      "full_name",
      "fullname",
      "email",
      "blog_id", // tumblr
      "blog_name", // blogger
    ]

    Object.entries(fields).map(([fieldName, fieldValue]) => {
      let key = ""

      if (idKeys.includes(fieldName)) {
        key = "identity"
      } else if (fieldName === "user_url") {
        key = "URL"
      } else if (fieldName === "id") {
        key = "ID"
      } else {
        key = capitalizeAll(fieldName.replace(/_/g, " "))
      }

      if (fieldValue) {
        credentials[key] = fieldValue
      }
    })

    return credentials
  }

  buildCredentialRow = (index, liveChannel, service) => {
    const credentials = this.normalizeChannelFields(
      liveChannel.dynamic_channel_fields
    )

    const styleClass = classNames("settings-item-container", {
      "one-account": !service.allow_multiple_live_channels,
    })

    return (
      <li key={liveChannel.id} className={styleClass}>
        <ul className="settings-item">
          {credentials["identity"] && (
            <li className="settings-attribute identity">
              {this.props.allowMultipleLiveChannels && liveChannel.primary && (
                <span className="label">Default</span>
              )}
              <span className="value">
                {credentials["identity"]}
                {liveChannel.offline && (
                  <>
                    <br />
                    <span className="offline">This account is offline.</span>
                  </>
                )}
              </span>
            </li>
          )}
          <li className="settings-attribute">
            <span className="label">Status</span>
            <span className="value">
              {liveChannel.offline ? "Inactive" : "Active"}
            </span>
          </li>
          {Object.entries(credentials).map(
            ([credentialLabel, credentialValue], idx) => {
              if (credentialLabel === "identity") return null

              return (
                <li key={idx} className="settings-attribute">
                  <span className="label">{credentialLabel}</span>
                  <span className="value">{credentialValue}</span>
                </li>
              )
            }
          )}
        </ul>
        <ul className="settings-item-actions">
          <li className="settings-attribute">
            {index === 0 && <span className="label">&nbsp;</span>}
            <EditAction
              service={service}
              liveChannel={liveChannel}
              action={liveChannel.reconnect_path}
            />
          </li>
        </ul>
      </li>
    )
  }

  componentDidMount() {
    attachNewBadgeForButtons()
  }

  render() {
    return (
      <>
        <h3>
          {this.props.service.allow_multiple_live_channels
            ? "Connected as"
            : "Details"}
        </h3>
        <ul>
          {this.props.liveChannels.map((liveChannel, index) =>
            this.buildCredentialRow(index, liveChannel, this.props.service)
          )}
        </ul>
        {this.props.allowMultipleLiveChannels && (
          <div className="add-account">
            <a
              role="button"
              className="button-secondary"
              href={this.props.addAccountPath}
              data-allow-new-badge="multi_service_account"
            >
              Add new account
            </a>
          </div>
        )}
      </>
    )
  }
}

AccountInfo.propTypes = {
  service: PropTypes.shape({
    allow_multiple_live_channels: PropTypes.bool.isRequired,
    brand_color: PropTypes.string.isRequired,
    module_name: PropTypes.string.isRequired,
  }).isRequired,
  liveChannels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      offline: PropTypes.bool,
      dynamic_channel_fields: PropTypes.string,
      primary: PropTypes.bool,
    }).isRequired
  ),
  allowMultipleLiveChannels: PropTypes.bool,
  addAccountPath: PropTypes.string,
}
