import PropTypes from "prop-types"
import "./applet_limit_warning.scss"

const AppletLimitWarning = ({ className, user: { tier, appletQuota } }) => {
  const created = appletQuota.total - appletQuota.remaining
  const visible =
    !["legacy_pro", "pro"].includes(tier) && appletQuota.remaining > 0

  return visible ? (
    <div styleName={`applet-limit-warning ${className || ""}`}>
      You&rsquo;re using {created} of {appletQuota.total} Applets
    </div>
  ) : null
}

AppletLimitWarning.propTypes = {
  user: PropTypes.shape({
    tier: PropTypes.string.isRequired,
    appletQuota: PropTypes.shape({
      total: PropTypes.number,
      remaining: PropTypes.number,
    }),
  }),
  className: PropTypes.string,
}

export default AppletLimitWarning
