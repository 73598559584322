import { useState, useRef } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import { CheckEmailAvailability, SendMagicSignIn } from "./mutations"
import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"

import rightTriangle from "images/web/connect/right_triangle.svg"
import icons from "../connect_sdk_applet/utils/art"
import {
  replaceTextWithComponent,
  formatTextStyle,
} from "./utils/replace_text_with_component"

import "./connect_url.scss"

export default function VerifyEmailAddress(props) {
  const [badEmail, updateBadEmail] = useState(false)
  const formRef = useRef()

  const submitEmailForm = event => {
    event.preventDefault()
    const emailToCheck = email.trim()

    if (emailToCheck.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) {
      CheckEmailAvailability(emailToCheck)
        .then(available => {
          if (available) {
            formRef.current.submit()
          } else {
            SendMagicSignIn({
              appletSlug: props.slug,
              email: emailToCheck,
              redirectUri: `${location.href}&skip_verify=1`,
              locale: props.locale,
            })
              .then(({ errors = [] } = {}) => {
                if (errors.length) {
                  throw new Error(JSON.stringify(errors))
                } else {
                  props.onSentMagicSignIn(emailToCheck)
                }
              })
              .catch(rejectedXHRPromiseLogger())
          }
        })
        .catch(rejectedXHRPromiseLogger())
    } else {
      updateBadEmail(true)
    }
  }

  const updateEmailAddress = event => {
    props.onEmailChange(event.target.value)
    updateBadEmail(false)
  }

  const formatLocalizationTextWithLogoAndLink = localization => {
    const svg = <span styleName="ifttt-logo">{parse(icons.iftttLogoWhite)}</span>

    return replaceTextWithComponent(
      localization.secured_with_ifttt,
      "IFTTT",
      svg
    ).map(
      formatTextStyle(
        localization.learn_more,
        <a styleName="learn-more" href="#" onClick={props.toggleLearnMore}>
          {localization.learn_more}
        </a>
      )
    )
  }

  const {
    email,
    submitUrl,
    redirectUri,
    code,
    overlayColor,
    skipConfig,
    localization,
  } = props

  return (
    <form
      action={submitUrl}
      method="POST"
      styleName="connect-cta"
      onSubmit={submitEmailForm}
      ref={formRef}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={window.App.authenticityToken}
      />
      <input type="hidden" label="email" name="email" value={email} />
      {redirectUri && (
        <input
          type="hidden"
          label="redirect_uri"
          name="redirect_uri"
          value={redirectUri}
        />
      )}
      {code && <input type="hidden" label="code" name="code" value={code} />}
      {skipConfig && (
        <input
          type="hidden"
          label="skip_config"
          name="skip_config"
          value="true"
        />
      )}
      <div>
        {badEmail && (
          <p styleName="secure-connections">Please use a valid email address</p>
        )}
        <span styleName="email-input">
          <input
            type="email"
            name="verified_email"
            placeholder="Your email"
            value={email}
            onChange={updateEmailAddress}
          />
          <button
            type="submit"
            styleName="verify-button"
            style={{ backgroundColor: overlayColor }}
          >
            <span styleName="right-triangle">{parse(rightTriangle)}</span>
          </button>
        </span>
        <p styleName="secure-connections">
          {formatLocalizationTextWithLogoAndLink(localization)}
        </p>
      </div>
    </form>
  )
}

VerifyEmailAddress.propTypes = {
  email: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  redirectUri: PropTypes.string,
  code: PropTypes.string,
  toggleLearnMore: PropTypes.func.isRequired,
  overlayColor: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  skipConfig: PropTypes.bool,
  locale: PropTypes.string,
  localization: PropTypes.shape({
    secured_with_ifttt: PropTypes.string.isRequired,
    learn_more: PropTypes.string.isRequired,
  }).isRequired,
  onEmailChange: PropTypes.func.isRequired,
}

VerifyEmailAddress.defaultPropTypes = {
  locale: "en",
}
