interface Props {
  billingUrl: string
  selectSurveryOption: func
  moveNextView: func
  surveyOption: string
  tierName: string
}

const Survey = ({ billingUrl, selectSurveryOption, moveNextView, surveyOption, tierName }: Props) => {
  const options = {
    plans_too_pricy: "Cost",
    not_using: "Not using",
    another_solution: "Found alternate solution",
    customer_support: "Technical issues",
    wrong_plan: "Wrong plan",
    other: "Other",
  }

  const onCancellationClick = () => {
    switch (surveyOption) {
      case "customer_support":
        moveNextView("customer_support")
        break
      case "wrong_plan":
        moveNextView("switch_your_plan")
        break
      case "plans_too_pricy":
      case "not_using":
      case "another_solution":
      case "other":
        moveNextView("exclusive_offer")
        break
    }
  }

  return (
    <div>
      <h1 className="mt4 mb4 mb2--phone mb2--small-phone">Are you sure?</h1>

      <p className="txt-body-2 mt0">Please select the reason you are canceling your subscription.</p>

      <form className="form">
        <div className="fieldset">
          <div className="field">
            <div className="input">
              {Object.entries(options).map(([option, label]) => (
                <div className="input-group mb3" key={option}>
                  <input
                    type="radio"
                    id={option}
                    name="reason"
                    value={option}
                    onChange={() => selectSurveryOption(option)}
                    checked={surveyOption === option}
                  />
                  <label htmlFor={option}>{label}</label>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="cancellation-actions mt16 mt8--phone mt8--small-phone">
          <a className="button-primary" href={billingUrl}>
            Stay {tierName}
          </a>
          <a className={`link-standalone mt4 ${!surveyOption && "disabled"}`} onClick={onCancellationClick}>
            Continue with cancellation
          </a>
        </div>
      </form>
    </div>
  )
}

export default Survey
