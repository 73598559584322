import PropTypes from "prop-types"
import parse from "html-react-parser"

import queryIcon from "images/icons/icon-new-connection-query.svg"

import "./learn_more_button.scss"

const LearnMoreButton = ({ darkHeader, title, url }) => {
  const extraStyle = darkHeader ? "dark-header" : ""

  return (
    <a
      styleName={`learn-more ${extraStyle}`}
      className="learn-more-icon"
      title={title}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      href={url}
      rel="noreferrer"
    >
      <span>{parse(queryIcon)}</span>
    </a>
  )
}

LearnMoreButton.propTypes = {
  darkHeader: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
}

LearnMoreButton.defaultProps = {
  darkHeader: false,
  title: "Learn more",
}

export default LearnMoreButton
