import classNames from "classnames"
import parse from "html-react-parser"

import "./hello_styles.scss"

interface Props {
  clickAccept: () => void
  clickAcceptUrl: string
  containerStyle: string
  ctaTitle: string
  declineCopy: string
  logoCover: string
  logoHtml: string
  mainBody: string
  mainTitle: string
  onClickDecline: () => void
  onboardingStyle?: string
}

const HelloCard = ({
  clickAccept,
  clickAcceptUrl,
  containerStyle,
  ctaTitle,
  declineCopy,
  logoCover,
  logoHtml,
  mainBody,
  mainTitle,
  onClickDecline,
  onboardingStyle,
}: Props) => {
  const clickAcceptHandler = event => {
    if (clickAccept) {
      event.preventDefault()
      clickAccept()
    }
  }
  return (
    <div styleName={classNames("popup-container", containerStyle)}>
      <div styleName="popup-card">
        <a href={clickAcceptUrl} onClick={clickAcceptHandler}>
          <div styleName="sla-img-container">
            <MainImage image={logoHtml} />
            {logoCover && <span styleName="logo-cover">{parse(logoCover)}</span>}
          </div>
          <div styleName={onboardingStyle ? `copy ${onboardingStyle}` : "copy"}>
            <h3>{mainTitle}</h3>
            <p>{mainBody}</p>
          </div>
        </a>
        <div className="action-buttons-container">
          {ctaTitle && (
            <a href={clickAcceptUrl} onClick={clickAcceptHandler} className="button-tertiary">
              {ctaTitle}
            </a>
          )}
          {declineCopy && (
            <button
              onClick={onClickDecline}
              className="button-tertiary button-cancel-link"
            >
              {declineCopy}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default HelloCard

export const MainImage = ({ image }: { image: string }) =>
  image.includes("gif") ? <img src={image} /> : <div styleName="logo">{parse(image)}</div>
