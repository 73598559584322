import { ReactElement, useState } from "react"
import Modal from "shared/components/modal"
import parse from "html-react-parser"

import "./install_app_to_accept_invite.scss"

interface Props {
  dynamicLink: string
  qrCode: string
}

const InstallAppToAcceptInvite = (props: Props): ReactElement | null => {
  const [visible, setVisibility] = useState(true)

  return (
    <div styleName="modal-container">
      <Modal show={visible} includeCloseX={false}>
        <h3>To accept this invite, download our app!</h3>

        <p className="txt-body-3">
          Group Location requires you to share your location using our app on iOS or Android.
        </p>

        <section styleName="qr-code">
          <span>{parse(props.qrCode)}</span>
          <span className="txt-caption">
            Scan code to continue
            <span styleName="link-on-responsive">
              or{" "}
              <a href={props.dynamicLink} target="_blank" rel="noreferrer">
                click here
              </a>
              .
            </span>
          </span>
        </section>

        <div className="action-buttons-container">
          <button className="button-tertiary" onClick={() => setVisibility(false)}>
            Done
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default InstallAppToAcceptInvite
