interface Props {
  services: Array<Service>
  trigger: TriggerOrQueryService
}

interface Service {
  name: string
  actions: Array<ActionService>
  queries: Array<TriggerOrQueryService>
  trigger: TriggerOrQueryService
}

interface TriggerOrQueryService {
  label: string
  name: string
  ingredients: Array<Ingredient>
}
interface Ingredient {
  example: string
  label: string
  name: string
}

interface ActionService {
  label: string
  name: string
  fields: Array<Field>
}

interface Field {
  label: string
  name: string
}

export default function generateApiList(contextData: Props) {
  const apiList = { More: buildMetaDefinitions() }
  const { services, trigger } = contextData
  const triggerService = services.find(s => s.trigger)
  const queryServices = services.filter(s => s.queries.length)
  const actionServices = services.filter(s => s.actions.length)

  if (trigger && triggerService)
    apiList["Triggers"] = buildTriggerDefinitions(triggerService, trigger)
  if (queryServices.length)
    apiList["Queries"] = buildQueryDefinitions(queryServices)
  if (actionServices.length)
    apiList["Actions"] = buildActionDefinitions(actionServices)

  return apiList
}

function buildTriggerDefinitions(triggerService: Service, trigger: TriggerOrQueryService) {
  return {
    placeholder: "Select trigger ingredients",
    options: {
      [triggerService.name]: buildIngredientsAPIList(
        trigger.name,
        trigger.ingredients
      ),
    },
  }
}

function buildQueryDefinitions(queryServices: Array<Service>) {
  return {
    placeholder: "Select query ingredients",
    options: buildQueryAPIList(queryServices),
  }
}

function buildActionDefinitions(actionServices: Array<Service>) {
  return {
    placeholder: "Select action options",
    options: buildActionAPIList(actionServices),
  }
}

function buildMetaDefinitions() {
  return {
    placeholder: "Select other options",
    options: {
      Meta: ["currentUserTime", "triggerTime"],
    },
  }
}

function buildQueryAPIList(queryServices: Array<Service>) {
  const definitions = {}
  queryServices.forEach(service =>
    service.queries.forEach(query => {
      const firstObjectName = `${query.name}[0]`
      definitions[service.name] = (definitions[service.name] || []).concat(
        buildIngredientsAPIList(firstObjectName, query.ingredients)
      )
    })
  )
  return definitions
}

function buildIngredientsAPIList(objectName: string, ingredients: Array<Ingredient>) {
  return ingredients.map(i => `${objectName}.${i.name}`)
}

function buildActionAPIList(actionServices: Array<Service>) {
  const definitions = {}
  actionServices.forEach(service =>
    service.actions.forEach(action => {
      definitions[service.name] = (definitions[service.name] || []).concat(
        buildFieldsAPIList(action.name, action.fields)
      )
    })
  )
  return definitions
}

function buildFieldsAPIList(objectName: string, stepFields: Array<Field>) {
  return [`${objectName}.skip()`].concat(
    stepFields.map(f => `${objectName}.set${f.name}()`)
  )
}
