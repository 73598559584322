import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import classnames from "classnames"

import TransitionEvent from "app/scripts/transition_event"
import {
  getOverlayColor,
  formatHexColorValue,
} from "shared/lib/alternate_brand_color"

import "./returning_user_flow.scss"

export default class Connect extends Component {
  state = { transitionStarted: false, transitionEnded: false }

  componentDidMount() {
    this.initiateTransition()
  }

  componentDidUpdate(prevProps) {
    if (this.state.transitionStarted && !this.state.transitionEnded) return

    if (
      this.props.view !== prevProps.view ||
      this.props.nextToConnect.name !== prevProps.nextToConnect.name
    ) {
      this.initiateTransition()
    }
  }

  // in case user navigates away before the timeout kicks in
  componentWillUnmount() {
    clearTimeout(this.timeOut)
    this.element.removeEventListener(
      TransitionEvent(),
      this.handleTransitionEnd
    )
  }

  initiateTransition = () => {
    this.setState({ transitionStarted: false, transitionEnded: false }, () => {
      this.timeOut = setTimeout(
        () =>
          this.setState({ transitionStarted: true }, () => {
            this.element.addEventListener(
              TransitionEvent(),
              this.handleTransitionEnd
            )
          }),
        this.props.delayFadeInBy
      )
    })
  }

  handleTransitionEnd = () => {
    this.setState({ transitionEnded: true }, () => {
      this.element.removeEventListener(
        TransitionEvent(),
        this.handleTransitionEnd
      )
    })
    this.props.onAnimationEnd()
  }

  render() {
    const {
      nextToConnect: { brand_color, name },
      createMagickAccount,
      beforeVerification,
      email,
    } = this.props

    const bgColor = {
      backgroundColor: formatHexColorValue(brand_color),
    }

    return (
      <div
        style={bgColor}
        styleName={classnames({
          "connection-status-container": true,
          clickable: true,
        })}
        onClick={this.handleClick}
      >
        <div styleName="connection-status">
          <div
            id="progress-meter"
            ref={ref => (this.element = ref)}
            styleName={classnames({
              meter: true,
              "grow-right": this.state.transitionStarted,
            })}
            style={{ background: getOverlayColor(brand_color) }}
          />
          <div styleName="info">
            {createMagickAccount ? (
              <span>
                {parse(this.props.localization.creating_account_for.replace("%@", email))}
              </span>
            ) : beforeVerification ? (
              <span>
                {this.props.localization.going_to_service.replace("%@", name)}…
              </span>
            ) : (
              <span>
                {this.props.localization.connecting_service.replace("%@", name)}
                …
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  handleClick = event => {
    event.preventDefault()
  }
}

Connect.propTypes = {
  email: PropTypes.string.isRequired,
  nextToConnect: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_name: PropTypes.string,
    brand_color: PropTypes.string.isRequired,
  }),
  delayFadeInBy: PropTypes.number,
  emailAppScheme: PropTypes.string,
  onAnimationEnd: PropTypes.func,
  createMagickAccount: PropTypes.bool,
  beforeVerification: PropTypes.bool,
  view: PropTypes.string.isRequired,
  localization: PropTypes.shape({
    going_to_service: PropTypes.string.isRequired,
    connecting_service: PropTypes.string.isRequired,
    creating_account_for: PropTypes.string.isRequired,
  }),
}

Connect.defaultProps = {
  delayFadeInBy: 1300,
  beforeVerification: false,
}
