import Modal from "shared/components/modal"
import parse from "html-react-parser"

import "./mobile_gate_modal.scss"

import appStoreLogo from "images/icons/apple_appstore_download.svg"
import playStoreLogo from "images/icons/google_play_store_download.svg"

interface Props {
  device: string
  onClose: object
  visible: boolean
}

const MobileGateModal = (props: Props) => {
  const copy = props.device === "none"
    ? "Please widen your browser window or download the IFTTT app to use the complete filter code experience."
    : "Download the IFTTT app for an optimized filter code experience on mobile."

  return (
    <div styleName="modal-container">
      <Modal show={props.visible} closeListener={props.onClose} includeCloseX={false}>
        <h1 className="mt0 mb0">IFTTT app</h1>
        <p className="txt-body-3">{copy}</p>
        <div className="action-buttons-container">
          {["ios", "none"].includes(props.device) && (
            <a
              href="https://itunes.apple.com/app/apple-store/id660944635?pt=1752682&ct=&mt=8"
              title="Download on the App Store"
              aria_label="The Apple App Store"
              target="_blank"
              rel="nopener noreferrer"
            >
              <span>{parse(appStoreLogo)}</span>
            </a>
          )}
          {["android", "none"].includes(props.device) && (
            <a
              href="https://play.google.com/store/apps/details?id=com.ifttt.ifttt&utm_source=&utm_medium=web"
              title="Get it on Google Play"
              aria_label="The Google Play Store"
              target="_blank"
              rel="nopener noreferrer"
            >
              <span>{parse(playStoreLogo)}</span>
            </a>
          )}
          <a
            className="link-caption mt2"
            onClick={props.onClose}
          >
            Maybe later
          </a>
        </div>
      </Modal>
    </div>
  )
}

export default MobileGateModal
