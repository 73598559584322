function getFieldType(fieldType) {
  switch (fieldType) {
    case "TEXT_FIELD":
    case "TEXT_AREA":
    case "MINUTE_SELECT":
    case "TIME_SELECT":
    case "DATETIME_NO_YEAR_SELECT":
      return "Text input"
    case "COLLECTION_SELECT":
      return "Dropdown list"
    case "DOUBLE_COLLECTION_SELECT":
      return "Nested dropdown list"
    case "CHECKBOX_MULTI":
      return "Checkboxes"
    case "LOCATION_POINT":
    case "LOCATION_RADIUS":
    case "LOCATION_ENTER":
    case "LOCATION_EXIT":
    case "LOCATION_ENTER_AND_EXIT":
      return "Location input"
    default:
      throw new Error(`Don't know field type: ${fieldType}`)
  }
}

function getFieldSubType(fieldTypeDefinitionSlug) {
  switch (fieldTypeDefinitionSlug) {
    case "text_raw":
      return "Other"
    case "title":
      return "Title"
    case "collection_title":
      return "Document title"
    case "html":
      return "HTML"
    case "body":
      return "Long text"
    case "message":
      return "Short text"
    case "mobile":
      return "Notification"
    case "text_datetime":
      return "ISO 8601 date and time"
    case "text_email":
      return "Email address"
    case "text_regex":
      return "Regular expression"
    case "text_url":
      return "URL"
    case "audio_url":
      return "Audio"
    case "file_url":
      return "File"
    case "filename":
      return "File name"
    case "folder":
      return "Path to a folder"
    case "link_url":
      return "Link"
    case "photo_url":
      return "Photo"
    case "tags":
      return "Tags"
    case "location_point":
    case "location_point_dynamic":
      return "Point"
    case "location_radius":
    case "location_radius_dynamic":
      return "Radius"
    case "location_enter":
    case "location_enter_dynamic":
    case "location_exit":
    case "location_exit_dynamic":
    case "location_enter_and_exit":
    case "location_enter_and_exit_dynamic":
      return "Area"
    case "dropdown_static":
    case "dropdown_dynamic":
    case "checkbox_static":
    case "checkbox_dynamic":
    case "dropdown_dynamic_double":
    case "text_not_blank":
    case "text_dynamic":
    case "undefined":
      return null
    default:
      throw new Error(`Don't know field type: ${fieldTypeDefinitionSlug}`)
  }
}

export function getFieldTypeDefinition(field) {
  return [
    getFieldType(field.field_type),
    getFieldSubType(field.field_type_definition_slug),
  ]
    .filter(Boolean)
    .join(" > ")
}
