import { gql } from "shared/lib/graphql"

export const LiveChannelsPartialQuery = `
  live_channels {
    id
    user_name_field
    offline
  }
`
export const LiveChannelsQuery = gql`
  query LiveChannelsQuery($serviceModuleName: String!) {
    channel(module_name: $serviceModuleName) {
      ${LiveChannelsPartialQuery}
    }
  }
`
