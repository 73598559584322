import { graphQueryBang } from "shared/lib/graphql"

import {
  InviteUserToGroupMutation,
  LeaveGroupMutation,
  RescindInvitationMutation,
  RespondToInvitationMutation
} from "./actions"

export const inviteUser = async (email: string, guid: string) =>
  graphQueryBang(InviteUserToGroupMutation, {
    user_email: email,
    group_guid: guid,
  })

export const leaveGroup = async (guid: string, email: string) =>
  graphQueryBang(LeaveGroupMutation, { group_guid: guid, user_email: email })

export const rescindInvitation = async (guid: string, email: string) =>
  graphQueryBang(RescindInvitationMutation, {
    group_guid: guid,
    user_email: email,
  })

export const respondToInvitation = async (guid: string, accepted: boolean) =>
  graphQueryBang(RespondToInvitationMutation, { group_guid: guid, accepted })
