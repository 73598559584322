import PropTypes from "prop-types"

import "./connect_url.scss"

const ClickVerify = ({ onSubmit, overlayColor, localization }) => (
  <div styleName="verify-service">
    <a
      onClick={e => {
        e.preventDefault()
        onSubmit()
      }}
      href="#"
      styleName="big-button"
      style={{ backgroundColor: overlayColor }}
    >
      <span>{localization.continue}</span>
    </a>
  </div>
)

ClickVerify.propTypes = {
  overlayColor: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localization: PropTypes.shape({
    continue: PropTypes.string.isRequired,
  }),
}

export default ClickVerify
