import { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

import classNames from "classnames"
import UpgradePlanModal from "./upgrade_plan_modal"

const PlanUpgradeButton = ({
  buttonOnColor,
  currentTier,
  eligibleForTrial,
  eligibleTrialPeriod,
  isTrialing,
  newProPlusSubscriptionUrl,
  proPlusProductId,
  signedIn,
  subscriptionPaymentType,
  trackLocationType,
  upgradePath,
}) => {
  const upgrading = !!window.App.user?.id && currentTier !== "free" && signedIn
  const [showModal, setShowModal] = useState(false)

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  useEffect(() => {
    const erbOverlay = document.querySelector(".link_overlay.js-handle")

    if (erbOverlay) {
      erbOverlay.addEventListener("click", toggleModal)

      return () => erbOverlay.removeEventListener("click", toggleModal)
    }
  }, [toggleModal])

  const cta = eligibleForTrial ? "Try it free" : "Get Pro+"

  return (
    <>
      {upgrading && (
        <UpgradePlanModal
          onClose={() => toggleModal()}
          onUpgrade={() => (window.location = upgradePath)}
          proPlusProductId={proPlusProductId}
          step="plan_upgrade"
          tierRequirement="pro"
          trackLocationType={trackLocationType}
          user={{
            eligibleForTrial,
            eligibleTrialPeriod,
            isTrialing,
            subscriptionPaymentType,
            tier: currentTier,
          }}
          visible={showModal}
        />
      )}
      <a
        href={upgrading ? undefined : newProPlusSubscriptionUrl}
        onClick={upgrading ? () => toggleModal() : undefined}
        className={classNames("button-secondary", {
          "button-on-color": buttonOnColor,
        })}
        data-plan-page={!upgrading}
        title={cta}
      >
        {cta}
      </a>
    </>
  )
}

PlanUpgradeButton.propTypes = {
  buttonOnColor: PropTypes.bool,
  currentTier: PropTypes.string,
  eligibleForTrial: PropTypes.bool,
  eligibleTrialPeriod: PropTypes.number,
  isTrialing: PropTypes.bool,
  newProPlusSubscriptionUrl: PropTypes.string.isRequired,
  proPlusProductId: PropTypes.string.isRequired,
  signedIn: PropTypes.bool,
  subscriptionPaymentType: PropTypes.string,
  trackLocationType: PropTypes.string,
  upgradePath: PropTypes.string,
}

PlanUpgradeButton.defaultProps = {
  buttonOnColor: false,
  eligibleTrialPeriod: 0,
}

export default PlanUpgradeButton
