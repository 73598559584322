import classnames from "classnames"

import FilterIcon from "shared/components/icons/filter_icon"
import DelayIcon from "shared/components/icons/delay_icon"
import { verifyIntendedPromiseRejection } from "./utils/modal_promise"
import { cancellationError } from "./utils/error_map"

import "./diy_tanda_selected.scss"
import { Service } from "./utils/interfaces"
import { MouseEventHandler } from "react"

interface Props {
  canEdit: boolean
  color?: string
  showProFeatures?: boolean
  label: "if" | "with" | "when" | "wait" | "then" | "and"
  onDeleteClick: (dataConfirmation: () => Promise<void>) => Promise<void>
  onEditClick: () => Promise<void>
  liveChannelId?: string | undefined
  readOnly: boolean
  service: Service
  stepName: string | undefined
}

export default function DIYTandaSelected({
  canEdit,
  label,
  onDeleteClick,
  onEditClick,
  liveChannelId,
  readOnly,
  service,
  showProFeatures = false,
  stepName,
}: Props) {
  const isFilter = label === "when"
  const isDelay = label === "wait"

  const serviceImage = () => {
    if (isFilter) {
      return <FilterIcon title="filter icon" />
    } else if (isDelay) {
      return <DelayIcon title="delay icon" />
    } else {
      return <img width="20" height="20" src={service.image} alt={`${service.name} icon`} title={service.name} />
    }
  }

  const blockClass = classnames("tanda-selected-block", {
    highlighted: ["if", "then"].includes(label),
    clickable: canEdit || isFilter,
  })
  const blockStyle = { borderColor: service.brand_color, backgroundColor: service.brand_color }
  const labelClass = classnames("label", {
    when: isFilter || isDelay,
  })

  const dataConfirmation = () => {
    let message: string
    if (label === "if") {
      message = showProFeatures
        ? "If you’ve used any of this trigger’s ingredients in queries, filter code or actions you will have to remove those ingredients before saving this Applet."
        : "If you’ve used any of this trigger’s ingredients in your action you will have to remove those ingredients before saving this Applet."
    } else if (label === "with") {
      message =
        "If you’ve used any of this query’s ingredients in filter code or actions you will have to remove those ingredients before saving this Applet."
    } else if (showProFeatures && ["then", "and"].includes(label)) {
      message = "If you’ve used this action in filter code you will have to remove this code before saving this Applet."
    }

    return new Promise<void>((resolve, reject) => {
      if (message && !window.confirm(message)) {
        reject(cancellationError)
      } else {
        resolve()
      }
    })
  }

  const wrapHandler = (handler: () => Promise<void>) => {
    return (e: MouseEventHandler<HTMLElement>) => {
      e.stopPropagation()
      verifyIntendedPromiseRejection(handler())
    }
  }

  const selectedLiveChannel = service.live_channels?.find(lc => lc.id == liveChannelId)

  return (
    <section
      onClick={canEdit || isFilter ? wrapHandler(onEditClick) : undefined}
      style={blockStyle}
      styleName={blockClass}
    >
      <span styleName={labelClass}>{label}</span>

      {serviceImage()}
      <div styleName="step">
        <span styleName={classnames("step-name", { then: label === "then" })}>{stepName}</span>
        {service.allow_multiple_live_channels && (
          <span styleName="step-multi-account-selected">{selectedLiveChannel?.user_name_field}</span>
        )}
      </div>

      <div styleName="actions">
        {(canEdit || isFilter) && (
          <button styleName="action" onClick={wrapHandler(onEditClick)}>
            Edit
          </button>
        )}
        {!readOnly && (
          <button styleName="action" onClick={wrapHandler(() => onDeleteClick(dataConfirmation))}>
            Delete
          </button>
        )}
      </div>
    </section>
  )
}
