import PropTypes from "prop-types"
import { connectionShape, serviceShape, partialStoryShape } from "app/lib/shared_prop_types"
import classNames from "classnames"
import parse from "html-react-parser"

import Service from "./service"
import AppletCard from "./applet_card"
import StoryCard from "./story_card"
import TqaDetails from "./tqa_details"

import iftttProPlusLogo from "./ifttt-pro-plus-logo.svg"
import iftttStoryPro from "./story-cta.svg"
import "./index.scss"

const HeroCard = ({ signedIn, showUpgradePrompt }) => {
  if (signedIn && !showUpgradePrompt) {
    return null
  }

  const destination = signedIn ? "/subscriptions/pro_plus" : "/join"
  const buttonCopy = signedIn ? "Upgrade now" : "Start automating"
  return (
    <a href={destination}>
      <div styleName="cta-container">
        <div styleName="story-cta">
          <div styleName={classNames("copy", { "with-logo": signedIn })}>
            {signedIn ? (
              <div>
                <div>{parse(iftttProPlusLogo)}</div>
                <div>More, better, faster Applets.</div>
              </div>
            ) : (
              "IFTTT helps all your apps and devices work better together"
            )}
            <button styleName="start-btn" className="button-secondary">
              {buttonCopy}
            </button>
          </div>
          <div styleName="hero">{parse(iftttStoryPro)}</div>
        </div>
      </div>
    </a>
  )
}

HeroCard.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  showUpgradePrompt: PropTypes.bool.isRequired,
}

const ConnectView = ({ applets, alternateApplets, services, showUpgradePrompt, signedIn, stories, tqaDetails }) => {
  let showAppletsTitle
  let shownApplets
  const integrationTitle = `Useful ${services[0].name} and ${services[1].name} integrations`

  if (applets.length > 0) {
    showAppletsTitle = `Popular ${services[0].name} and ${services[1].name} workflows and automations`
    shownApplets = applets
  } else {
    showAppletsTitle = `Other popular ${services[0].name} workflows and automations`
    shownApplets = alternateApplets || []
  }

  return (
    <div styleName="connect-container">
      {!signedIn && (
        <a styleName="sign-up" className="button-primary sign-up" href="/join">
          Start today
        </a>
      )}
      {shownApplets.length > 0 ? (
        <>
          <h2 styleName="header">{showAppletsTitle}</h2>
          <ul styleName="applets" className="web-applet-cards">
            {shownApplets.map((applet, idx) => {
              return <AppletCard applet={applet} key={idx} position={idx} />
            })}
          </ul>
          <h3 styleName="header">{integrationTitle}</h3>
        </>
      ) : (
        <h2 styleName="header">{integrationTitle}</h2>
      )}

      <TqaDetails {...tqaDetails} />

      {!!stories.length && (
        <>
          <h3 styleName="header">
            {services[0].name} and {services[1].name} resources
          </h3>
          <div styleName="resources">
            {stories.map(story => (
              <StoryCard
                key={story.id}
                story={story}
                position={0}
                singleStoryBasePath="explore"
                locationType="connect"
              />
            ))}
          </div>
        </>
      )}

      <HeroCard signedIn={signedIn} showUpgradePrompt={showUpgradePrompt} />

      <footer styleName="service-list">
        {services.map((service, idx) => {
          return <Service key={idx} service={service} />
        })}
      </footer>
    </div>
  )
}

ConnectView.tqaShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  module_name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  brand_color: PropTypes.string.isRequired,
})

ConnectView.propTypes = {
  applets: PropTypes.arrayOf(connectionShape),
  alternateApplets: PropTypes.arrayOf(connectionShape),
  services: PropTypes.arrayOf(serviceShape),
  showUpgradePrompt: PropTypes.bool.isRequired,
  signedIn: PropTypes.bool.isRequired,
  tqaDetails: PropTypes.shape({
    triggers: PropTypes.arrayOf(ConnectView.tqaShape),
    queries: PropTypes.arrayOf(ConnectView.tqaShape),
    actions: PropTypes.arrayOf(ConnectView.tqaShape),
  }),
  stories: PropTypes.arrayOf(partialStoryShape).isRequired,
}

ConnectView.defaultProps = {
  applets: [],
}

export default ConnectView
