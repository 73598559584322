import Upsell from "./upsell"
import { TrialConfigAssets, TrialConfigUrls } from "./upsell"
import { submitPreferredPlatforms, submitUserGoals } from "./api"
import { assignWithBustedCache } from "shared/lib/utils"

import "./header.scss"
import parse from "html-react-parser"
import iftttLogo from "images/logo-ifttt.svg"
import proBadge from "images/plan-badges/pro.svg"
import proPlusBadge from "images/plan-badges/pro_plus.svg"

interface Props {
  browserPlatform: string
  trialConfig: {
    assets: TrialConfigAssets
    urls: TrialConfigUrls
  }
  urls: {
    exploreUrl: string
  }
  userIsStudent: boolean
}

const OnboardingExclusiveOffer = ({ browserPlatform, trialConfig, urls, userIsStudent }: Props) => {
  const browserToMobilePlatformMapping: { [key: string]: string } = {
    ios: "iphone",
    android: "android",
  }

  // After user clicks on Continue or Skip, we'll move onboarding to done with standard options.
  const finishOnboarding = () => {
    const userMobilePlatform = browserToMobilePlatformMapping[browserPlatform]
    submitPreferredPlatforms([userMobilePlatform || "web"])
    submitUserGoals(["something_else"], null)

    window.history.replaceState({}, "", urls.exploreUrl)
  }

  const onClickContinue = (evt: MouseEvent) => {
    evt.preventDefault()

    finishOnboarding()

    assignWithBustedCache(evt.target?.href || trialConfig.urls.proSubscriptionsUrl)
  }

  const onSkipUpsell = () => {
    finishOnboarding()

    assignWithBustedCache(urls.exploreUrl)
  }

  return (
    <>
      <header styleName="onboarding-header trial">
        <div styleName="badge-container">
          <span>{parse(iftttLogo)}</span>
          <span>{parse(userIsStudent ? proPlusBadge : proBadge)}</span>
        </div>
      </header>

      <Upsell
        goal="something_else"
        onClickBack={() => {}} // disabled
        onClickContinue={onClickContinue}
        onSkip={onSkipUpsell}
        skipHeader={true}
        trialConfig={trialConfig}
        userIsStudent={userIsStudent}
      />
    </>
  )
}

export default OnboardingExclusiveOffer
