import { useState, useEffect } from "react"
import { gql, graphQueryBang } from "../../../shared/lib/graphql"

import "./receipt_settings.scss"

const FlagQuery = gql`
  query FlagQuery {
    me {
      flag(key: "email_receipts")
    }
  }
`

const FlagMutation = gql`
  mutation SetUserFlag($value: JSON!) {
    setUserFlag(input: { flag: "email_receipts", value: $value }) {
      success
    }
  }
`

const ReceiptSettings = () => {
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    graphQueryBang(FlagQuery).then(result => {
      setChecked(result.me.flag)
      setLoading(false)
    })
  }, [])

  const onChange = async () => {
    setLoading(true)
    const result = await graphQueryBang(FlagMutation, {
      value: JSON.stringify(!checked),
    })

    if (result.setUserFlag?.success) {
      setChecked(!checked)
      window.appendFlash("Receipt settings updated", "success")
    } else {
      window.appendFlash(
        "There was a problem updating your receipt settings",
        "danger"
      )
    }
    setLoading(false)
  }

  return (
    <div styleName="receipt-settings">
      <label className="checkbox">
        <input
          type="checkbox"
          name={"email,receipts"}
          onChange={onChange}
          checked={checked}
          value={checked}
          disabled={loading}
        />
        <span />
        Email me a receipt when my subscription renews
      </label>
    </div>
  )
}

export default ReceiptSettings
