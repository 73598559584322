import { Component, cloneElement } from "react"
import PropTypes from "prop-types"
import isEmail from "validator/lib/isEmail"

import AuthPageContent from "./auth_page_content"
import FooterButton from "./footer_button"
import Form from "./form"
import FormField from "./form_field"
import FooterLink from "./footer_link"
import SdkLayout from "./sdk_layout"
import WorksWith from "./works_with"
import { post } from "shared/lib/api"

export default class SignUpPage extends Component {
  state = {
    submitting: false,
    email:
      this.props.suggestedEmail && isEmail(this.props.suggestedEmail)
        ? this.props.suggestedEmail
        : "",
    errors: {},
    signedUp: false,

    // These keys are both props and state because we have to update them when
    // the user has signed up. Normally this is an antipattern but in this case
    // it doesn't really matter since the props are embedded in the page and
    // can't be updated anyway.
    nextStepColor: this.props.nextStepColor,
    nextStepLabel: this.props.nextStepLabel,
    nextStepUrl: this.props.nextStepUrl,
    services: this.props.services,
  }

  handleChangeEmail = e => this.setState({ email: e.target.value })

  handleSubmit = e => {
    e.preventDefault()

    const { apiSignUpUrl } = this.props
    const { submitting, email } = this.state

    if (submitting) {
      return
    } else {
      this.setState({ submitting: true })
    }

    post(apiSignUpUrl, { email })
      .then(({ flow, user_id }) => {
        this.setState(
          {
            submitting: false,
            signedUp: true,
            ...flow,
          },
          () => {
            if (window.App.env && window.App.env === "production") {
              window.App.user.id = user_id
            }
          }
        )
      })
      .catch(({ response }) => {
        this.setState({ submitting: false })

        if (response && response.error) {
          const { error } = response

          if (error.code === "bad_email") {
            this.setState({
              errors: {
                email: [error.message],
              },
            })
          }
        }
        window.appendFlash("There was an error signing up", "danger")
      })
  }

  renderLayout(footer, content) {
    return (
      <SdkLayout footer={cloneElement(footer, { fadeIn: true })}>
        <WorksWith
          services={this.state.services}
          loggedIn={this.state.signedUp}
          justLoggedIn={this.state.signedUp}
        />
        <AuthPageContent>{content}</AuthPageContent>
      </SdkLayout>
    )
  }

  renderSignUp() {
    const { signInUrl } = this.props
    const { submitting, email, errors } = this.state

    const disabled = submitting || !email.trim()

    return this.renderLayout(
      <FooterButton
        label="Get started"
        disabled={disabled}
        onClick={this.handleSubmit}
      />,
      <div>
        <p>
          <b>IFTTT</b> is the easy, free way to get more out of all your
          favorite apps and devices. Add your email to begin.
        </p>

        <Form onSubmit={this.handleSubmit}>
          <FormField
            name="email"
            type="email"
            label="Email"
            onChange={this.handleChangeEmail}
            value={email}
            errors={errors.email}
          />

          <p className="note">
            Already have an IFTTT account?
            <a className="link-standalone" href={signInUrl}>
              Log in
            </a>
          </p>
        </Form>
      </div>
    )
  }

  renderSignedUp() {
    const { email, nextStepColor, nextStepLabel, nextStepUrl } = this.state

    return this.renderLayout(
      <FooterLink
        label={nextStepLabel}
        backgroundColor={nextStepColor}
        href={nextStepUrl}
      />,
      <p>
        We created you an IFTTT account for <b>{email}</b> and sent you an email
        with all your new account details.
      </p>
    )
  }

  render() {
    if (this.state.signedUp) {
      return this.renderSignedUp()
    } else {
      return this.renderSignUp()
    }
  }
}

SignUpPage.propTypes = {
  apiSignUpUrl: PropTypes.string.isRequired,
  nextStepColor: PropTypes.string,
  nextStepLabel: PropTypes.string.isRequired,
  nextStepUrl: PropTypes.string.isRequired,
  services: WorksWith.propTypes.services,
  suggestedEmail: PropTypes.string,
  signInUrl: PropTypes.string.isRequired,
}
