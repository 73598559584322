import { ReactElement } from "react"
import classnames from "classnames"

interface Props {
  rating: number
  fill: boolean
  rated: boolean
  hovered: boolean
  handleClickRating: (rating: number) => void
  setHoveredRating: (rating: number) => void
}

export default function StarIcon({
  fill,
  rating,
  rated,
  hovered,
  setHoveredRating,
  handleClickRating,
}: Props): ReactElement {
  return (
    <svg
      className={classnames({ rated, hovered })}
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleClickRating(rating)}
      onMouseEnter={rated ? () => {} : () => setHoveredRating(rating)}
    >
      {fill ? (
        <path
          d="M20.0001 0L13.4433 12.1867L0 14.7518L9.39033 24.8487L7.63924 38.6208L20 32.6744L32.3608 38.6208L30.6097 24.8487L40 14.7518L26.5567 12.1867L20.0001 0Z"
          fill="white"
        />
      ) : (
        <path
          d="M13.7244 13.6602L14.4259 13.5263L14.7642 12.8975L20.0001 3.1659L25.2358 12.8974L25.5741 13.5263L26.2756 13.6602L37.0541 15.7167L29.5113 23.8272L29.0331 24.3414L29.1216 25.0379L30.5247 36.073L20.6503 31.3227L20 31.0099L19.3497 31.3227L9.47526 36.073L10.8784 25.0379L10.9669 24.3414L10.4887 23.8272L2.94588 15.7167L13.7244 13.6602Z"
          fill="none"
          stroke="#333333"
          strokeWidth="3"
        />
      )}
    </svg>
  )
}
