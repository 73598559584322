import PropTypes from "prop-types"

export default function ErrorIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ color }}
        d="M29.937 59.874C13.403 59.874 0 46.471 0 29.937S13.403 0 29.937 0s29.937 13.403 29.937 29.937-13.403 29.937-29.937 29.937zm4.933-17.515c0-.592-.116-1.161-.346-1.706a4.076 4.076 0 0 0-.97-1.402 4.869 4.869 0 0 0-1.455-.935 4.467 4.467 0 0 0-1.755-.35 4.472 4.472 0 0 0-1.755.35 4.38 4.38 0 0 0-1.432.958c-.4.406-.716.88-.947 1.426a4.329 4.329 0 0 0-.347 1.705 4.05 4.05 0 0 0 .37 1.73c.24.52.568.994.97 1.402.4.405.88.724 1.409.935a4.574 4.574 0 0 0 1.732.327c.615 0 1.201-.117 1.755-.35a4.889 4.889 0 0 0 1.455-.935c.419-.394.75-.871.97-1.402a4.45 4.45 0 0 0 .346-1.753zm-8.406-7.104v-.001h7.76V13.24h-7.76v22.015z"
        fillRule="evenodd"
      />
    </svg>
  )
}

ErrorIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}

ErrorIcon.defaultProps = {
  width: "60",
  height: "60",
  color: "#fff",
}
