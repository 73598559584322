import { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import moment from "moment"
import ChevronDownIcon from "shared/components/icons/chevron_down_icon"
import ChevronUpIcon from "shared/components/icons/chevron_up_icon"

import "./step_details.scss"
import DelayIcon from "shared/components/icons/delay_icon"
import { formatToTimestamp } from "../diy_composer/utils/format_time"

const RunDetails = ({ stepFields, message, messageTitle }) => {
  if (stepFields?.length) {
    return stepFields.map(field => (
      <FieldDetails key={field.label} {...field} />
    ))
  } else if (message) {
    return (
      <div styleName="message">
        <div styleName="title">{messageTitle}</div>
        <div>{message}</div>
      </div>
    )
  } else {
    return "No details available"
  }
}

RunDetails.propTypes = {
  stepFields: PropTypes.array,
  message: PropTypes.string,
  messageTitle: PropTypes.string,
}

const FieldDetails = ({ label, value, unfilteredValue }) => (
  <div styleName="detail-pair">
    <dt>{label}</dt>
    <dd styleName={classNames({ empty: !value })}>
      <code>{value || "(none)"}</code>
      {unfilteredValue && (
        <div styleName="unfiltered">
          {`The original value "${unfilteredValue}" was updated by the filter.`}
        </div>
      )}
    </dd>
  </div>
)

FieldDetails.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  unfilteredValue: PropTypes.string,
}

const verbForOutcome = outcome => {
  switch (outcome) {
    case "success":
      return "ran"
    case "error":
      return "failed"
    case "abort":
      return "aborted"
    case "filter_skip":
      return "skipped"
  }
}

const StepDetails = ({
  brand_color,
  icon_url,
  finalStep,
  id,
  name,
  service_name,
  detailsForStep,
}) => {
  const [showDetails, setShowDetails] = useState(false)

  let stepType
  if (id.match(/^\/actions\//)) {
    stepType = "Action"
  } else if (id.match(/^\/triggers\//)) {
    stepType = "Trigger"
  } else if (id.match(/^\/queries\//)) {
    stepType = "Query"
  } else if (id.match(/^filter$/)) {
    stepType = "Filter Code"
  } else {
    stepType = "Delayed"
  }
  const timeString = detailsForStep?.ts
  const timestamp = timeString && moment(timeString).format("h:mm A")

  const subheaderName = () => {
    if (service_name === "Delay") {
      let timestamp = formatToTimestamp(detailsForStep.actions_delay)
      return `${timestamp} ${name}`
    } else {
      return name
    }
  }

  const message = detailsForStep?.message
  const messageTitle = detailsForStep?.message_title

  const didNotRun = !["success", "error"].includes(detailsForStep?.outcome)

  let stepFields
  if (detailsForStep?.ingredients?.length > 0) {
    stepFields = detailsForStep.ingredients
  } else if (detailsForStep?.query_ingredients?.length > 0) {
    stepFields = detailsForStep.query_ingredients
  } else if (detailsForStep?.action_fields?.length > 0) {
    stepFields = detailsForStep.action_fields
  } else {
    stepFields = detailsForStep?.fields
  }
  stepFields = stepFields?.flat()

  if (detailsForStep?.unfiltered_action_fields?.length > 0) {
    stepFields = stepFields?.map(field => {
      const unfilteredField = detailsForStep.unfiltered_action_fields.find(
        ({ label }) => label === field.label
      )
      const unfilteredValue =
        unfilteredField?.value !== field.value ? unfilteredField.value : null
      return { ...field, unfilteredValue }
    })
  }

  const outcomeVerb = verbForOutcome(detailsForStep?.outcome)
  const errorOutcome = ["error", "abort"].includes(detailsForStep?.outcome)

  const onClick = async () => {
    if (service_name === "Delay") return

    const toggledShow = !showDetails
    setShowDetails(toggledShow)
  }

  const renderChevron = service_name != "Delay"

  return (
    <div>
      <div role="button" styleName="overview" onClick={onClick}>
        <div styleName="step-name">
          <div styleName="icon" style={{ backgroundColor: brand_color }}>
            {service_name === "Delay" ? (
              <DelayIcon title={"delay icon"} alt="Clock icon" />
            ) : (
              <img src={icon_url} alt={`${service_name} icon`} />
            )}
          </div>
          <h4>{service_name}</h4>
        </div>
        {renderChevron ? (
          <div className="show-details">
            {showDetails ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        ) : null}
      </div>
      <div styleName={classNames("details-row", { open: showDetails })}>
        <div styleName={classNames("gutter", { guideline: !finalStep })} />
        <div styleName="details-container">
          <div onClick={onClick} styleName="subheader">
            <p className="txt-body-3">{subheaderName()}</p>
            <div styleName={classNames("timestamp", { error: errorOutcome })}>
              <div styleName={classNames("dot", { open: didNotRun })} />
              <span className="txt-body-3">
                {outcomeVerb ? (
                  <>
                    {service_name === "Delay" ? (
                      <>
                        {stepType}
                        {timestamp && ` until ${timestamp}`}
                      </>
                    ) : (
                      <>
                        {stepType} {outcomeVerb}
                        {timestamp && `, ${timestamp}`}
                      </>
                    )}
                  </>
                ) : (
                  <>Expand for details</>
                )}
              </span>
            </div>
          </div>
          {showDetails && detailsForStep && (
            <dl styleName="details">
              <RunDetails
                stepFields={stepFields}
                message={message}
                messageTitle={messageTitle}
              />
            </dl>
          )}
        </div>
      </div>
    </div>
  )
}

StepDetails.propTypes = {
  brand_color: PropTypes.string.isRequired,
  icon_url: PropTypes.string.isRequired,
  finalStep: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  service_name: PropTypes.string.isRequired,
  detailsForStep: PropTypes.shape({
    action_fields: PropTypes.array,
    fields: PropTypes.array,
    ingredients: PropTypes.array,
    query_ingredients: PropTypes.array,
    message: PropTypes.string,
    message_title: PropTypes.string,
    outcome: PropTypes.string,
    stepFields: PropTypes.array,
    ts: PropTypes.string,
    unfiltered_action_fields: PropTypes.array,
    actions_delay: PropTypes.number,
  }),
}

export default StepDetails
