import PropTypes from "prop-types"
import classnames from "classnames"

import { connectionShape } from "app/lib/shared_prop_types"
import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"
import XIcon from "shared/components/icons/x_icon"

import "./applet_card.scss"

export default function AppletCard({
  applet,
  availableToRestore,
  isPro,
  onRestoreApplet,
  subscriptionUrl,
}) {
  const blockProRestore = !isPro && applet.pro_features

  const handleOnClick = e => {
    e.preventDefault()

    if (!availableToRestore) return null

    if (blockProRestore) {
      window.open(subscriptionUrl, "_blank")
    } else {
      onRestoreApplet(applet)
    }
  }

  return (
    <li
      className="my-web-applet-card web-applet-card"
      styleName={classnames("web-applet-card", {
        disabled: !availableToRestore,
      })}
    >
      {blockProRestore && (
        <div styleName="pro-applet" onClick={handleOnClick}>
          <span styleName="icon">
            <XIcon
              width="33"
              height="33"
              color="rgba(0, 0, 0, 0.7)"
              strokeWidth="2.5"
            />
          </span>
          <span styleName="copy">
            Can’t restore Applet with Pro-only features
          </span>
        </div>
      )}
      <SmallAppletCard
        appletPath={blockProRestore ? subscriptionUrl : "#"}
        onAppletClick={handleOnClick}
        applet={applet}
        useDeactivatedColor={true}
        showConnectButton={false}
        showRestoreButton={true}
        availableToRestore={availableToRestore}
      />
    </li>
  )
}

AppletCard.propTypes = {
  applet: connectionShape,
  availableToRestore: PropTypes.bool,
  isPro: PropTypes.bool,
  onRestoreApplet: PropTypes.func.isRequired,
  subscriptionUrl: PropTypes.string,
}
