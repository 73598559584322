import PropTypes from "prop-types"

import AuthPageContent from "./auth_page_content"
import FooterLink from "./footer_link"
import SdkLayout from "./sdk_layout"
import WorksWith from "./works_with"

export default function AlreadySignedInPage({
  currentUserEmail,
  nextStepColor,
  nextStepLabel,
  nextStepUrl,
  services,
}) {
  return (
    <SdkLayout
      footer={<FooterLink label={nextStepLabel} backgroundColor={nextStepColor} href={nextStepUrl} fadeIn={true} />}
    >
      <WorksWith services={services} loggedIn={true} />
      <AuthPageContent>
        <p>
          You’re signed into IFTTT as <b>{currentUserEmail}</b>.
        </p>
      </AuthPageContent>
    </SdkLayout>
  )
}

AlreadySignedInPage.propTypes = {
  currentUserEmail: PropTypes.string.isRequired,
  nextStepColor: PropTypes.string,
  nextStepLabel: PropTypes.string.isRequired,
  nextStepUrl: PropTypes.string.isRequired,
  services: WorksWith.propTypes.services,
}
