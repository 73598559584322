import { useState, useEffect, MouseEventHandler } from "react"
import DelayIcon from "shared/components/icons/delay_icon"
import { formatToTimestamp } from "./utils/format_time"

import "./delay.scss"

const HOURS = ["00", "01", "02", "03"]
const MINUTES = ["00", "01", "02", "03", "04", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
const SECONDS = ["00", "15", "30", "45"]

interface Props {
  delay: number
  updateDelay: (delay: number) => void
}

export default function Delay({ delay, updateDelay }: Props) {
  const [submitDisabled, updateSubmitDisabled] = useState<boolean>(true)
  const [selectedHours, updateSelectedHours] = useState<string>("00")
  const [selectedMinutes, updateSelectedMinutes] = useState<string>("00")
  const [selectedSeconds, updateSelectedSeconds] = useState<string>("00")
  const TIME = {
    Hour: { list: HOURS, selection: selectedHours },
    Minutes: { list: MINUTES, selection: selectedMinutes },
    Seconds: { list: SECONDS, selection: selectedSeconds },
  }

  useEffect(() => {
    if (delay && delay > 0) {
      const timestamp = formatToTimestamp(delay).split(":")

      updateSelectedHours(timestamp[0])
      updateSelectedMinutes(timestamp[1])
      updateSelectedSeconds(timestamp[2])
    }
  }, [delay])

  useEffect(() => {
    if (convertTimestampToSeconds() > 10800 && selectedHours === "03") {
      updateSelectedMinutes("00")
      updateSelectedSeconds("00")
    }
  }, [selectedHours, selectedMinutes, selectedSeconds])

  const onClick = (event: MouseEventHandler<HTMLInputElement>): void => {
    event.preventDefault()
    const delay = convertTimestampToSeconds()

    updateSubmitDisabled(true)
    updateDelay(delay)
  }

  const convertTimestampToSeconds = () => {
    const hoursToSec = Number(selectedHours) * 3600
    const minsToSec = Number(selectedMinutes) * 60
    const sec = Number(selectedSeconds)
    const result = hoursToSec + minsToSec + sec
    return result
  }

  const handleInputChange = e => {
    const { name, value } = e.target as HTMLSelectElement

    updateSubmitDisabled(false)
    if (name === "Hour") {
      updateSelectedHours(value)
    } else if (name === "Minutes") {
      updateSelectedMinutes(value)
    } else {
      updateSelectedSeconds(value)
    }
  }

  return (
    <div styleName="delay-action" className="delay-action-editor">
      <span styleName="icon" className="hide-on-sm">
        <DelayIcon title="delay icon" />
      </span>

      <div styleName="delay-action-container">
        <form styleName="delay-action-form" id="delay-action-form">
          {Object.entries(TIME).map(([key, value]) => (
            <div key={key} styleName="input">
              <label htmlFor={key} className="label">
                {key}
              </label>
              <div>
                <select
                  name={key}
                  value={value.selection}
                  onChange={handleInputChange}
                  disabled={selectedHours == "03" && key !== "Hour"}
                >
                  {value.list.map(value => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                {key === "Seconds" ? null : <div styleName="separator">:</div>}
              </div>
            </div>
          ))}
        </form>
        <div styleName="cta">
          <button
            className="button-secondary"
            type="submit"
            form="delay-action-form"
            disabled={submitDisabled}
            onClick={onClick}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
