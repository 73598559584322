import PropTypes from "prop-types"
import classNames from "classnames"

import colors from "foundation/_colors_export.scss?variables"
import "./complete_icon.scss"

export default function CompleteIcon({ color, animate, large }) {
  const className = classNames("svg-icon", "svg-icon-checkmark")
  const polylineClassName = classNames("checkmark-check", { animate })
  const svgClassName = classNames({ "in-circle": large })

  return (
    <span className={className} styleName="icon">
      <svg
        styleName={svgClassName}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <g
          styleName="checkmark"
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="6"
        >
          <polyline
            styleName={polylineClassName}
            stroke={color === "black" ? colors.primaryTextColor : "#FFFFFF"}
            points="2.5 14.5 12 24 29.5 6.5"
          />
        </g>
      </svg>
    </span>
  )
}

CompleteIcon.propTypes = {
  color: PropTypes.oneOf(["black", "white"]),
}

CompleteIcon.defaultProps = {
  color: "black",
  large: false,
}
