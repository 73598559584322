import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import { ProfileHeader } from "./profile_header"
import { patch } from "shared/lib/api"
import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"
import iconChecked from "images/icons/icon-checked.svg"
import iconUnchecked from "images/icons/icon-unchecked.svg"

export default class ProfileProviderView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialProvider: this.props.profile.profile_provider,
      selectedProvider: this.props.profile.profile_provider,
      canSubmit: false,
    }
  }

  updateSelectedProvider = provider => {
    this.setState({
      selectedProvider: provider,
    })

    if (
      this.state.initialProvider &&
      this.state.initialProvider.module_name === provider.module_name
    ) {
      this.state.canSubmit && this.setState({ canSubmit: false })
      return
    }

    this.setState({
      canSubmit: true,
    })
  }

  submitProvider = () => {
    patch(
      this.props.updateProviderPath,
      {
        provider: this.state.selectedProvider.module_name,
      },
      { Accept: "text/javascript" }
    ).then(response => {
      if (response.redirect_to) {
        window.location.assign(response.redirect_to)
      } else if (response.message) {
        window.appendFlash(response.message, response.success ? "success" : "danger")
        if (response.success) {
          window.location.reload()
        }
      }
    })
    .catch(rejectedXHRPromiseLogger())
  }

  renderCheckboxItem = provider => {
    const checked =
      this.state.selectedProvider &&
      this.state.selectedProvider.name === provider.name

    return (
      <li
        key={provider.name}
        onClick={() => this.updateSelectedProvider(provider)}
        className={checked ? "checked" : "unchecked"}
      >
        <span>{provider.name}</span>
        <span className="checked-svg">
          {parse(checked ? iconChecked : iconUnchecked)}
        </span>
      </li>
    )
  }

  render() {
    return (
      <div className="provider-view">
        <ProfileHeader avatar={this.props.avatar} headerText="Choose profile">
          <span className="profile-label">
            Using
            <div className="provider-name">
              {this.props.profile.profile_provider
                ? this.props.profile.profile_provider.name
                : "None"}
            </div>
          </span>
        </ProfileHeader>
        <ul>
          {this.props.profileProviders.map((provider, idx) => {
            return this.renderCheckboxItem(provider)
          })}
        </ul>
        <div className="set-profile-actions">
          <button
            className="button-primary"
            onClick={this.submitProvider}
            disabled={!this.state.canSubmit}
          >
            Set profile
          </button>
        </div>
      </div>
    )
  }
}

ProfileProviderView.propTypes = {
  avatar: PropTypes.string,
  profile: PropTypes.object,
  profileProviders: PropTypes.array,
  updateProviderPath: PropTypes.string,
  backButtonPath: PropTypes.string.required,
  email: PropTypes.string,
  source: PropTypes.string,
}
