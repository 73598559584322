import PropTypes from "prop-types"

import AppletHeader from "./applet_header"
import SdkLayout from "./sdk_layout"
import FooterLink from "./footer_link"

import "./landing_page.scss"

export default function LandingPage({
  brandColor,
  description,
  iconUrl,
  name,
  nextStepUrl,
  worksWith,
}) {
  return (
    <SdkLayout
      backgroundColor={brandColor}
      footer={
        <FooterLink
          label="Continue"
          backgroundColor={brandColor == "#000000" ? "lighten" : "darken"}
          href={nextStepUrl}
        />
      }
    >
      <AppletHeader description={description} iconUrl={iconUrl} name={name} />

      {worksWith.length > 0 && (
        <p styleName="works-with">
          <span>works with</span>
          {worksWith.map((service, idx) => (
            <img
              key={idx}
              src={service.iconUrl}
              title={service.name}
              alt={service.name}
            />
          ))}
        </p>
      )}
    </SdkLayout>
  )
}

LandingPage.propTypes = {
  brandColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nextStepUrl: PropTypes.string.isRequired,
  worksWith: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      iconUrl: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
