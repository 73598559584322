import { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import throttle from "lodash/throttle"

import GlobalMeta from "shared/components/global_meta"
import GlobalStyle from "shared/components/global_style"

// This component implements a full-bleed layout on mobile and a centered
// layout on tablet and above.
export default class SdkLayout extends Component {
  state = {
    screenHeight: null,
    backgroundTransition: false,
  }

  UNSAFE_componentWillMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  componentDidUpdate(previousProps) {
    if (previousProps.backgroundColor !== this.props.backgroundColor) {
      this.setState({ backgroundTransition: true })
    }
  }

  handleResize = throttle(() => {
    this.setState({
      screenHeight: window.innerHeight,
    })
  }, 250)

  render() {
    const { backgroundColor, children, footer } = this.props
    const { screenHeight } = this.state

    const className = classNames("sdk-sdk-layout", {
      "sdk-sdk-layout-on-color": !!backgroundColor,
    })

    let globalStyle = ""

    // Once we know the screen height, we can replace the `100vh` in the
    // stylesheet with a specific pixel value. The reason this is necessary is
    // that the bottom chrome in iOS Safari is included in `100vh` but not in
    // `window.innerHeight`, so without this code the button would be hidden
    // beneath the bottom toolbar. :( :( :(
    if (screenHeight) {
      globalStyle = globalStyle.concat(`
        body,
        .sdk-sdk-layout,
        .sdk-sdk-layout-inner-container {
          min-height: ${screenHeight}px;
        }
      `)
    }

    // If this page is on-color, we need to make the progress bar white so that
    // it won't clash with the brand color. Also we need to give `body` the
    // appropriate background so that it extends off the screen if the user
    // does elastic scrolling.
    if (backgroundColor) {
      globalStyle = globalStyle.concat(`
        body {
          background-color: ${backgroundColor};
          ${
            this.state.backgroundTransition
              ? "transition: background-color .125s;"
              : ""
          }
        }
      `)
    }

    // Note that in addition to the global background color on body, we have to
    // put a background color on this div in order to cover up the
    // `<InfiniteFooter>` component, which is fixed to the bottom of the screen
    // with a negative z-index.
    return (
      <div className={className} style={{ backgroundColor }}>
        {globalStyle && <GlobalStyle content={globalStyle} />}
        {backgroundColor && (
          <GlobalMeta name="theme-color" content={backgroundColor} />
        )}

        <div className="sdk-sdk-layout-inner-container">
          {children}
          <div className="sdk-sdk-layout-spacer" />
          <div className="sdk-sdk-layout-footer">{footer}</div>
        </div>
      </div>
    )
  }
}

SdkLayout.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
}
