import { ReactElement, useState, useEffect } from "react"
import { useLocalStorage } from "shared/localstorage_helper"

import * as api from "./api"
import "./index.scss"

interface Member {
  email_for_user_groups: string
}

interface UserGroup {
  name: string
  guid: string
  members: Member[]
  invited_user_emails: string[]
}

const OwnedGroups = (props: { ownedGroupsInitial: UserGroup[] }): ReactElement | null => {
  const ownedGroups = props.ownedGroupsInitial
  const [storedGroupId, setStoredGroupId] = useLocalStorage(
    `selectedGroupGuid-${window.App.user?.id}`,
    ownedGroups[0]?.guid
  )
  const [selectedGroup, setSelectedGroup] = useState(() => {
    let group = ownedGroups.find((group: UserGroup) => group.guid === storedGroupId)

    if (!group) {
      group = ownedGroups[0]
      setStoredGroupId(group.guid)
    }

    return group
  })

  const [groupMembers, setGroupMembers] = useState(selectedGroup.members)
  const [groupInvitations, setGroupInvitations] = useState(selectedGroup.invited_user_emails)
  const [emailToBeInvited, setEmailToBeInvited] = useState("")

  const handleInvitationFormSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    inviteUser(emailToBeInvited)
  }

  const inviteUser = async (memberEmail: string) => {
    if (window.confirm(`Are you sure you want to invite ${memberEmail} to ${selectedGroup.name}?`)) {
      const {
        inviteUserToGroup: { errors },
      } = await api.inviteUser(memberEmail, selectedGroup.guid)

      if (errors.length !== 0) {
        let message = `There was an error inviting ${memberEmail} to ${selectedGroup.name}`

        if (errors[0].attribute === "user_email") {
          message = errors[0].message
        }

        window.appendFlash(message, "danger")
        return
      }

      if (groupInvitations.includes(memberEmail)) {
        // This is a re-invitation
        window.appendFlash(`${memberEmail} has been re-invited to ${selectedGroup.name}`)
      } else {
        // This is a brand new invitation
        setGroupInvitations([...groupInvitations, memberEmail])
        window.appendFlash(`${memberEmail} has been invited to ${selectedGroup.name}`, "invite-success")
      }

      setEmailToBeInvited("")
    }
  }

  const removeMember = async (memberEmail: string) => {
    if (window.confirm(`Are you sure you want to remove ${memberEmail} from this group?`)) {
      const {
        userGroupMemberDelete: { errors },
      } = await api.leaveGroup(selectedGroup.guid, memberEmail)

      if (errors.length !== 0) {
        window.appendFlash(`There was an error removing ${memberEmail} from this group`, "danger")
        return
      }

      setGroupMembers(selectedGroup.members.filter(member => member.email_for_user_groups !== memberEmail))

      window.appendFlash(`${memberEmail} has been removed from this group`)
    }
  }

  const rescindInvitation = async (memberEmail: string) => {
    if (window.confirm(`Are you sure you want to remove ${memberEmail}'s invitation to this group?`)) {
      const {
        userGroupInviteRescind: { errors },
      } = await api.rescindInvitation(selectedGroup.guid, memberEmail)

      if (errors.length !== 0) {
        window.appendFlash(`There was an error removing ${memberEmail}'s invitation to this group`, "danger")
        return
      }

      setGroupInvitations([...groupInvitations.filter(email_address => email_address !== memberEmail)])

      window.appendFlash(`${memberEmail}'s invitation to this group has been removed`)
    }
  }

  return (
    <div styleName="container">
      <h3>Your group</h3>
      <h2 styleName="standalone-group-name">{selectedGroup.name}</h2>

      <div className="ingredients-text-field">
        <label htmlFor="invite-via-email">
          <h5>Invite members by email address:</h5>
        </label>
        <form onSubmit={handleInvitationFormSubmit}>
          <input
            type="text"
            id="invite-via-email"
            name="Email address to invite to the group"
            placeholder="email@provider.com"
            value={emailToBeInvited}
            onChange={e => setEmailToBeInvited(e.target.value.trim())}
          />
          <div styleName="invite-button-container">
            <button type="submit" className="ingredients-button" disabled={!emailToBeInvited}>
              Invite
            </button>
          </div>
        </form>
      </div>

      <h5 styleName="section-header">Current members</h5>

      {[...groupInvitations, ...groupMembers].length ? null : <p>{selectedGroup.name} does not have any members.</p>}

      <ul>
        {groupInvitations.map(invitedMemberEmail => {
          return (
            <div key={invitedMemberEmail} className="ingredients-text-field">
              <li>
                <div styleName="email-address">{invitedMemberEmail}</div>
                <div styleName="pending">(pending)</div>
                <div>
                  <button
                    className="link-inline"
                    styleName="link-inline"
                    onClick={() => inviteUser(invitedMemberEmail)}
                  >
                    Resend
                  </button>
                  <button className="ingredients-button" onClick={() => rescindInvitation(invitedMemberEmail)}>
                    Cancel
                  </button>
                </div>
              </li>
            </div>
          )
        })}
      </ul>

      {groupMembers.length ? (
        <ul>
          {groupMembers.map(member => {
            return (
              <div key={member.email_for_user_groups} className="ingredients-text-field">
                <li>
                  <div styleName="email-address">{member.email_for_user_groups}</div>
                  <div>
                    <button className="ingredients-button" onClick={() => removeMember(member.email_for_user_groups)}>
                      Remove
                    </button>
                  </div>
                </li>
              </div>
            )
          })}
        </ul>
      ) : null}

      {groupInvitations.length ? (
        <p className="txt-caption">
          For security purposes, invitations will expire after 48 hours. You can cancel the invite or resend a new one
          from this page at any time.
        </p>
      ) : null}

      <hr />
    </div>
  )
}

const GroupInfo = (props: { groupName: string; ownerEmailAddress: string }): ReactElement | null => {
  return (
    <div key={props.groupName} className="member-group">
      <h4>{props.groupName}</h4>
      <p className="txt-caption">Created by {props.ownerEmailAddress}</p>
    </div>
  )
}

interface Props {
  currentUserEmail: string
  currentUserHasMobileApp: boolean
  groupGuidToAccept: string
  memberGroups: UserGroup[]
  ownedGroups: UserGroup[]
  pendingGroups: UserGroup[]
}

const UserGroups = (props: Props): ReactElement | null => {
  // After the component renders:
  // 1. Don't persist the anchor (ex. #pending-invitations) if there is one
  // 2. If there's no invitation to accept, be on your merry way.
  // 3. If there's an invitation to accept, accept it!
  useEffect(() => {
    if (props.groupGuidToAccept) {
      respondToInvite(props.groupGuidToAccept, true)
    }
  }, [])

  const [memberGroups, setMemberGroups] = useState(props.memberGroups)
  const [pendingGroups, setPendingGroups] = useState(props.pendingGroups)

  const leaveGroup = async (groupGuid: string) => {
    if (window.confirm("Are you sure you want to leave this group?")) {
      const {
        userGroupMemberDelete: { errors },
      } = await api.leaveGroup(groupGuid, props.currentUserEmail)

      if (errors.length !== 0) {
        window.appendFlash("There was an error leaving that group.", "danger")
        return
      }

      setMemberGroups(memberGroups.filter((group: UserGroup) => group.guid !== groupGuid))

      window.appendFlash("You are no longer a member of that group.")
    }
  }

  const shiftGroupFromPendingToMember = groupGuid => {
    console.log(`Shift group ${groupGuid}`)

    const newGroup = pendingGroups.find((group: UserGroup) => group.guid === groupGuid)

    setMemberGroups([...memberGroups, newGroup])
    setPendingGroups(pendingGroups.filter((group: UserGroup) => group.guid !== groupGuid))
  }

  const respondToInvite = async (groupGuid: string, accepted: boolean) => {
    if (!props.currentUserHasMobileApp && accepted) {
      window.appendFlash("Download the IFTTT app before accepting the invitation", "danger")
      return
    }

    if (window.confirm(`Are you sure you want to ${accepted ? "accept" : "decline"} this invitation?`)) {
      const {
        userGroupInviteRespond: { errors },
      } = await api.respondToInvitation(groupGuid, accepted)

      if (errors.length !== 0) {
        window.appendFlash("There was an error responding to that invitation.", "danger")
        return
      }

      if (accepted) {
        shiftGroupFromPendingToMember(groupGuid)
        window.appendFlash("Invitation accepted")
      } else {
        setPendingGroups(pendingGroups.filter((group: UserGroup) => group.guid !== groupGuid))
        window.appendFlash("Invitation declined")
      }
    }
  }

  return (
    <div styleName="container">
      <header>
        <h1>Manage Group</h1>
      </header>

      {props.ownedGroups.length ? <OwnedGroups ownedGroupsInitial={props.ownedGroups} /> : null}

      {memberGroups.length ? (
        <>
          <h3 styleName="section-header">Member Groups</h3>
          <ul>
            {memberGroups.map(group => (
              <li key={group.guid}>
                <div className="ingredients-text-field">
                  <div styleName="member-row">
                    <GroupInfo groupName={group.name} ownerEmailAddress={group.owner.email_for_user_groups} />

                    <button className="ingredients-button" onClick={() => leaveGroup(group.guid)}>
                      Leave group
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : null}

      {pendingGroups.length ? (
        <>
          <h3 styleName="section-header" id="pending-invitations">
            Pending invitations
          </h3>
          <ul>
            {pendingGroups.map(group => (
              <li key={group.guid}>
                <div className="ingredients-text-field">
                  <div styleName="member-row">
                    <GroupInfo groupName={group.name} ownerEmailAddress={group.owner.email_for_user_groups} />

                    <div>
                      <button
                        className="link-inline"
                        styleName="link-inline"
                        onClick={() => respondToInvite(group.guid, false)}
                      >
                        Decline
                      </button>
                      <button className="ingredients-button" onClick={() => respondToInvite(group.guid, true)}>
                        Accept
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  )
}

export default UserGroups
