import { useEffect, useState } from "react"
import moment from "moment"
import classNames from "classnames"
import { Permissions } from "types"
import { saveUserPermissions } from "shared/scripts/new_badge_button"
import { captureMessage } from "@sentry/react"

import "./success.scss"

interface URLs {
  continuePath: string
  explore: string
}

interface Subscription {
  plan: "intermediate_pro" | "pro"
  isTrialing: boolean
  expiresAt: number
}

interface Props {
  permissions: Permissions
  subscriptionMobileSuccessPath: string
  subscription: Subscription
  urls: URLs
}

export default function Success({ permissions, subscriptionMobileSuccessPath, subscription, urls }: Props) {
  const view = {
    INITIAL: 0,
    FIRST_FEATURE: 1,
    SECOND_FEATURE: 2,
    THIRD_FEATURE: 3,
    LAST_FEATURE: 4,
  }

  const [currentView, updateView] = useState(view["INITIAL"])

  useEffect(() => {
    saveUserPermissions(subscription.plan, permissions)
  }, [])

  const sharedSupportView = {
    title: "Customer support",
    copy: "Get friendly, personalized, and technical help with your Applets.",
    imageTitle: "IFTTT Pro illustration",
  }

  let successCopy = ""
  const referenceDate = moment.utc(new Date(subscription.expiresAt))

  if (referenceDate.isValid()) {
    const formattedDate = referenceDate.format("LL")
    successCopy = subscription.isTrialing
      ? `Your first billing period begins after your free trial ends on ${formattedDate}.`
      : `Your subscription is active and will renew on ${formattedDate}.`
  } else {
    captureMessage("Invalid subscription expiration date", {
      extra: {
        subscription,
      },
    })
    successCopy = subscription.isTrialing
      ? "Your first billing period will begin after your free trial ends."
      : "Your subscription is active."
  }

  const sharedCopySuccessView = {
    title: "Success!",
    copy: successCopy,
  }

  const intermediate_pro_views = {
    [view["INITIAL"]]: {
      objectId: "pro_success",
      subTitle: "You have access to IFTTT Pro!",
      imageTitle: "IFTTT Pro illustration",
      ...sharedCopySuccessView
    },
    [view["FIRST_FEATURE"]]: {
      objectId: "pro-multi-action",
      title: "Multi-action Applets",
      copy: "Do more with fewer Applets using multiple actions. Create a nighttime Applet that turns off your lights and locks your doors.",
      imageTitle: "IFTTT Pro illustration",
    },
    [view["SECOND_FEATURE"]]: {
      objectId: "pro-customer-support",
      ...sharedSupportView
    },
    [view["THIRD_FEATURE"]]: {
      objectId: "pro-faster-applets",
      title: "Faster Applets",
      copy: "With Pro, polling Applets run every five minutes so you always receive timely updates.",
      imageTitle: "IFTTT Pro illustration",
    },
    [view["LAST_FEATURE"]]: {
      objectId: "pro-total-applets",
      title: "20 Applets",
      copy: "You can now create up to 20 Applets. Get started with your first Pro Applet now!",
      imageTitle: "IFTTT Pro illustration",
    }
  }

  const pro_views = {
    [view["INITIAL"]]: {
      objectId: "pro-plus-success",
      subTitle: "You have access to IFTTT Pro+!",
      imageTitle: "IFTTT Pro illustration",
      ...sharedCopySuccessView
    },
    [view["FIRST_FEATURE"]]: {
      objectId: "pro-plus-filter-code",
      title: "Filter code and queries",
      copy: "Schedule your Applets to run during certain hours and create additional conditions. You are in complete control of your Applets.",
      imageTitle: "IFTTT Pro illustration",
    },
    [view["SECOND_FEATURE"]]: {
      objectId: "pro-plus-multiple-accounts",
      title: "Multiple accounts",
      copy: "Add and manage multiple accounts per service with Pro+. Use one account to create Applets for personal and professional uses.",
      imageTitle: "IFTTT Pro illustration",
    },
    [view["THIRD_FEATURE"]]: {
      objectId: "pro-plus-customer-support",
      ...sharedSupportView
    },
    [view["LAST_FEATURE"]]: {
      objectId: "pro-plus-unlimited-applets",
      title: "Unlimited Applets",
      copy: "Unlimited Applets mean unlimited possibilities. Create your first Pro+ Applet now!",
      imageTitle: "IFTTT Pro illustration",
    }
  }

  let views = {}
  if (subscription.plan === "intermediate_pro") {
    views = intermediate_pro_views
  } else {
    views = pro_views
  }

  const visit = link =>  window.location.assign(link)
  const nextView = () => views[currentView+1]

  const handleContinueClick = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (!nextView()) {
      // last view
      const continueDestination = subscriptionMobileSuccessPath || sessionStorage.getItem("afterProPaymentPath") || urls.continuePath
      sessionStorage.removeItem("afterProPaymentPath")
      visit(continueDestination)
    } else {
      updateView(currentView + 1)
    }
  }

  const onSkip = () => {
    const continueDestination = subscriptionMobileSuccessPath || sessionStorage.getItem("afterProPaymentPath") || urls.continuePath
    sessionStorage.removeItem("afterProPaymentPath")
    visit(continueDestination)
  }

  const progressBarBuild = (styleName, className)  => (
    <div styleName={styleName} className={className}>
      <span styleName={classNames({active: currentView == view["INITIAL"]})}></span>
      <span styleName={classNames({active: currentView == view["FIRST_FEATURE"]})}></span>
      <span styleName={classNames({active: currentView == view["SECOND_FEATURE"]})}></span>
      <span styleName={classNames({active: currentView == view["THIRD_FEATURE"]})}></span>
      <span styleName={classNames({active: currentView == view["LAST_FEATURE"]})}></span>
    </div>
  )

  return (
    <section styleName="payment-success-container">
      <section
        styleName="success-illustration"
        className={`success-illustration-${subscription.plan}-${currentView}`}
        title="Success!"
        alt={views[currentView].imageTitle}
      />
      <section styleName="payment-content">
        {progressBarBuild("progress-bar", "hide-on-sm")}
        <h1>{views[currentView].title}</h1>
        {views[currentView]?.subTitle && <p>{views[currentView].subTitle}</p>}
        <p>{views[currentView].copy}</p>

        <div styleName="tablet-container">
          <div
            className="action-buttons-container"
            styleName={classNames("action-buttons-container", { "one_action": !nextView() })}
          >
            <button
              className="button-primary"
              title="Continue"
              aria-label="Continue"
              onClick={handleContinueClick}
            >
              Continue
            </button>

            <button
              className="button-tertiary button-cancel-link"
              title="Skip"
              aria-label="Skip"
              onClick={onSkip}
            >
              Skip
            </button>
          </div>

          {progressBarBuild("progress-bar show-on-tablet")}
        </div>
      </section>
    </section>
  )
}
