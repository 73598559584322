import workTogetherImg from "images/web/access_applet_card/info-drawer-bullet-work-together.svg"
import controlImg from "images/web/access_applet_card/info-drawer-bullet-control.svg"
import manageImg from "images/web/access_applet_card/info-drawer-bullet-manage.svg"
import staySecureImg from "images/web/access_applet_card/info-drawer-bullet-stay-secure.svg"
import closeImg from "images/web/access_applet_card/close.svg"
import iftttLogo from "images/logo-ifttt.svg"
import iftttLogoWhite from "images/logo-ifttt-white.svg"
import arrow from "images/web/access_applet_card/arrow.svg"
import installCount from "images/web/applet_card/user-icon.svg"
import plug from "images/icons/icon-plug.svg"
import lock from "images/icons/icon-lock.svg"
import toggleLeft from "images/icons/icon-toggle-left.svg"
import twoSquares from "images/icons/icon-two-squares.svg"
import appleAppStore from "images/icons/apple_appstore_download.svg"
import googlePlayStore from "images/icons/google_play_store_download.svg"

export default {
  workTogetherImg,
  controlImg,
  manageImg,
  staySecureImg,
  iftttLogo,
  iftttLogoWhite,
  closeImg,
  installCount,
  arrow,
  plug,
  lock,
  toggleLeft,
  twoSquares,
  appleAppStore,
  googlePlayStore,
}
