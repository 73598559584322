import { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import StoryField from "./story_field"
import "./story.scss"
import { fullStoryShape } from "app/lib/shared_prop_types"

export default class IFTTTNextStory extends Component {
  render() {
    const {
      story: { discoverable, fields },
      linkAppletCardsToConsumerSite,
    } = this.props

    return (
      <>
        <div
          className="story-content"
          styleName={classnames({
            // so that main css can redefine the main story container's width
            "story-content": true,
            "non-discoverable-content": discoverable === true,
          })}
        >
          <ul styleName="story-fields">
            {fields.map((field, idx) => (
              <StoryField
                key={idx}
                fieldPosition={idx}
                field={field}
                linkAppletCardsToConsumerSite={linkAppletCardsToConsumerSite}
              />
            ))}
          </ul>
        </div>
      </>
    )
  }
}

IFTTTNextStory.propTypes = {
  story: fullStoryShape.isRequired,
  linkAppletCardsToConsumerSite: PropTypes.bool,
}

IFTTTNextStory.defaultProps = {
  linkAppletCardsToConsumerSite: false,
}
