import debounce from "lodash/debounce"

export default (minWidth, setter) => {
  const debouncedMediaMatcher = debounce(
    () => setter(window.matchMedia(`(min-width: ${minWidth}px)`)?.matches),
    500
  )
  window.addEventListener("resize", debouncedMediaMatcher)

  return () => {
    debouncedMediaMatcher.cancel()
    window.removeEventListener("resize", debouncedMediaMatcher)
  }
}
