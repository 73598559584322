import { useEffect, useState } from "react"
import { fetchRecommendedApplets } from "app/components/new_user_intake/api"
import AppletCard from "./applet_card"
import Modal from "shared/components/modal"

import "./empty_state_recommendations.scss"

const maxModalWidth = 935
const maxModalHeight = 517

interface Props {
  createPath: string
}

const EmptyStateRecommendations = ({ createPath }: Props) => {
  const [recommendedApplets, setRecommendedApplets] = useState<any[]>([])
  const [show, setShow] = useState<boolean>(false)
  const widthMatcher = window.matchMedia(`(max-width: ${maxModalWidth}px)`)
  const heightMatcher = window.matchMedia(`(max-height: ${maxModalHeight}px)`)
  const [isNarrow, setNarrow] = useState(widthMatcher?.matches)
  const [isShort, setShort] = useState(heightMatcher?.matches)
  useEffect(() => {
    const effect = async () => {
      setRecommendedApplets((await fetchRecommendedApplets()).slice(0, 3))
    }
    effect()
  }, [])

  useEffect(() => {
    const widthListener = (event: { matches: any }) => setNarrow(event.matches)
    const heightListener = (event: { matches: any }) => setShort(event.matches)
    widthMatcher?.addEventListener("change", widthListener)
    heightMatcher?.addEventListener("change", heightListener)

    return () => {
      widthMatcher?.removeEventListener("change", widthListener)
      heightMatcher?.removeEventListener("change", heightListener)
    }
  }, [])

  let width = maxModalWidth
  let height = maxModalHeight
  if (isNarrow && isShort) {
    height = window.innerHeight - 160
    // try to have the correct aspect ratio, fill available height, or just make it wide enough to include controls
    width = Math.max(Math.min(height * (16 / 9), window.innerWidth), 300)
  } else if (isNarrow) {
    width = window.innerWidth
    height = width * (9 / 16)
  }

  return (
    <div styleName="empty-state-recommendations">
      <Modal show={show} closeListener={() => setShow(false)}>
        <iframe
          width={width}
          height={height}
          src="https://www.youtube.com/embed/kJxSWfn62Oc"
          title="Create your own Applet demo - YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
      <section className="dashboard-applets">
        <h1>Start connecting your world</h1>
        <p className="txt-body-3">
          Save time and money by making the internet work for you! We believe
          you might like...
        </p>
        <ul className="web-applet-cards" styleName="recommendations">
          {recommendedApplets.map(applet => (
            <AppletCard key={applet.id} applet={applet} />
          ))}
        </ul>
        <a role="button" className="button-secondary" href={createPath}>
          Create your own
        </a>
        <p className="txt-body-3">
          Or get an overview by watching a{" "}
          <a
            className="link-inline"
            styleName="link-inline"
            onClick={() => setShow(true)}
          >
            quick demo
          </a>
        </p>
      </section>
    </div>
  )
}

export default EmptyStateRecommendations
