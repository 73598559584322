import PropTypes from "prop-types"

import { serviceShape, tqaShape } from "app/lib/shared_prop_types"

import "./header.scss"

const TQAHeader = ({ service, tqa, servicePath, serviceImg }) => {
  return (
    <section className="service-header tqa-header">
      <div className="brand-section" styleName="brand-section">
        <a href={servicePath} title={service.name} rel="noreferrer" styleName="brand-link">
          <img
            styleName="small-service-logo"
            src={serviceImg}
            alt={service.name}
            title={service.name}
            width="32"
            height="32"
          />
          {service.name}
        </a>
        <h1>{tqa.name}</h1>
      </div>
    </section>
  )
}

TQAHeader.propTypes = {
  service: serviceShape.isRequired,
  tqa: tqaShape.isRequired,
  servicePath: PropTypes.string.isRequired,
  serviceImg: PropTypes.string.isRequired,
}

export default TQAHeader
