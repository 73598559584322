import { useState } from "react"
import { graphQueryBang, gql } from "shared/lib/graphql"
import UpgradePlanModal from "./upgrade_plan_modal"

interface Props {
  user: object
  eligibleForTrial: boolean
  eligibleTrialPeriod: number
  proPlusProductId: string
  serviceName: string
  subscriptionPaymentType: string
  isTrialing: boolean
  urls: { [key: string]: string }
}

const GatedServiceAuthButton = ({
  user,
  eligibleForTrial,
  eligibleTrialPeriod,
  proPlusProductId,
  serviceName,
  subscriptionPaymentType,
  isTrialing,
  urls: { redirectToConnect, newProSubscriptionUrl, modalSubscriptionUrl },
}: Props) => {
  const [showModal, setShowModal] = useState(false)

  const onUpgrade = () => {
    graphQueryBang(userQuery, {}).then((res: { me: { tier: string } }) => {
      if (res.me.tier !== "free") {
        connect()
      }
    })
  }

  const connect = () => (window.location = redirectToConnect)

  return (
    <>
      <UpgradePlanModal
        onClose={() => setShowModal(false)}
        onUpgrade={onUpgrade}
        premiumService={serviceName}
        proPlusProductId={proPlusProductId}
        urls={{
          newProSubscriptionUrl,
          modalSubscriptionUrl,
        }}
        step="pro_service_tqas"
        tierRequirement="intermediate_pro"
        user={{
          eligibleForTrial,
          eligibleTrialPeriod,
          isTrialing,
          subscriptionPaymentType,
          tier: user.tier,
        }}
        visible={showModal}
      />
      <a
        href="#"
        onClick={() => setShowModal(true)}
        className="button-secondary button-on-color"
        title="Connect"
      >
        Connect
      </a>
    </>
  )
}

const userQuery = gql`
  query userQuery {
    me {
      tier
    }
  }
`

export default GatedServiceAuthButton
