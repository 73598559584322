import "./pro_feature_check.scss"

interface Props {
  billingUrl: string
  expirationDate: string
  maxFreeApplets: string
  moveNextView: func
  tierName: string
}

const ProFeatureCheck = ({ billingUrl, expirationDate, maxFreeApplets, moveNextView, tierName }: Props) => {
  const proFeatures = (
    <>
      <li>20 Applets</li>
      <li>Twitter, Webhooks, SMS, and Phone Call services</li>
      <li>Multiple actions</li>
      <li>Customer support</li>
      <li>Increased rate limits and Applet speeds</li>
    </>
  )

  const proPlusFeatures = (
    <>
      <li>Unlimited Applets</li>
      <li>Queries and filter code</li>
      <li>AI, Twitter, Webhooks, SMS, and Phone Call services</li>
      <li>Multiple actions</li>
      <li>Prioritized customer support</li>
      <li>Increased rate limits and Applet speeds</li>
      <li>Multiple accounts per service</li>
    </>
  )

  return (
    <div styleName="pro_feature_check">
      <h1 className="mt4 mb4 mb2--phone mb2--small-phone">Are you sure?</h1>

      <p className="txt-body-2 mb8 m0--phone">
        On {expirationDate} you will be moved to our free plan, which is limited to {maxFreeApplets} Applets. You will
        lose access to all subscription benefits and any future subscriptions will be at our latest pricing. Your
        current subscription benefits include:
      </p>

      <ul styleName="feature_list" className="p4">
        {tierName === "Pro+" ? proPlusFeatures : proFeatures}
      </ul>

      <div className="cancellation-actions">
        <a className="button-primary" href={billingUrl}>
          Stay {tierName}
        </a>
        <a className="link-standalone mt4" onClick={moveNextView}>
          Continue with cancellation
        </a>
      </div>
    </div>
  )
}

export default ProFeatureCheck
