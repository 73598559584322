import PropTypes from "prop-types"
import ErrorIcon from "shared/components/icons/error_icon"
import get from "lodash/get"
import parse from "html-react-parser"

import "./error_message.scss"

const ERROR_TRANSLATIONS = {
  "Default value can't be blank when the field is required and hidden": "Field cannot be blank",
}

export default function ErrorMessageForField({ field, errors }) {
  const untranslatedMessage = get(errors, field)
  const errorMessage = ERROR_TRANSLATIONS[untranslatedMessage] || untranslatedMessage

  if (!errorMessage || errorMessage.length === 0) {
    return null
  }

  const messages = Array.isArray(errorMessage) ? errorMessage : [errorMessage]

  return (
    <>
      {messages.map((msg, index) => (
        <span key={index} styleName="error-message">
          <ErrorIcon width="24" height="24" />
          <span>{parse(msg)}</span>
        </span>
      ))}
    </>
  )
}

ErrorMessageForField.propTypes = {
  field: PropTypes.string.isRequired,
  errors: PropTypes.object,
}
