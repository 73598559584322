import { gql } from "shared/lib/graphql"
import { LiveChannelsPartialQuery } from "shared/components/stored_fields/service_account_select_field/queries.js"

export const DIYLoadServiceTriggersQuery = gql`
  query DIYLoadServiceTriggersQuery($serviceModuleName: String!) {
    channel(module_name: $serviceModuleName) {
      ${LiveChannelsPartialQuery}
      public_triggers {
        module_name
        full_module_name
        normalized_module_name
        name
        description
        id
        weight
        tier
      }
    }
  }
`

export const DIYLoadTriggerFieldsWithIngredientsQuery = gql`
  query DIYLoadTriggerFieldsWithIngredientsQuery($id: Int) {
    trigger(id: $id) {
      trigger_fields {
        name
        label
        required
        shareable
        hideable
        field_ui_type
        normalized_field_type
        helper_text
      }
      ingredients {
        id
        name
        normalized_name
        label
        note
        slug
        value_type
        example
      }
    }
  }
`

export const DIYLoadServiceActionsQuery = gql`
  query DIYLoadServiceActionsQuery($serviceModuleName: String!) {
    channel(module_name: $serviceModuleName) {
      ${LiveChannelsPartialQuery}
      public_actions {
        module_name
        full_module_name
        normalized_module_name
        name
        description
        id
        weight
        incompatible_triggers
        tier
      }
    }
  }
`

export const DIYLoadActionFields = gql`
  query DIYLoadActionFields($id: Int) {
    action(id: $id) {
      action_fields {
        name
        normalized_module_name
        label
        required
        shareable
        hideable
        field_ui_type
        normalized_field_type
        helper_text
      }
    }
  }
`

export const DIYLoadServiceQueriesQuery = gql`
  query DIYLoadServiceQueriesQuery($serviceModuleName: String!) {
    channel(module_name: $serviceModuleName) {
      ${LiveChannelsPartialQuery}
      public_queries {
        module_name
        full_module_name
        normalized_module_name
        name
        description
        id
        weight
        tier
      }
    }
  }
`

export const DIYLoadQueryFieldsWithIngredientsQuery = gql`
  query DIYLoadQueryFieldsWithIngredientsQuery($id: Int) {
    query(id: $id) {
      query_fields {
        name
        label
        required
        shareable
        hideable
        field_ui_type
        normalized_field_type
        helper_text
      }
      ingredients {
        id
        name
        normalized_name
        label
        note
        slug
        value_type
        example
      }
    }
  }
`

export const FilterCodeGeneratorsQuery = gql`
  query FilterCodeGeneratorsQuery($triggerIds: [String]!, $queryIds: [String]!, $actionIds: [String]!) {
    filter_code_generators(trigger_ids: $triggerIds, query_ids: $queryIds, action_ids: $actionIds) {
      id
      description
      helper_text
      templates {
        action
        variations {
          code
          validation
        }
      }
      title
      type
      variables {
        term
        type
        options {
          label
          value
        }
      }
    }
  }
`

export const AppletTriggerFieldValidationMutation = gql`
  mutation AppletTriggerFieldValidationMutation($trigger: DiyStepValidationInput, $applet_id: String) {
    appletStepFieldValidation(input: { trigger: $trigger, applet_id: $applet_id }) {
      errors {
        attribute
        message
      }
    }
  }
`

export const AppletQueryFieldValidationMutation = gql`
  mutation AppletQueryFieldValidationMutation(
    $trigger: DiyStepValidationInput
    $query: DiyStepValidationInput
    $applet_id: String
  ) {
    appletStepFieldValidation(input: { trigger: $trigger, query: $query, applet_id: $applet_id }) {
      errors {
        attribute
        message
      }
    }
  }
`

export const AppletActionFieldValidationMutation = gql`
  mutation AppletActionFieldValidationMutation(
    $trigger: DiyStepValidationInput
    $queries: [DiyStepValidationInput]
    $action: DiyStepValidationInput
    $applet_id: String
  ) {
    appletStepFieldValidation(input: { trigger: $trigger, queries: $queries, action: $action, applet_id: $applet_id }) {
      errors {
        attribute
        message
      }
    }
  }
`

export const StatementPreviewMutation = gql`
  mutation StatementPreviewMutation($stored_fields: JSON) {
    statementPreview(input: { stored_fields: $stored_fields }) {
      normalized_applet {
        name
        brand_color
        service_slug
      }
      errors {
        attribute
        message
      }
    }
  }
`

export const AutoActivateMutation = gql`
  mutation AutoActivateMutation($serviceModuleName: String!) {
    liveChannelCreate(input: { channel_module_name: $serviceModuleName }) {
      live_channel {
        offline
      }
      errors {
        attribute
        message
      }
    }
  }
`

export const DIYCreateAppletMutation = gql`
  mutation DIYCreateAppletMutation(
    $name: String!
    $description: String
    $channel_id: ID!
    $filter_code: String
    $trigger: DiyTandaInput!
    $queries: [DiyTandaInput]
    $actions: [DiyTandaInput]!
    $actions_delay: Int
  ) {
    diyAppletCreate(
      input: {
        name: $name
        description: $description
        channel_id: $channel_id
        filter_code: $filter_code
        trigger: $trigger
        queries: $queries
        actions: $actions
        actions_delay: $actions_delay
      }
    ) {
      errors {
        attribute
        message
      }
      normalized_applet {
        id
      }
    }
  }
`

export const DIYAppletEditMutation = gql`
  mutation DIYAppletEditMutation(
    $applet_id: String!
    $name: String!
    $description: String
    $channel_id: ID!
    $filter_code: String
    $trigger: DiyTandaInput!
    $queries: [DiyTandaInput]
    $actions: [DiyTandaInput]!
    $actions_delay: Int
  ) {
    diyAppletEdit(
      input: {
        applet_id: $applet_id
        name: $name
        description: $description
        channel_id: $channel_id
        filter_code: $filter_code
        trigger: $trigger
        queries: $queries
        actions: $actions
        actions_delay: $actions_delay
      }
    ) {
      errors {
        attribute
        message
      }
      normalized_applet {
        id
      }
    }
  }
`

export const AppletFilterCodeValidationMutation = gql`
  mutation AppletFilterCodeValidationMutation(
    $filter_code: String!
    $trigger: DiyStepValidationInput
    $queries: [DiyStepValidationInput]
    $actions: [DiyStepValidationInput]
  ) {
    appletFilterCodeValidation(
      input: { filter_code: $filter_code, trigger: $trigger, queries: $queries, actions: $actions }
    ) {
      errors {
        attribute
        message
      }
    }
  }
`
export const NormalizedAppletEnableMutation = gql`
  mutation NormalizedAppletEnableMutation(
    $applet_id: String
    $name: String
    $stored_fields: JSON
    $metadata: JSON
    $filter_code: String
    $actions_delay: Int
  ) {
    normalizedAppletEnable(
      input: {
        applet_id: $applet_id
        name: $name
        stored_fields: $stored_fields
        metadata: $metadata
        filter_code: $filter_code
        actions_delay: $actions_delay
      }
    ) {
      errors {
        attribute
        message
      }
      user_token
      normalized_applet {
        id
      }
    }
  }
`

export const LoadPrivateServicesQuery = gql`
  query LoadPrivateServicesQuery {
    me {
      private_channels {
        id
        text_only_description
        description_html
        module_name
        normalized_module_name
        name
        lrg_variant_image_url
        lrg_monochrome_image_url
        brand_color
        incompatible_channels
        allow_multiple_live_channels
        tags
        category {
          name
        }
        public_triggers: triggers {
          module_name
          full_module_name
          name
          description
          id
          weight
          tier
        }
        public_actions: actions {
          module_name
          full_module_name
          name
          description
          id
          weight
          incompatible_triggers
          tier
        }
        public_queries: queries {
          module_name
          full_module_name
          name
          description
          id
          weight
          tier
        }
      }
    }
  }
`

export const DiyAppletAIMakerCreateMutation = gql`
  mutation diyAppletAIMakerCreate(
    $name: String!
    $trigger_module_name: String!
    $queries_module_name: [String!]
    $actions_module_name: [String!]!
  ) {
    diyAppletAIMakerCreate(
      input: {
        name: $name
        trigger_module_name: $trigger_module_name
        queries_module_name: $queries_module_name
        actions_module_name: $actions_module_name
      }
    ) {
      errors {
        attribute
        message
      }
      normalized_applet {
        id
        permissions {
          channel {
            module_name
            connected
          }
        }
      }
    }
  }
`

export const AIAppletSuggestionQuery = gql`
  query AIAppletSuggestionQuery($user_description: String!) {
    ai_applet_suggestion(user_description: $user_description) {
      trigger {
        full_module_name
        name
        tier
        channel {
          brand_color
          monochrome_image_url
        }
      }
      queries {
        full_module_name
        name
        tier
        channel {
          brand_color
          monochrome_image_url
        }
      }
      actions {
        full_module_name
        name
        tier
        channel {
          brand_color
          monochrome_image_url
        }
      }
      enablement_rules {
        block_user
        minimum_tier
        block_reason
      }
      errors {
        attribute
        message
      }
    }
  }
`
