import { Component } from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Description from "./description"
import Footer from "./footer"

import ConnectionStoredFieldsForm from "shared/components/connection_card/config/connection_stored_fields_form"

import isEmpty from "lodash/isEmpty"

const noop = () => {}

export default class ConnectSDKAppletConfig extends Component {
  state = {
    enabled: false,
  }

  onSaveClicked = () => {
    this.storedFieldsForm.onSubmit()
  }

  onFormEnabled = () => {
    this.setState({ enabled: true })
  }

  onFormDisabled = () => {
    this.setState({ enabled: false })
  }

  render() {
    return (
      <div className="connection-config-editor">
        <Header
          mainService={this.props.mainService}
          connection={{
            brand_color: "#fff",
            name: this.props.applet.name,
            type: this.props.applet.type,
          }}
          updateURL={this.props.submitUrl}
          icons={this.props.icons}
          programmable={this.props.programmable}
        />

        {this.props.applet.description && (
          <Description text={this.props.applet.description} />
        )}

        {!isEmpty(this.props.storedFields) && (
          <>
            <hr />
            <div className="connection-fields-form">
              <ConnectionStoredFieldsForm
                ref={r => (this.storedFieldsForm = r)}
                onFormEnabled={this.onFormEnabled}
                onFormDisabled={this.onFormDisabled}
                clearLocalStorage={noop} // not needed for SDK
                connectionFinishedUrl={this.props.connectionFinishedUrl}
                {...this.props}
              />
            </div>
          </>
        )}

        <Footer
          enabled={this.state.enabled}
          includeSubmit={!isEmpty(this.props.storedFields)}
          onSaveClicked={this.onSaveClicked}
        />
      </div>
    )
  }
}

ConnectSDKAppletConfig.propTypes = {
  user: PropTypes.object,
  applet: PropTypes.object,
  programmable: PropTypes.bool.isRequired,
  mainService: PropTypes.object.isRequired,
  icons: PropTypes.object,
  storedFields: PropTypes.objectOf(PropTypes.array),
  configuration: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  disabled_slugs: PropTypes.arrayOf(PropTypes.string),
  ingredientsMetadata: PropTypes.object,
  submitUrl: PropTypes.string,
  includeDescriptions: PropTypes.bool,
  includeFooter: PropTypes.bool,
  useInlineErrors: PropTypes.bool,
  onFormEnabled: PropTypes.func,
  onFormDisabled: PropTypes.func,
  showSuccessMessage: PropTypes.bool,
  connectionFinishedUrl: PropTypes.string,
}
